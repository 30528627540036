import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

interface CountdownTimerProps {
  time: number;
  onComplete: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ time, onComplete }: CountdownTimerProps) => {
  const [lastMinutes, setLastMinutes] = useState('00');
  const [lastSeconds, setLastSeconds] = useState('00');
  const [seconds, setSeconds] = useState(time);

  useEffect(() => {
    if (seconds <= 1) {
      onComplete();
      return;
    }
    const intervalId = setInterval(() => {
      countDown(seconds);
    },                             1000);
    return () => clearInterval(intervalId);
  },        [seconds]);

  const secondsToTime = (secs: number) => {
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const time = {
      m: minutes >= 10 ? `${minutes}` : `0${minutes}`,
      s: seconds >= 10 ? `${seconds}` : `0${seconds}`,
    };
    return time;
  };

  const countDown = seconds => {
    const newSeconds = seconds - 1;
    setSeconds(newSeconds);
    const newTime = secondsToTime(newSeconds);
    setLastMinutes(newTime.m);
    setLastSeconds(newTime.s);
  };

  return (
    <div className={styles.countdownTimer}>
      <div className={styles.countdownTimerInner}>
        {lastMinutes} : {lastSeconds}
      </div>
    </div>
  );
};

CountdownTimer.defaultProps = {
  onComplete: () => {},
};

export default CountdownTimer;
