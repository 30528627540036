import request from '../services/Api';
import { AxiosResponse } from 'axios';

export const fetchCustomerGroup = (custId: string | number): Promise<AxiosResponse> =>
  request.get(`CoreAPI/CustomerGroup?custId=${custId}`);

export const addToCustomerGroup = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/CustomerGroup', data);

export const removeFromCustomerGroup = (data: any): Promise<AxiosResponse> =>
  request.post('CoreAPI/CustomerGroup/RemoveFromGroup', data);
