import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HeaderPanel from '../../components/HeaderPanel';
import OhNoWhiteIcon from '../../assets/icons/oh-no-white.svg';
import ContentContainer from '../../components/ContentContainer';

import styles from './styles.module.scss';
import { fallingPaymentAction } from '../../store/Payment/actions';

const PaymentFailureScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fallingPaymentAction());
  }, []);

  return (
    <Fragment>
      <ContentContainer theme="red">
        <HeaderPanel theme="dark" />
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <img src={OhNoWhiteIcon} className={styles.errorIcon} alt="" />
            <div className={styles.separator} />
            <div className={styles.errorMessageContainer}>
              <p className={styles.errorMessage}>
                Something's not right.
                <br />
                Please see a cashier for help.
              </p>
            </div>
          </div>
        </div>
      </ContentContainer>
    </Fragment>
  );
};

export default PaymentFailureScreen;
