import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface ButtonIconProps {
  icon?: string;
  children: React.ReactNode;
  iconLeftSide?: boolean;
  onClick?: () => void;
  color?: string;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, iconLeftSide, onClick, children, color }: ButtonIconProps) => {
  return (
    <button className={styles.buttonIcon} onClick={onClick}>
      {iconLeftSide && (
        <div className={cn(styles.iconWrapper)}>
          <img src={icon} />
        </div>
      )}
      <div className={cn(styles.titleWrapper)} style={{ color: `${color}` }}>
        {children}
      </div>
      {!iconLeftSide && (
        <div className={cn(styles.iconWrapper)}>
          <img src={icon} />
        </div>
      )}
    </button>
  );
};

ButtonIcon.defaultProps = {
  iconLeftSide: false,
  onClick: () => {},
  color: '#000000',
};

export default ButtonIcon;
