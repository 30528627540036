import React, { Fragment } from 'react';
import styles from './styles.module.scss';

interface Props {
  titleOfStap: string;
  required?: boolean;
  errorMessage?: string;
  children: React.ReactNode;
}

const WizardFormCheckbox = ({ titleOfStap, required, errorMessage, children }: Props) => {
  return (
    <div className={styles.wizardFormField}>
      <div className={styles.mainContent}>
        <div className={styles.titleWrap}>
          <h2 className={styles.title}>
            {titleOfStap}
            {required && <span className={styles.required}>*</span>}
          </h2>
          <div className={styles.separator} />
          {errorMessage && (
            <Fragment>
              <div className={styles.errorMessage}>{errorMessage}</div>
              <div className={styles.separator} />
            </Fragment>
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

WizardFormCheckbox.defaultProps = {
  titleOfStap: '',
  required: false,
  errorMessage: '',
};

export default WizardFormCheckbox;
