import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchSources } from '../../../api';
import { SOURCES_TYPES, getSourcesAction, getSourcesActionSuccess, getSourcesActionFailure } from '../actions';

export function* getSourcesWorker() {
  try {
    const { data } = yield call<any>(fetchSources);
    yield put<any>(getSourcesActionSuccess(data));
  } catch (err) {
    yield put<any>(getSourcesActionFailure());
    yield put<any>(getSourcesAction());
  }
}

export function* getSourcesWatcher() {
  yield takeLatest(SOURCES_TYPES.REQUEST, getSourcesWorker);
}

export default function* sourceSaga() {
  yield all([getSourcesWatcher()]);
}
