const PRODUCT_TYPE = {
  REGULAR: 1,
  POINT: 2,
  FOOD: 3,
  RESERVATION: 4,
  GAME_CARD: 5,
  MEMBERSHIP: 6,
  GIFT_CARD: 7,
  INTER_CARD: 9,
};

export default PRODUCT_TYPE;
