import React, { useState, useEffect, useRef } from 'react';
import { isUndefined } from '../../utils/core';
import { addPrefixCss } from '../../utils/prefixCss';
import WheelPickerContents from './WheelPickerContents';

import './WheelPicker.css';

const WheelPicker = ({
  isShowing,
  hide,
  store,
  displayField,
  valueField,
  handleSelect,
  debug,
  debugListeners,
  ...props
}) => {
  const [animating, setAnimating] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [scrollStyle, setScrollStyle] = useState({});
  const [selectedValue, setSelectedValue] = useState({});
  const [listenersAttached, setListenersAttached] = useState(false);

  const scrollRef = useRef(null);
  const viewportRef = useRef(null);
  let touchY = 0;
  const LINE_HEIGHT = 40;
  let DATA_LENGTH: any = store.length;
  const MIDDLE_INDEX: any = Math.floor(DATA_LENGTH / 2);
  let currentIndex: any = MIDDLE_INDEX;
  let marginTop: any = (currentIndex - MIDDLE_INDEX) * LINE_HEIGHT;
  let moveToTimer: any = 0;
  let oldTransY = 0;
  let positionsMoved = 0;
  let endingYLocation = 0;

  useEffect(() => {
    if (debug) console.log('useEffect called for Picker');

    if (viewportRef.current === null) {
      viewportRef.current = document.getElementById('modal-viewport');
    }

    if (scrollRef.current === null) {
      scrollRef.current = document.getElementById('modal-scroll');
    }

    if (isUndefined(viewportRef.current) || viewportRef.current === null) {
      return;
    }

    if (!listenersAttached) {
      viewportRef.current.addEventListener('touchstart', handleContentTouch);
      viewportRef.current.addEventListener('touchmove', handleContentTouch);
      viewportRef.current.addEventListener('touchend', handleContentTouch);
      viewportRef.current.addEventListener('mousedown', handleContentMouseDown);
      setListenersAttached(true);
      if (debug) console.log('listeners have been attached');
    }

    draw();

    return function cleanUp() {
      if (debug) console.log('cleanup is running to remove event listeners');
      viewportRef.current.removeEventListener('touchstart', handleContentTouch);
      viewportRef.current.removeEventListener('touchmove', handleContentTouch);
      viewportRef.current.removeEventListener('touchend', handleContentTouch);
      viewportRef.current.removeEventListener('mousedown', handleContentMouseDown);
      setListenersAttached(false);
      viewportRef.current = null;
      scrollRef.current = null;
      setTranslateY(0);
    };
  }, [store, hide, isShowing]);

  useEffect(() => {
    draw();
  }, [translateY]);

  const handleSelectPressed = data => {
    handleSelect(data);
  };

  const clearTransition = obj => {
    addPrefixCss(obj, { transition: '' });
  };

  const moveTo = direction => {
    if (positionsMoved >= 0) {
      setAnimating(true);
      endingYLocation = positionsMoved * LINE_HEIGHT * -1;
    }
    setScrollStyle({
      transform: `translateY(${endingYLocation}px)`,
      marginTop,
    });

    // there is no transition end so we are going to use setTimeout
    moveToTimer = setTimeout(() => {
      setAnimating(false);
      if (positionsMoved <= 0) {
        positionsMoved = 0;
      }
      if (positionsMoved >= DATA_LENGTH - 1) {
        positionsMoved = DATA_LENGTH - 1;
      }
      setSelectedValue(store[positionsMoved]);
      handleSelectPressed(store[positionsMoved]);
      clearTransition(scrollRef.current);
      oldTransY = 0;
    }, 200);
  };

  const checkUpdates = (direction, transY) => {
    if (direction === 1) {
      console.log('transY', transY);
      if (transY - oldTransY >= LINE_HEIGHT) {
        oldTransY = transY;
        positionsMoved--;
      }
    } else {
      if (transY - oldTransY <= -LINE_HEIGHT) {
        oldTransY = transY;
        positionsMoved++;
      }
    }
  };

  const handleStart = e => {
    if (debugListeners) console.log('handle start is running');
    if (oldTransY === 0) {
      touchY = getLocalTouchY(e);
      setTranslateY(touchY);
    }
  };

  const handleMove = e => {
    // here we are creating a comment just to see if this is working or not;
    const localTouchY = getLocalTouchY(e);
    const dir = localTouchY - touchY;
    const direction = dir < 0 ? -1 : 1;
    const transY = translateY + dir;
    checkUpdates(direction, transY);

    setTranslateY(transY + endingYLocation);
  };

  const getLocalTouchY = e => {
    return !isUndefined(e.targetTouches) && !isUndefined(e.targetTouches[0]) ? e.targetTouches[0].pageY : e.pageY;
  };

  const handleEnd = e => {
    const localTouchY = e.pageY || e.changedTouches[0].pageY;
    if (localTouchY) {
    }
    const dir = localTouchY - touchY;
    const direction = dir < 0 ? -1 : 1;
    touchY = localTouchY;
    moveTo(direction);
  };

  function handleContentTouch(e) {
    e.preventDefault();
    if (animating) return;
    if (e.type === 'touchstart') {
      handleStart(e);
    } else if (e.type === 'touchmove') {
      handleMove(e);
    } else if (e.type === 'touchend') {
      handleEnd(e);
    }
  }

  function handleContentMouseDown(e) {
    if (debugListeners) console.log('mousedown detected');
    if (animating) return;
    handleStart(e);
    viewportRef.current.addEventListener('mousemove', handleContentMouseMove, false);
    viewportRef.current.addEventListener('mouseup', handleContentMouseUp, false);
  }

  const handleContentMouseMove = e => {
    if (animating) return;
    handleMove(e);
  };

  const handleContentMouseUp = e => {
    if (animating) return;
    handleEnd(e);
    viewportRef.current.removeEventListener('mousemove', handleContentMouseMove);
    viewportRef.current.removeEventListener('mouseup', handleContentMouseUp);
  };

  const draw = () => {
    if (scrollRef.current !== null) {
      setScrollStyle({
        transform: `translateY(${translateY}px)`,
        marginTop,
      });
    }
  };

  return (
    <div>
      <WheelPickerContents
        store={store}
        displayField={displayField}
        valueField={valueField}
        scrollRef={scrollRef}
        scrollStyle={scrollStyle}
        activeItem={selectedValue}
      />
    </div>
  );
};

WheelPicker.defaultProps = {
  debug: false,
};

export default WheelPicker;
