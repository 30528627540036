import { takeLatest, all, select, put, call, delay } from 'redux-saga/effects';
import { MAKE_TEXT_2_PAY_TYPES } from '../actions';
import * as Api from '../../../api';
import { isEmpty } from 'lodash';
// import { endCustomerSessionAction } from '../../General/actions';

export function* makeText2PayWorker({ payload }: any) {
  try {
    const {
      payByLink: { paymentLinkId, phoneNumber },
    } = yield select(store => store);
    yield call(Api.makeText2Pay, { paymentLinkId, ...(!isEmpty(phoneNumber) && { phoneNumber }) });
  } catch (err) {}
}

export default function* makeText2PaySaga() {
  yield all([yield takeLatest(MAKE_TEXT_2_PAY_TYPES.MAKE_TEXT_2_PAY, makeText2PayWorker)]);
}
