import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Api from '../../api';
import HeaderPanel from '../../components/HeaderPanel';
import styles from './styles.module.scss';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import isAdult from '../../utils/isAdult';
import Modal from '../Modal';
import NavigationPanel from '../NavigationPanel';
import ContentContainer from '../ContentContainer';
import SignatureCanvas from '../SignatureCanvas';
import { getCustomerGroupActionSuccess } from '../../store/CustomerGroup/actions';
import { saveCustomerToStoreAction } from '../../store/Customer/actions';
import WarningAlert from '../WarningAlert';
import { createAdultWaiver, createMinorWaiver } from '../../utils/waivers';
import WaiverAgreementText from '../WaiverAgreementText/WaiverAgreementText';

interface Props {
  members?: any[];
  membersWithoutWaiver?: any[];
  finishEnforceWaiver: (p: any[]) => void;
  closeEnforceWaiver: () => void;
}

const EnforceWaiver = ({ members, membersWithoutWaiver, finishEnforceWaiver, closeEnforceWaiver }: Props) => {
  const {
    customerStore,
    registrationSettings: { adultAge, waiverStep },
  } = useSelector(state => state);
  const sigPad = React.useRef(null);
  const dispatch = useDispatch();
  const minorGuests = [...membersWithoutWaiver].filter((item: any) => {
    return !isAdult(item.birthDate, adultAge);
  });
  const adultGuests = [...membersWithoutWaiver].filter((item: any) => {
    return isAdult(item.birthDate, adultAge) && customerStore.custId !== item.custId;
  });

  const [primaryCustomerInList, setPrimaryCustomerInList] = useState(false);
  const [chapterOfEnforceWaiver, setChapterOfEnforceWaiver] = useState(0);
  const [indexOfActiveCustomer, setIndexOfActiveCustomer] = useState(0);
  const [minorsListModal, setMinorsListModal] = useState(false);
  const [primaryCustomerSignature, setPrimaryCustomerSignature] = useState(null);
  const [customerSignatures, setCustomerSignatures] = useState({});
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [agreeWithRulesError, setAgreeWithRulesError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResetSignatureModalShow, setIsResetSignatureModalShow] = useState(false);

  useEffect(() => {
    const isPrimaryCustomerInList = () => {
      const result = [...membersWithoutWaiver].filter((item: any) => {
        return item.custId === customerStore.custId;
      });
      return result.length > 0;
    };
    setPrimaryCustomerInList(isPrimaryCustomerInList());
  }, []);

  useEffect(() => {
    const isPrimaryCustomerInList = () => {
      const result = [...membersWithoutWaiver].filter((item: any) => {
        return item.custId === customerStore.custId;
      });
      return result.length > 0;
    };
    if (minorGuests.length || isPrimaryCustomerInList()) {
      setChapterOfEnforceWaiver(1);
    } else {
      setChapterOfEnforceWaiver(2);
    }
  }, []);

  const onBackClick = () => {
    if (sigPad !== null && sigPad.current !== null) {
      sigPad.current.clear();
    }
    setAgreeWithRules(false);
    if (chapterOfEnforceWaiver === 1) {
      closeEnforceWaiver();
      return;
    }
    if (chapterOfEnforceWaiver === 2) {
      if (indexOfActiveCustomer === 0) {
        closeEnforceWaiver();
        return;
      }
      if (indexOfActiveCustomer > 0) {
        setIndexOfActiveCustomer(prevState => prevState - 1);
        return;
      }
    }
  };

  const onContinueClick = async () => {
    const signatureOfCustomer = sigPad.current.toDataURL().substr(22);
    if (!agreeWithRules) {
      setAgreeWithRulesError(true);
      return;
    }
    setAgreeWithRulesError(false);
    if (sigPad.current.isEmpty()) {
      return;
    }
    if (chapterOfEnforceWaiver === 1) {
      setPrimaryCustomerSignature(signatureOfCustomer);
      if (adultGuests.length) {
        sigPad.current.clear();
        setChapterOfEnforceWaiver(2);
        setAgreeWithRules(false);
        return;
      }
      await saveSignatures(signatureOfCustomer);
      return;
    }
    if (chapterOfEnforceWaiver === 2) {
      const localCustomerSignatures = customerSignatures;
      localCustomerSignatures[adultGuests[indexOfActiveCustomer].custId] = signatureOfCustomer;
      setCustomerSignatures(localCustomerSignatures);
      if (indexOfActiveCustomer === adultGuests.length - 1) {
        await saveSignatures();
        return;
      }
      if (indexOfActiveCustomer !== adultGuests.length - 1) {
        sigPad.current.clear();
        setAgreeWithRules(false);
        setIndexOfActiveCustomer(prevState => prevState + 1);
        return;
      }
    }
  };

  const saveSignatures = async (signatureOfCustomer: string = '') => {
    setLoading(true);
    if (primaryCustomerInList) {
      const adultWaiver = await createAdultWaiver({
        signature: signatureOfCustomer ? signatureOfCustomer : primaryCustomerSignature,
        adultCustId: customerStore.custId,
      });
      if (!adultWaiver) {
        setIsResetSignatureModalShow(true);
        return;
      }
    }
    if (minorGuests.length && !isResetSignatureModalShow) {
      for (const item of minorGuests) {
        const minorWaiver = await createMinorWaiver({
          guardianSignature: signatureOfCustomer ? signatureOfCustomer : primaryCustomerSignature,
          minorCustId: item.custId,
          guardianCustId: customerStore.custId,
        });
        if (!minorWaiver) {
          setIsResetSignatureModalShow(true);
          return;
        }
      }
    }
    if (adultGuests.length && !isResetSignatureModalShow) {
      for (const item of adultGuests) {
        const adultWaiver = await createAdultWaiver({
          signature: customerSignatures[item.custId],
          adultCustId: item.custId,
        });
        if (!adultWaiver) {
          setIsResetSignatureModalShow(true);
          return;
        }
      }
    }
    const { data: groupCustomer } = await Api.fetchCustomerGroup(customerStore.custId);
    const { data: primaryCustomer } = await Api.fetchCustomerById(customerStore.custId);
    dispatch(getCustomerGroupActionSuccess(groupCustomer));
    dispatch(saveCustomerToStoreAction(primaryCustomer));
    const membersData = [primaryCustomer, ...groupCustomer].filter(customer =>
      members.find(cust => customer.custId === cust.custId),
    );
    setLoading(false);
    await finishEnforceWaiver(membersData);
  };

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };

  const resetSignatureModalHandleClick = () => {
    clearSignature();
    setIsResetSignatureModalShow(false);
  };

  useEffect(() => {
    if (isResetSignatureModalShow) {
      setLoading(false);
    }
  }, [isResetSignatureModalShow]);

  return (
    <Fragment>
      <div className={styles.enforceWaiver}>
        <ContentContainer loading={loading}>
          <HeaderPanel startOverButton border paginationHistory="" paginationStep={''} />
          <div className={styles.contentWaiver}>
            <div className={styles.waiverAgreementTextContainer}>
              <span className={styles.waiverAgreementTextTitle}>
                Waiver Agreement -
                {chapterOfEnforceWaiver === 1 && (
                  <span>
                    {customerStore.fName} {customerStore.lName}
                  </span>
                )}
                {chapterOfEnforceWaiver === 2 && (
                  <span>
                    {adultGuests[indexOfActiveCustomer].fName} {adultGuests[indexOfActiveCustomer].lName}
                  </span>
                )}
                {chapterOfEnforceWaiver === 1 && !!minorGuests.length && (
                  <span>
                    {' '}
                    (signing for{' '}
                    <span className={styles.redText} onClick={() => setMinorsListModal(prevState => !prevState)}>
                      minors in group
                    </span>
                    )
                  </span>
                )}
              </span>
              <div className={styles.waiverAgreementTextText}>
                {((chapterOfEnforceWaiver === 1 && primaryCustomerInList) || chapterOfEnforceWaiver === 2) && (
                  <Fragment>
                    <div>Adult Waiver Text:</div>
                    <WaiverAgreementText isDoubleText={!!minorGuests.length} waiverText={waiverStep.adultWaiverText} />
                  </Fragment>
                )}
                {chapterOfEnforceWaiver === 1 && !!minorGuests.length && (
                  <Fragment>
                    <div>Minor Waiver Text:</div>
                    <WaiverAgreementText isDoubleText={true} waiverText={waiverStep.minorWaiverText} />
                  </Fragment>
                )}
              </div>
            </div>

            <div className={styles.waiverAgreementTextContainer}>
              <div className={styles.iAgreeTextbox}>
                {chapterOfEnforceWaiver === 1 && primaryCustomerInList && !!minorGuests.length && (
                  <Checkbox
                    state={!!agreeWithRules}
                    label={`I, ${customerStore.fName} ${customerStore.lName}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to both my own waiver, and the waiver for any minors present in my group.`}
                    handleClick={() => setAgreeWithRules(prevState => !prevState)}
                    error={agreeWithRulesError}
                  />
                )}
                {chapterOfEnforceWaiver === 1 && !primaryCustomerInList && !!minorGuests.length && (
                  <Checkbox
                    state={!!agreeWithRules}
                    label={`I, ${customerStore.fName} ${customerStore.lName}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to the waiver for any minors present in my group`}
                    handleClick={() => setAgreeWithRules(prevState => !prevState)}
                    error={agreeWithRulesError}
                  />
                )}
                {chapterOfEnforceWaiver === 1 && !minorGuests.length && (
                  <Checkbox
                    state={!!agreeWithRules}
                    label={`I, ${customerStore.fName} ${customerStore.lName}, agree to the terms and conditions of the above agreement.`}
                    handleClick={() => setAgreeWithRules(prevState => !prevState)}
                    error={agreeWithRulesError}
                  />
                )}
                {chapterOfEnforceWaiver === 2 && (
                  <Checkbox
                    state={!!agreeWithRules}
                    label={`I, ${adultGuests[indexOfActiveCustomer].fName} ${adultGuests[indexOfActiveCustomer].lName}, agree to the terms and conditions of the above agreement.`}
                    handleClick={() => setAgreeWithRules(prevState => !prevState)}
                    error={agreeWithRulesError}
                  />
                )}
              </div>
            </div>

            {agreeWithRules && (
              <div className={styles.waiverAgreementTextContainer}>
                <div className={styles.waiverAgreementSignatureCanvas}>
                  <span className={styles.waiverAgreementSignatureTitle}>
                    <h2>
                      {chapterOfEnforceWaiver === 1 && (
                        <span>
                          {customerStore.fName} {customerStore.lName + ' '}
                        </span>
                      )}
                      {chapterOfEnforceWaiver === 2 && (
                        <span>
                          {adultGuests[indexOfActiveCustomer].fName} {adultGuests[indexOfActiveCustomer].lName + ' '}
                        </span>
                      )}
                      please sign below.
                    </h2>
                  </span>
                </div>
                <SignatureCanvas sigPad={sigPad} />
              </div>
            )}
          </div>
          <div className={styles.navigation}>
            <NavigationPanel
              onLeftButtonClick={onBackClick}
              leftButtonTitle="Title"
              onRightButtonClick={onContinueClick}
              rightButtonTitle="Continue"
            />
            {/*<Button theme="white" size="base" handleClick={onBackClick} customClass={styles.whiteButton}>*/}
            {/*  Back*/}
            {/*</Button>*/}
            {/*<div className={styles.navigationFilter} />*/}
            {/*<Button theme="red" size="base" handleClick={onContinueClick} customClass={styles.redButton}>*/}
            {/*  Continue*/}
            {/*</Button>*/}
          </div>
        </ContentContainer>
        <WarningAlert
          zIndex={9999}
          isOpen={isResetSignatureModalShow}
          title="Whoops!"
          description="An error occurred while creating your signature, please try again."
          handleClick={resetSignatureModalHandleClick}
        />
      </div>
      <Modal isOpen={minorsListModal} zIndex={102}>
        <div className={styles.minorsListModal}>
          <div className={styles.minorsListModalContainer}>
            <p>
              {customerStore.fName} {customerStore.lName} consent to this waiver
              <br />
              agreement will apply to the
              <br />
              following minor(s) in the group:
            </p>
            <ul>
              {minorGuests.map((item, index) => {
                return (
                  <li key={index}>
                    {item.fName} {item.lName}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.cloverPassButtonWrap}>
            <Button
              handleClick={() => setMinorsListModal(prevState => !prevState)}
              theme="red"
              customClass={styles.minorsListModalButton}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default EnforceWaiver;
