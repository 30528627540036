export const UPDATE_TERMINAL_SETTINGS_TYPES = {
  REQUEST: 'settings/UPDATE_TERMINAL_SETTINGS_REQUEST',
  SUCCESS: 'settings/UPDATE_TERMINAL_SETTINGS_SUCCESS',
  FAILURE: 'settings/UPDATE_TERMINAL_SETTINGS_FAILURE',
};

export const updateTerminalSettingsAction = (mode:string) => ({
  type: UPDATE_TERMINAL_SETTINGS_TYPES.REQUEST,
  payload: mode,
});

export const updateTerminalSettingsActionSuccess = (payload:any) => {
  return {
    type: UPDATE_TERMINAL_SETTINGS_TYPES.SUCCESS,
    payload
  };
};

export const updateTerminalSettingsActionFailure = () => {
  return {
    type: UPDATE_TERMINAL_SETTINGS_TYPES.FAILURE,
  };
};

export const UPDATE_NATIVE_KEYBOARD_TYPE = 'settings/UPDATE_NATIVE_KEYBOARD';

export const updateNativeKeyboardAction = (newNativeKeyboardStatus:boolean) => ({
  type: UPDATE_NATIVE_KEYBOARD_TYPE,
  payload: newNativeKeyboardStatus,
});

export const GET_GLOBAL_SETTINGS_TYPES = {
  REQUEST: 'settings/GET_GLOBAL_SETTINGS_REQUEST',
  SUCCESS: 'settings/GET_GLOBAL_SETTINGS_SUCCESS',
  FAILURE: 'settings/GET_GLOBAL_SETTINGS_FAILURE',
};

export const SELECT_MODE_TYPE = 'settings/SELECT_MODE';




export const getAction = () => ({
  type: GET_GLOBAL_SETTINGS_TYPES.REQUEST,
});

export const selectModeAction = (mode:string) => ({
  type: SELECT_MODE_TYPE,
  payload: mode,
});

export const sendResetCodeActionSuccess = () => {
  return {
    type: GET_GLOBAL_SETTINGS_TYPES.SUCCESS,
  };
};

export const sendResetCodeActionFailure = () => {
  return {
    type: GET_GLOBAL_SETTINGS_TYPES.FAILURE,
  };
};
