export const RESET_PAYMENT_PROCESS_TYPE = {
  RESET_PAYMENT_PROCESS_REQUEST: 'payment/RESET_PAYMENT_PROCESS_REQUEST',
  RESET_PAYMENT_PROCESS_SUCCESS: 'payment/RESET_PAYMENT_PROCESS_SUCCESS',
};

export const resetPaymentProcessAction = () => ({
  type: RESET_PAYMENT_PROCESS_TYPE.RESET_PAYMENT_PROCESS_REQUEST,
});

export const resetPaymentProcessSuccessAction = () => ({
  type: RESET_PAYMENT_PROCESS_TYPE.RESET_PAYMENT_PROCESS_SUCCESS,
});
