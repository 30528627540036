import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createCart } from '../../../api';
import {
  CREATE_CART_TYPES,
  createCartActionFailure,
  createCartActionSuccess,
  createCartResetLoadingAction,
} from '../actions';
import { history } from '../../../App';
import { ROUTES } from '../../../constants';

export function* createCartWorker() {
  try {
    // yield take<any>([CREATE_CART_TYPES.REQUEST])
    const {
      customerStore: { custId },
      cartStore,
    } = yield select(store => store);

    if (!cartStore.checkId) {
      const payloadData = {
        checkDetails: [],
        ...(custId && { custId }),
      };

      const { data: newCartData } = yield call<any>(createCart, payloadData);
      yield put<any>(createCartActionSuccess(newCartData));
      return newCartData.checkId;
    }
    yield put<any>(createCartActionSuccess(cartStore.checkId));
    return cartStore.checkId;
  } catch (err) {
    yield put<any>(createCartActionFailure());
  }
}

export function* successCartWorker() {
  try {
    history.push(ROUTES.PRODUCTS);
  } catch (err) {}
}

export function* resetLoadingCartWorker() {
  try {
    yield put<any>(createCartResetLoadingAction());
  } catch (err) {}
}

export default function* createCartSaga() {
  yield all([
    yield takeLatest('persist/REHYDRATE', resetLoadingCartWorker),
    yield takeLatest(CREATE_CART_TYPES.REQUEST, createCartWorker),
    yield takeLatest(CREATE_CART_TYPES.SUCCESS, successCartWorker),
  ]);
}
