import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../App';
import ContentContainer from '../../components/ContentContainer';
import IdleMonitor from '../../services/IdleMonitor';
import HeaderPanel from '../../components/HeaderPanel';
import ProductGrid from '../../components/ProductGrid';
import ProductGridItem from '../../components/ProductGridItem';

import { ROUTES } from '../../constants';

import Cart from '../../components/Cart';
import Title from '../../components/Title';
import styles from './styles.module.scss';
import { getProductCategoriesAction } from '../../store/ProductCategories/actions';
import { useGridHeightHook } from '../../hooks';
import Warning from '../../components/Warning';
import { endCustomerSessionAction } from '../../store/General/actions';

const CategoriesScreen = () => {
  const dispatch = useDispatch();
  const {
    productCategoriesStore: { loading, categoriesList },
  } = useSelector(state => state);
  const greedContainerHeight = useGridHeightHook();

  React.useEffect(() => {
    if (!categoriesList.length) {
      dispatch(getProductCategoriesAction());
    }
  }, []);

  const goToCategory = (categoryId, productType, categoryTitle) => {
    history.push(`${ROUTES.PRODUCTS}/${categoryId}${productType === 'food' ? '?type=food' : ''}`, {
      title: categoryTitle,
    });
  };

  const endCustomerSession = () => {
    dispatch(endCustomerSessionAction());
  };

  return (
    <>
      <ContentContainer loading={loading}>
        <HeaderPanel homeButton logOutButton border />
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <Title message="Categories" />
              <div className={styles.productsList} style={{ height: `${greedContainerHeight}px` }}>
                {categoriesList && categoriesList.length > 0 && (
                  <ProductGrid>
                    {categoriesList.map((item: any, index) => (
                      <ProductGridItem
                        key={`${item.categoryId}_${index}`}
                        highResolutionCover={item.highResImagePath}
                        title={item.description}
                        cover={item?.largeIcon}
                        handleClick={() => goToCategory(item.categoryId, item.productType, item.description)}
                      />
                    ))}
                  </ProductGrid>
                )}
                {!loading && categoriesList.length === 0 && (
                  <Warning
                    title="Oh no!"
                    text="There are no products available for purchase right now, please check again later."
                    buttonText="OK"
                    handleClick={endCustomerSession}
                  />
                )}
              </div>
            </div>
          </div>
          <Cart />
        </div>
      </ContentContainer>
      <IdleMonitor />
    </>
  );
};

export default CategoriesScreen;
