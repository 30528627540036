import { camelCase } from './core';

export const formatCss = props => {
  const prefixes = ['-webkit-', '-moz-', '-ms-'];

  const result = [];

  const regPrefix = /transform|transition/;

  for (const key in props) {
    if (props.hasOwnProperty(key)) {
      const styleValue = props[key];

      if (regPrefix.test(key)) {
        for (let i = 0; i < prefixes.length; i++) {
          const stylename = camelCase(prefixes[i] + key);
          result[stylename] = styleValue.replace(regPrefix, `${prefixes[i]}$&`);
        }
      }

      result[key] = styleValue;
    }
  }

  return result;
};

export const addPrefixCss = (element: any, props: any) => {
  const formatProps = formatCss(props);
  for (const key in formatProps) {
    if (formatProps.hasOwnProperty(key)) {
      element.style[key] = formatProps[key];
    }
  }
};
