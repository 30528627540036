import { GET_PRODUCTS_BY_CATEGORY_TYPES } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  productsByCategory: [],
  loading: true,
};

export type InitialStateType = typeof initialState;

export default function productsByCategoryReducer(state = initialState, { type, payload = {} }: any,
): InitialStateType {
  switch (type) {
    case GET_PRODUCTS_BY_CATEGORY_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS_BY_CATEGORY_TYPES.SUCCESS:
      return {
        ...state,
        productsByCategory: payload,
        loading: false,
      };
    case GET_PRODUCTS_BY_CATEGORY_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
