import { AxiosResponse } from 'axios';
import request from '../services/Api';

export const createCart = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/Checks', data);
export const getCart = (cartId: number): Promise<AxiosResponse> => request.get(`CoreAPI/Checks/${cartId}`);

export const addToCart = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/Checks/AddCheckDetails', data);

export const deleteFromCart = (data: any): Promise<AxiosResponse> =>
  request.post('CoreAPI/Checks/RemoveCheckDetails', data);

export const deleteCart = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/Checks/RemoveCheck', data);
