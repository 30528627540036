import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeItemFromCartAction } from '../../../store/Cart/actions';
import blackCrossIcons from '../../../assets/icons/black-cross.svg';
import isAdult from '../../../utils/isAdult';
import styles from './styles.module.scss';
import { PRODUCT_TYPE } from '../../../constants';
import CartItemRemoveAlert from '../CartItemRemoveAlert';
import { showRemoveMembershipAlertAction } from '../../../store/Cart/actions/removeCartActions';
import CartItemSchedule from './CartItemSchedule';

interface CartItemProps {
  custId?: number;
  productId?: number;
  checkDetailId?: number;
  currentCartItem: any;
  productType?: number;
}

const CartItem = ({ checkDetailId, productId, currentCartItem }: CartItemProps) => {
  const dispatch = useDispatch();

  const {
    customerStore,
    customerGroup: { members },
    registrationSettings: { adultAge },
    productsByCategoryStore,
    cartItemStore: { showRemoveMembershipAlert, membershipId, custId },
  } = useSelector(state => state);

  const getCustomerInfo = () => {
    const customerId = currentCartItem.p_CustId || currentCartItem.m_CustId;

    if (customerStore.custId === customerId) {
      return customerStore;
    }
    const customerFromMembers = members.filter(item => item.custId === customerId);
    return !!customerFromMembers.length ? customerFromMembers[0] : null;
  };

  const customerData = getCustomerInfo();
  const [productData, setProductData] = React.useState(null);
  React.useEffect(() => {
    const data = productsByCategoryStore.productsByCategory.filter((item: any) => {
      return item.productId == productId;
    })[0];
    setProductData(data);
  }, []);

  const removeItem = () => {
    if (currentCartItem.checkDetailType === PRODUCT_TYPE.MEMBERSHIP) {
      dispatch(
        removeItemFromCartAction({
          checkDetailId,
          custId: currentCartItem.m_CustId,
          isMembership: true,
          isCheck: true,
        }),
      );
    } else {
      dispatch(removeItemFromCartAction({ checkDetailId }));
    }
  };

  const continueClickHandle = () => {
    dispatch(
      removeItemFromCartAction({
        custId,
        checkDetailId: membershipId,
        isMembership: true,
        isCheck: false,
      }),
    );
    dispatch(showRemoveMembershipAlertAction({ showRemoveMembershipAlert: false, membershipId: null, custId: null }));
  };

  const productCover = productData?.highResImagePath
    ? `${productData?.highResImagePath}`
    : `data:image/jpeg;base64,${productData?.largeIcon}`;
  return (
    <>
      <div className={styles.cartItem}>
        <div className={styles.cartItemCover}>{productData && <img src={productCover} alt="" />}</div>
        <div className={styles.cartItemInfo}>
          <div className={styles.productTitle}>
            <span className={styles.productTitleInner}>{currentCartItem.productName}</span>
          </div>
          {currentCartItem.subItems.length > 0 && (
            <div className={styles.productOptions}>
              {currentCartItem.subItems.reduce(
                (acc, item, index, arr) => `${acc} ${item}${index < arr.length - 1 ? ',' : ''}`,
                '+ ',
              )}
            </div>
          )}
          {customerData && (
            <div className={styles.ownerInfo}>
              {/* // @ts-ignore */}
              <span className={styles.ownerInfoInner}>
                {/*
                // @ts-ignore */}
                {isAdult(customerData.birthDate, adultAge) ? 'Adult' : 'Minor'} | {customerData.fName}{' '}
                {/*
                // @ts-ignore */}
                {customerData.lName}
              </span>
            </div>
          )}
          <CartItemSchedule currentCartItem={currentCartItem} />
        </div>
        <div className={styles.cartItemRightSide}>
          <div className={styles.cartItemRightSideInner}>
            <div className={styles.cartItemQuantity}>x {currentCartItem.qty}</div>
            <div className={styles.cartItemPrice}>${currentCartItem.checkDetailSubtotal.toFixed(2)}</div>
            <div>
              <button className={styles.cartItemDelete} onClick={removeItem}>
                <img src={blackCrossIcons} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {showRemoveMembershipAlert && (
        <CartItemRemoveAlert
          isOpen={showRemoveMembershipAlert && checkDetailId === membershipId}
          cancelClickHandle={() => dispatch(showRemoveMembershipAlertAction({ showRemoveMembershipAlert: false }))}
          continueClickHandle={continueClickHandle}
        />
      )}
    </>
  );
};

export default CartItem;
