import React, { Fragment, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import IdleMonitor from '../../services/IdleMonitor';
import * as Api from '../../api';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import Modal from '../../components/Modal';

import Button from '../../components/Button';

import WizardFormStep from '../../components/WizardFormStep';
import styles from './styles.module.scss';
import validator from '../../utils/validator';
import detectRequiredField from '../../utils/detectRequiredField';
import { endCustomerSessionAction } from '../../store/General/actions';
import {
  logInAntherWayAddPhoneAndPinAction,
  logInAntherWayAddPinAction,
  logInAntherWayEnterPinAction,
} from '../../store/Customer/actions';
import WarningAlert from '../../components/WarningAlert';

const loginAnotherWayFields = [
  {
    customerFieldName: 'fName',
    displayName: 'First Name',
    required: true,
    settingName: 'FirstName',
  },
  {
    customerFieldName: 'lName',
    displayName: 'Last Name',
    required: true,
    settingName: 'LastName',
  },
  {
    customerFieldName: 'birthDate',
    displayName: 'Date Of Birth',
    required: true,
    settingName: 'DateOfBirth',
  },
];

const loginAnotherWaySteps = ['fName', 'lName', 'birthDate'];

type formType = {
  [key: string]: any;
};

const LogInAnotherWayScreen = () => {
  const dispatch = useDispatch();
  const { customerStore } = useSelector(state => state);
  const [activeField, setActiveField] = useState(loginAnotherWayFields[0].customerFieldName);
  const [paginationTitle, setPaginationTitle] = useState(loginAnotherWayFields[0].displayName);
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({
    birthDate: '',
  });
  const [errorsOfField, setErrorsOfField] = useState('');

  const [customerData, setCustomerData] = useState({});
  const [noMatchesModal, setNoMatchesModal] = useState(false);
  const [oneMatchNoPhoneNoPinModal, setOneMatchNoPhoneNoPinModal] = useState(false);
  const [oneMatchNoPinModal, setOneMatchNoPinModal] = useState(false);
  const [oneMatchModal, setOneMatchModal] = useState(false);
  const [manyMatchesModal, setManyMatchesModal] = useState(false);

  const findCustomer = async () => {
    try {
      await validator(
        activeField,
        fieldsOfForm[activeField],
        detectRequiredField(loginAnotherWayFields, activeField),
        true,
      );
      const { data } = await Api.findCustomerByNameAndDOB(
        fieldsOfForm['fName'],
        fieldsOfForm['lName'],
        fieldsOfForm['birthDate'],
      );
      const dataLength = data.length;
      if (dataLength === 0) {
        localStorage.setItem('login_another_way_birthdate', fieldsOfForm['birthDate']);
        localStorage.setItem('login_another_way_fName', fieldsOfForm['fName']);
        localStorage.setItem('login_another_way_lName', fieldsOfForm['lName']);
        setNoMatchesModal(true);
      } else if (dataLength === 1) {
        setCustomerData(data[0]);
        if (data[0].cell === '') {
          setOneMatchNoPhoneNoPinModal(true);
        } else if (!data[0].pinExists) {
          setOneMatchNoPinModal(true);
        } else {
          setOneMatchModal(true);
        }
      } else {
        setManyMatchesModal(true);
      }
    } catch (e) {}
  };

  const endCustomerSession = () => {
    dispatch(endCustomerSessionAction());
  };

  const loginFirstStep = () => {
    history.replace(ROUTES.LOG_IN_FIRST_STEP);
    return;
  };
  const goToAddPhoneAndPin = () => {
    dispatch(logInAntherWayAddPhoneAndPinAction(customerData));
  };

  const goToAddPin = () => {
    dispatch(logInAntherWayAddPinAction(customerData));
  };

  const goToEnterPin = () => {
    dispatch(logInAntherWayEnterPinAction(customerData));
  };

  const onChangeField = async (fieldName: string, newFieldValue: string) => {
    try {
      setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
      setErrorsOfField('');
      if (fieldName === 'fName' || fieldName === 'lName') {
        await validator(activeField, newFieldValue, detectRequiredField(loginAnotherWayFields, activeField), true);
      }
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const startOver = () => {
    setManyMatchesModal(false);
    history.replace(ROUTES.ROOT);
  };

  const goToNextStep = async () => {
    try {
      await validator(
        activeField,
        fieldsOfForm[activeField],
        detectRequiredField(loginAnotherWayFields, activeField),
        true,
      );
      const indexOfCurrentField = loginAnotherWaySteps.indexOf(activeField);
      setActiveField(loginAnotherWaySteps[indexOfCurrentField + 1]);
      setPaginationTitle(loginAnotherWayFields[indexOfCurrentField + 1].displayName);
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const goToPrevStep = () => {
    const indexOfCurrentField = loginAnotherWaySteps.indexOf(activeField);
    if (indexOfCurrentField <= 0) {
      history.goBack();
      return;
    }
    setActiveField(loginAnotherWaySteps[indexOfCurrentField - 1]);
    setPaginationTitle(loginAnotherWayFields[indexOfCurrentField - 1].displayName);
    setErrorsOfField('');
  };

  return (
    <Fragment>
      <ContentContainer loading={customerStore.loading} bgColor="#FFFFFF">
        <HeaderPanel startOverButton shadow paginationHistory="Log in Another Way" paginationStep={paginationTitle} />
        <WizardFormStep
          type="text"
          fieldName="fName"
          value={fieldsOfForm['fName']}
          label="First Name"
          onChange={onChangeField}
          required={true}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'fName' === activeField}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
        />
        <WizardFormStep
          type="text"
          fieldName="lName"
          value={fieldsOfForm['lName']}
          label="Last Name"
          required={true}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'lName' === activeField}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
        />
        <WizardFormStep
          type="numeric"
          fieldName="birthDate"
          value={fieldsOfForm['birthDate']}
          label="Date Of Birth MM/DD/YYYY"
          onChange={onChangeField}
          required={true}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'birthDate' === activeField}
          goToNextStep={findCustomer}
          goToPrevStep={goToPrevStep}
        />
      </ContentContainer>
      <Modal isOpen={noMatchesModal} zIndex={500}>
        <div className={styles.NoMatchesContent}>
          <div className={styles.contentModal}>
            <h2 className={styles.title}>Lookup Issue</h2>
            <h3 className={styles.subTitle}>
              We’re sorry we couldn’t find your account. <br /> Please continue to create a new account or start over.
            </h3>
            <div className={styles.countdown}></div>
          </div>
          <div className={styles.footer}>
            <Button handleClick={endCustomerSession} customClass={styles.button} theme="grey">
              Start Over
            </Button>
            <Button handleClick={loginFirstStep} customClass={styles.button} theme="red">
              Create Account
            </Button>
          </div>
        </div>
      </Modal>
      <WarningAlert
        isOpen={oneMatchNoPhoneNoPinModal}
        title="Almost There!"
        description="We’ve found your account, please add your phone number and create a PIN before proceeding"
        handleClick={goToAddPhoneAndPin}
      />
      <WarningAlert
        isOpen={oneMatchNoPinModal}
        title="Almost There!"
        description="We’ve found your account, please create a PIN before proceeding"
        handleClick={goToAddPin}
      />
      <WarningAlert
        isOpen={oneMatchModal}
        title=""
        description="We’ve found your account, please enter your PIN number to proceed."
        handleClick={goToEnterPin}
      />
      <WarningAlert
        isOpen={manyMatchesModal}
        title=""
        description="We've found more than one account with the information you've provided, please see a cashier for help"
        handleClick={startOver}
      />
      <IdleMonitor />
    </Fragment>
  );
};

export default LogInAnotherWayScreen;
