import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import Avatar from '../Avatar';
import blackCrossIcon from '../../assets/icons/black-cross.svg';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import isAdult from '../../utils/isAdult';

interface MemberCardProps {
  custId: number | string;
  isPrimaryMember?: boolean;
  avatarUrl?: string;
  firstName?: string;
  customerImage?: string;
  lastName?: string;
  birthday?: string;
  gender?: string | number;
  handleClick?: () => void;
  removeHandler?: () => void;
  isActive?: boolean;
  unavailable?: boolean;
}

const MemberCard: React.FC<MemberCardProps> = ({
  custId,
  isPrimaryMember,
  firstName,
  lastName,
  birthday,
  customerImage,
  handleClick,
  removeHandler,
  isActive,
  unavailable,
}: MemberCardProps) => {
  const {
    registrationSettings: { adultAge },
  } = useSelector(state => state);

  return (
    <div className={cn(styles.memberCard, { [styles.memberCardActive]: isActive })}>
      {unavailable && (
        <div className={styles.unavailable}>{firstName} is participating in another activity at this time.</div>
      )}
      {!isPrimaryMember && (
        <div className={styles.removeButton}>
          <button onClick={removeHandler}>
            <img src={blackCrossIcon} alt="" />
          </button>
        </div>
      )}
      <div className={styles.memberCardInner} onClick={handleClick}>
        <div className={styles.memberCardAvatar}>
          <Avatar customerImage={customerImage} custId={custId} />
        </div>
        <h3 className={styles.memberCardName}>
          {firstName} {lastName}
        </h3>
        <h5 className={styles.memberCardBirthday}>{isAdult(birthday, adultAge) ? 'Adult' : 'Minor'}</h5>
        <div className={styles.memberCardGender} />
      </div>
    </div>
  );
};

MemberCard.defaultProps = {
  avatarUrl: '',
  firstName: '',
  lastName: '',
  birthday: '',
  gender: '',
  handleClick: () => {},
  removeHandler: () => {},
  isActive: false,
  isPrimaryMember: true,
};

export default MemberCard;
