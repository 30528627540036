import React from 'react';
import Modal from '../Modal';
import Button from '../Button';
import styles from './styles.module.scss';

interface Props {
  isOpen?: boolean;
  title?: string;
  description?: string;
  handleClick?: () => void;
  zIndex?: number;
}

const WarningAlert = ({ isOpen, title, description, handleClick, zIndex }: Props) => {
  return (
    <Modal isOpen={isOpen} zIndex={zIndex}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.description}>
          <p>{description}</p>
        </div>
        <div className={styles.footer}>
          <Button theme="red" customClass={styles.button} handleClick={handleClick}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};

WarningAlert.defaultProps = {
  isOpen: false,
  title: '',
  description: '',
  handleClick: () => {},
};

export default WarningAlert;
