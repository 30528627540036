export const ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES = {
  REQUEST: 'customerGroup/ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES_REQUEST',
  SUCCESS: 'customerGroup/ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES_SUCCESS',
  FAILURE: 'customerGroup/ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES_FAILURE',
};

export const addMemberToCustomerRelationshipsAction = (payload: any) => ({
  type: ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES.REQUEST,
  payload,
});

export const addMemberToCustomerRelationshipsActionSuccess = (payload: any) => ({
  type: ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES.SUCCESS,
  payload,
});

export const addMemberToCustomerRelationshipsActionFailure = () => ({
  type: ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES.FAILURE,
});
