import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

const WaiverAgreementText = ({ waiverText, isDoubleText = false }: any) => {
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    setIsPortrait(windowHeight - 100 > windowWidth);
  };

  return (
    <div
      className={cn(styles.waiverAgreementText, {
        [styles.waiverAgreementTextDouble]: isDoubleText,
        [styles.waiverAgreementTextPortrait]: isPortrait,
      })}
    >
      <div>{waiverText}</div>
    </div>
  );
};

export default WaiverAgreementText;
