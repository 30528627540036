import request from '../services/Api';
import { AxiosResponse } from 'axios';

export const fetchProductsByCategory = (categoryId: string): Promise<AxiosResponse> =>
  request.get(`CoreAPI/Products/GetSelfServeKioskProductsByCategoryId?categoryId=${categoryId}`);

export const fetchFoodProductsByCategory = (categoryId: string): Promise<AxiosResponse> =>
  request.get(`coreAPI/Products/GetFoodSelfServeKioskProductsByCategoryId?categoryId=${categoryId}`);

export const fetchProductById = (productId: string | number): Promise<AxiosResponse> =>
  request.get(`CoreAPI/Products/${productId}`);

export const fetchProductsList = (productIds: string | number): Promise<AxiosResponse> =>
  request.get(`CoreAPI/Products/List?ProductIds=${productIds}`);

export const fetchFoodProductById = (productId: string | number): Promise<AxiosResponse> =>
  request.get(`CoreAPI/Products/${productId}`);

export const fetchProductPrompts = (productId: string | number): Promise<AxiosResponse> =>
  request.get(`CoreApi/Prompts/${productId}`);

export const fetchProductOptionsByProductId = (productId: string | number): Promise<AxiosResponse> =>
  request.get(`CoreAPI/SubItems?ProductId=${productId}`);

export const fetchTimeSlots = (
  scheduledDate: string,
  productId: string,
  selectedScheduledDates?: string | object,
  checkId?: string,
): Promise<AxiosResponse> =>
  request.post('CoreAPI/TimeslotDetails', {
    checkId,
    scheduledDate,
    productId,
    selectedScheduledDates,
  });
