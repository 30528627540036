import TERMINAL_MODES from './terminalModes';
import PAYMENT_PROCESSORS from './PaymentProcessors';

const TERMINAL_DEFAULT_SETTINGS = {
  MODE: TERMINAL_MODES.foodAndActivities.value,
  PAYMENT_PROCESSOR: PAYMENT_PROCESSORS.clover.value,
  TEXT_2_PAY_ENABLED: false,
  SESSION_TIME_OUT: 10,
  LINK_EXPIRATION_TIME_TEXT_2_PAY: 5,
  NATIVE_KEYBOARD: true,
};

export default TERMINAL_DEFAULT_SETTINGS;
