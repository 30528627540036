import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNativeKeyboardAction } from '../../store/Settings/actions'
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors'
import Checkbox from '../Checkbox';



const NativeKeyboardToggle: React.FC = () => {
  const dispatch = useDispatch();

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);

  const www = () => {
    dispatch(updateNativeKeyboardAction(!nativeKeyboardState))
  }

  return <Checkbox state={nativeKeyboardState} label="Native keyboard" handleClick={www} /> ;
};

export default NativeKeyboardToggle;
