import React from 'react';
import styles from './styles.module.scss';
import { getPrice } from '../../utils/core';
import { useSelector } from 'react-redux';

interface ProductGridItemProps {
  cover?: string;
  highResolutionCover?: string | null;
  title?: string;
  handleClick?: () => void;
  isRequiredMembership?: boolean;
  price?: any;
}

const ProductGridItem = ({ cover, highResolutionCover, title, handleClick, price }: ProductGridItemProps) => {
  const { customerStore } = useSelector(state => state);
  return (
    <div className={styles.categoryItem} onClick={handleClick}>
      {price && <div className={styles.categoryItemPrice}>${getPrice(price, customerStore.priceLevel)}</div>}
      <div className={styles.categoryItemCover}>
        <img src={highResolutionCover ? highResolutionCover : `data:image/jpeg;base64,${cover}`} alt="" />
      </div>
      <div className={styles.categoryItemTitleContainer}>
        <h3 className={styles.categoryItemTitle}>{title}</h3>
      </div>
    </div>
  );
};

export default ProductGridItem;
