import { takeLatest, put, all, call } from 'redux-saga/effects';
import { addToCustomerRelationships } from '../../../api';
import {
  GET_CUSTOMER_RELATIONSHIPS_TYPES,
  getCustomerRelationshipsActionSuccess,
  getCustomerRelationshipsActionFailure,
} from '../actions';

export function* getCustomerRelationshipsWorker({ payload }: any) {
  const { custId } = payload;
  try {
    const { data } = yield call<any>(addToCustomerRelationships, custId);
    yield put<any>(getCustomerRelationshipsActionSuccess(data));
  } catch (err) {
    yield put<any>(getCustomerRelationshipsActionFailure());
  }
}

export function* getCustomerRelationshipsWatcher() {
  yield takeLatest(GET_CUSTOMER_RELATIONSHIPS_TYPES.REQUEST, getCustomerRelationshipsWorker);
}

export default function* getCustomerRelationshipsSaga() {
  yield all([getCustomerRelationshipsWatcher()]);
}
