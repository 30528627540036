import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logInAntherWayUpdatePhoneAction } from '../../store/Customer/actions';
import ContentContainer from '../../components/ContentContainer';
import IdleMonitor from '../../services/IdleMonitor';
import PhoneKeypad from '../../components/PhoneKeypad';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import HeaderPanel from '../../components/HeaderPanel';
import cn from 'classnames';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

type formType = {
  cell: string;
};

const LogInAnotherWayEnterPhoneScreen = () => {
  const { customerStore } = useSelector(state => state);
  const dispatch = useDispatch();

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [fieldsOfForm, setFieldsOfForm] = useState({
    cell: '',
  });

  const [errorsOfForm, setErrorsOfForm] = useState({
    cell: '',
  });

  const validateFieldsOfForm = () => {
    if (fieldsOfForm.cell === '') {
      setErrorsOfForm({ ...errorsOfForm, ['cell']: 'Please enter phone number' });
      return false;
    }
    setErrorsOfForm({ ...errorsOfForm, ['cell']: '' });

    return true;
  };

  const [activeField, setActiveField] = useState<keyof formType>('cell');

  const phoneKeypadHandler = (newValueOfField: string) => {
    setFieldsOfForm({ ...fieldsOfForm, [activeField]: newValueOfField });
  };

  const updatePhone = () => {
    if (!validateFieldsOfForm()) return;
    if (fieldsOfForm.cell.length >= 3) {
      dispatch(
        logInAntherWayUpdatePhoneAction({
          custId: customerStore.custId,
          fName: customerStore.fName,
          lName: customerStore.lName,
          birthDate: customerStore.birthDate,
          cell: fieldsOfForm.cell,
          pin: 'pin',
        }),
      );
    }
  };

  return (
    <Fragment>
      <ContentContainer loading={customerStore.loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div className={styles.rowMargin}>
              <TextFieldWithLabel
                type="text"
                inputMode={nativeKeyboardState}
                value={fieldsOfForm['cell']}
                required={true}
                placeholder=""
                label="Mobile Number"
                onFocus={() => {}}
                invalid={Boolean(errorsOfForm.cell.length)}
                errorMessage={errorsOfForm.cell}
              />
            </div>
            <div className={styles.rowMargin}>
              <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler} />
            </div>

            <div className={styles.navigation}>
              <Button
                theme="red"
                handleClick={updatePhone}
                customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default LogInAnotherWayEnterPhoneScreen;
