import { takeLatest, put, all, call } from 'redux-saga/effects';
import { removeFromCustomerGroup } from '../../../api';
import {
  REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES,
  removeMemberFromCustomerGroupActionSuccess,
  removeMemberFromCustomerGroupActionFailure,
} from '../actions';

export function* removeMemberFromCustomerGroupWorker() {
  try {
    yield call<any>(removeFromCustomerGroup, {});
    yield put<any>(removeMemberFromCustomerGroupActionSuccess({}));
  } catch (err) {
    yield put<any>(removeMemberFromCustomerGroupActionFailure());
  }
}

export function* removeMemberFromCustomerGroupWatcher() {
  yield takeLatest(REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES.REQUEST, removeMemberFromCustomerGroupWorker);
}

export default function* removeMemberFromCustomerGroupSaga() {
  yield all([removeMemberFromCustomerGroupWatcher()]);
}
