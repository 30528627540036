export const UPDATE_KDS_DATA_TYPES = {
  REQUEST: 'payByLink/UPDATE_KDS_DATA_REQUEST',
  SUCCESS: 'payByLink/UPDATE_KDS_DATA_SUCCESS',
  FAILURE: 'payByLink/UPDATE_KDS_DATA_FAILURE',
};

export const UPDATE_KDS_DATA_START = 'UPDATE_KDS_DATA_START';

export const updateKdsDataStartAction = (payload: any) => ({
  type: UPDATE_KDS_DATA_START,
  payload,
});

export const updateKdsDataAction = (payload: any) => ({
  type: UPDATE_KDS_DATA_TYPES.REQUEST,
  payload,
});

export const updateKdsDataActionSuccess = (payload: any) => ({
  type: UPDATE_KDS_DATA_TYPES.SUCCESS,
  payload,
});

export const CANCEL_UPDATE_KDS_DATA = 'payByLink/CANCEL_UPDATE_KDS_DATA';

export const cancelUpdateKdsDataStartAction = () => ({
  type: CANCEL_UPDATE_KDS_DATA,
});

export const updateKdsDataActionFailure = () => ({
  type: UPDATE_KDS_DATA_TYPES.FAILURE,
});
