import { takeLatest, all, select, call, put } from 'redux-saga/effects';
import { RESET_PAYMENT_PROCESS_TYPE, resetPaymentProcessSuccessAction } from '../actions';
import { history } from '../../../App';
import { deletePaymentLink } from '../../../api';
import { ROUTES } from '../../../constants';

export function* resetPaymentProcessWorker() {
  try {
    const {
      payByLink: { paymentLinkId },
    } = yield select();
    // yield call(deletePaymentLink, { paymentLinkId });
    yield put(resetPaymentProcessSuccessAction());
    history.push(ROUTES.PRODUCTS);
  } catch (e) {
    console.log(e);
  }
}

export default function* resetPaymentProcessSaga() {
  yield all([yield takeLatest(RESET_PAYMENT_PROCESS_TYPE.RESET_PAYMENT_PROCESS_REQUEST, resetPaymentProcessWorker)]);
}
