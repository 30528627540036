import { takeLatest, put, all, call } from 'redux-saga/effects';
import { updatePhoneAndPin } from '../../../api';
import { ROUTES } from '../../../constants';
import { history } from '../../../App';
import { LOG_IN_ANOTHER_WAY_TYPES, logInAntherWayUpdatePhoneSuccess, logInAntherWayUpdatePinSuccess } from '../actions';

function* logInAntherWayAddPhoneAndPinWorker({ payload }: any) {
  try {
    history.push(ROUTES.LOG_IN_ANOTHER_WAY_ENTER_PHONE);
  } catch (err) {}
}

function* logInAntherWayAddPinWorker({ payload }: any) {
  try {
    history.push(ROUTES.LOG_IN_ANOTHER_WAY_ENTER_PIN);
  } catch (err) {}
}

function* logInAntherWayEnterPinWorker({ payload }: any) {
  try {
    history.push(ROUTES.LOG_IN_SECOND_STEP);
  } catch (err) {}
}

function* logInAntherWayUpdatePhoneWorker({ payload }: any) {
  try {
    yield call<any>(
      updatePhoneAndPin,
      payload.custId,
      payload.fName,
      payload.lName,
      payload.birthDate,
      payload.cell,
      payload.pin,
    );
    yield put<any>(logInAntherWayUpdatePhoneSuccess(payload));
    history.push(ROUTES.LOG_IN_ANOTHER_WAY_ENTER_PIN);
  } catch (err) {}
}

function* logInAntherWayUpdatePinWorker({ payload }: any) {
  try {
    yield call<any>(
      updatePhoneAndPin,
      payload.custId,
      payload.fName,
      payload.lName,
      payload.birthDate,
      payload.cell,
      payload.pin,
    );
    yield put<any>(logInAntherWayUpdatePinSuccess(payload));
    history.push(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
  } catch (err) {}
}

export default function* resetPinSaga() {
  yield all([
    yield takeLatest(LOG_IN_ANOTHER_WAY_TYPES.ADD_PHONE_AND_PIN, logInAntherWayAddPhoneAndPinWorker),
    yield takeLatest(LOG_IN_ANOTHER_WAY_TYPES.ADD_PIN, logInAntherWayAddPinWorker),
    yield takeLatest(LOG_IN_ANOTHER_WAY_TYPES.ENTER_PIN, logInAntherWayEnterPinWorker),
    yield takeLatest(LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PHONE_REQUEST, logInAntherWayUpdatePhoneWorker),
    yield takeLatest(LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PIN_REQUEST, logInAntherWayUpdatePinWorker),
  ]);
}
