import { GET_PRODUCT_CATEGORIES_TYPES } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  categoriesList: [],
  loading: true,
};

export type InitialStateType = typeof initialState;

export default function productCategoriesReducer(state = initialState, { type, payload = {} }: any,
): InitialStateType {
  switch (type) {
    case GET_PRODUCT_CATEGORIES_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_CATEGORIES_TYPES.SUCCESS:
      return {
        ...state,
        categoriesList: payload.categoriesList,
        loading: false,
      };
    case GET_PRODUCT_CATEGORIES_TYPES.FAILURE:
      return {
        ...state,
        categoriesList: [],
        loading: false,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
