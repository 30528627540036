import { takeLatest, put, all, call } from 'redux-saga/effects';
import { removeFromCustomerRelationships } from '../../../api';
import {
  REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES,
  removeMemberFromCustomerRelationshipsActionSuccess,
  removeMemberFromCustomerRelationshipsActionFailure,
} from '../actions';

export function* removeMemberFromCustomerRelationshipsWorker() {
  try {
    yield call<any>(removeFromCustomerRelationships, {});
    yield put<any>(removeMemberFromCustomerRelationshipsActionSuccess({}));
  } catch (err) {
    yield put<any>(removeMemberFromCustomerRelationshipsActionFailure());
  }
}

export function* removeMemberFromCustomerRelationshipsWatcher() {
  yield takeLatest(
    REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES.REQUEST,
    removeMemberFromCustomerRelationshipsWorker,
  );
}

export default function* removeMemberFromCustomerRelationshipsSaga() {
  yield all([removeMemberFromCustomerRelationshipsWatcher()]);
}
