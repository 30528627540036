import _ from 'lodash';

const detectRequiredField = (fields, field) => {
  if (!_.find(fields, obj => obj.customerFieldName === field)) {
    return false;
  }
  return _.find(fields, obj => obj.customerFieldName === field).required;
};

export default detectRequiredField;
