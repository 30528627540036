import React, { useState, useEffect } from 'react';
import * as Api from '../../api';
import blobToBase64 from '../../utils/blobToBase64';
import defaultAvatar from '../../assets/icons/default-avatar.svg';
import styles from './styles.module.scss';

interface AvatarProps {
  custId: number | string;
  customerImage?: string;
}

const Avatar = ({ customerImage, custId }: AvatarProps) => {
  const [avatarData, setAvatarData] = useState(null);

  useEffect(() => {
    fetchAvatar();
  }, [custId]);

  const fetchAvatar = async () => {
    try {
      if (customerImage !== null && customerImage !== undefined) {
        setAvatarData(customerImage);
        return;
      }
      const { data } = await Api.fetchCustomerPicture(custId);
      if (data !== 'File not found' && data !== 'RmlsZSBub3QgZm91bmQ=') {
        setAvatarData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.avatar}>
      {!avatarData && <img src={defaultAvatar} className={styles.avatarImg} alt="" />}
      {!!avatarData && <img src={avatarData} className={styles.avatarImg} alt="" />}
    </div>
  );
};

Avatar.defaultProps = {};

export default Avatar;
