import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as Api from '../../api';
import { history } from '../../App';
import HeaderPanel from '../../components/HeaderPanel';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';

import WizardFormStep from '../../components/WizardFormStep';
import detectLabelField from '../../utils/detectLabelField';
import detectRequiredField from '../../utils/detectRequiredField';
import { COUNTRIES, GENDER } from '../../constants';
import validator from '../../utils/validator';
import isAdult from '../../utils/isAdult';
import moment from 'moment';
import styles from './styles.module.scss';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import WarningAlert from '../../components/WarningAlert';
import { saveCustomerToStoreAction } from '../../store/Customer/actions';
import { getCustomerGroupAction } from '../../store/CustomerGroup/actions';
import NavigationPanel from '../../components/NavigationPanel';
import SignatureCanvas from '../../components/SignatureCanvas';
import { createAdultWaiver, createMinorWaiver } from '../../utils/waivers';
import WaiverAgreementText from '../../components/WaiverAgreementText';
import STATES from '../../constants/states';

const EditProductsScreen = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { custId } = useParams();
  const {
    source: sourceStore,
    customerStore: primaryCustomer,
    registrationSettings: {
      adultRegistrationFields,
      newCustomerRegistrationSteps,
      allowDuplicateEmail,
      adultAge,
      waiverStep,
    },
  } = useSelector(state => state);
  const sigPad = React.useRef(null);
  const [customerData, setCustomerData] = useState({});
  const [initialCustomerFields, setInitialCustomerFields] = useState({});
  const [customerFields, setCustomerFields] = useState({});
  const [customerImage, setCustomerImage] = useState(null);
  const [activeField, setActiveField] = useState(newCustomerRegistrationSteps[0]);
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [agreeWithRulesError, setAgreeWithRulesError] = useState(false);
  const [errorsOfField, setErrorsOfField] = useState('');
  const [warningBirthDateMessage, setWarningBirthDateMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isResetSignatureModalShow, setIsResetSignatureModalShow] = useState(false);

  const fetchCustomer = async () => {
    try {
      setLoading(true);
      const { data: customerData } = await Api.fetchCustomerById(custId);
      const { data: customerPictureData } = await Api.fetchCustomerPicture(custId);
      if (customerPictureData !== 'File not found' && customerPictureData !== 'RmlsZSBub3QgZm91bmQ=') {
        setCustomerImage(customerPictureData);
      }
      setCustomerData(customerData);
      setCustomerFields(customerData);
      setInitialCustomerFields(customerData);
      customerData.birthDate = moment(customerData.birthDate).format('MM/DD/YYYY');
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCustomer();
    return () => {};
  }, []);

  const onChangeField = async (fieldName: string, newFieldValue: any) => {
    try {
      if (fieldName === 'customerImage') {
        setCustomerImage(newFieldValue);
        return;
      }
      setCustomerFields({ ...customerFields, [fieldName]: newFieldValue });
      setErrorsOfField('');
      if (fieldName === 'fName' || fieldName === 'lName') {
        await validator(
          activeField,
          newFieldValue,
          detectRequiredField(adultRegistrationFields, activeField),
          allowDuplicateEmail,
        );
      }
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const updateDoNotMail = () => {
    setCustomerFields({ ...customerFields, doNotMail: !customerFields['doNotMail'] });
  };

  const isRequiredFieldsWasEdited = () => {
    if (initialCustomerFields['fName'] !== customerFields['fName']) {
      return true;
    }
    if (initialCustomerFields['lName'] !== customerFields['lName']) {
      return true;
    }
    if (initialCustomerFields['address'] !== customerFields['address']) {
      return true;
    }
    if (initialCustomerFields['city'] !== customerFields['city']) {
      return true;
    }
    if (initialCustomerFields['state'] !== customerFields['state']) {
      return true;
    }
    if (initialCustomerFields['country'] !== customerFields['country']) {
      return true;
    }
    if (initialCustomerFields['zip'] !== customerFields['zip']) {
      return true;
    }
    if (initialCustomerFields['licenseNumber'] !== customerFields['licenseNumber']) {
      return true;
    }
    if (initialCustomerFields['cell'] !== customerFields['cell']) {
      return true;
    }
    if (initialCustomerFields['emailAddress'] !== customerFields['emailAddress']) {
      return true;
    }
    if (initialCustomerFields['birthDate'] !== customerFields['birthDate']) {
      return true;
    }
    return false;
  };

  const goToNextStep = async () => {
    try {
      setLoading(true);
      if (activeField === 'customerImage') {
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
      if (activeField === 'birthDate') {
        if (initialCustomerFields['birthDate'] !== customerFields['birthDate']) {
          if (isAdult(initialCustomerFields['birthDate'], adultAge)) {
            if (!isAdult(customerFields['birthDate'], adultAge)) {
              setWarningBirthDateMessage(
                'Please see a cashier to change your  profile from a Adult Profile to an Minor Profile',
              );
              setLoading(false);
              return;
            }
          }
          if (!isAdult(initialCustomerFields['birthDate'], adultAge)) {
            if (isAdult(customerFields['birthDate'], adultAge)) {
              setWarningBirthDateMessage(
                'Please see a cashier to change your  profile from a Minor Profile to an Adult Profile',
              );
              setLoading(false);
              return;
            }
          }
        }
      }
      if (activeField === 'emailAddress' && customerFields['emailAddress'] === customerData['emailAddress']) {
        const indexOfCurrentField = newCustomerRegistrationSteps.indexOf(activeField);
        const stepPoint = activeField === newCustomerRegistrationSteps[indexOfCurrentField + 1] ? 2 : 1;
        setActiveField(newCustomerRegistrationSteps[indexOfCurrentField + stepPoint]);
        setLoading(false);
        return;
      }
      await validator(
        activeField,
        customerFields[activeField],
        detectRequiredField(adultRegistrationFields, activeField),
        allowDuplicateEmail,
      );
      const indexOfCurrentField = newCustomerRegistrationSteps.indexOf(activeField);
      if (indexOfCurrentField === newCustomerRegistrationSteps.length - 1) {
        if (isRequiredFieldsWasEdited()) {
          if (isAdult(customerFields['birthDate'], adultAge)) {
            setActiveField('waiverAgreementAdult');
          } else {
            setActiveField('waiverAgreementMinor');
          }
          setLoading(false);
          return;
        }
        await Api.updateCustomer(custId, customerFields);
        if (!!customerImage) {
          await Api.uploadCustomerPicture(custId, customerImage);
        }
        const { data: updatedPrimaryCustomerData } = await Api.fetchCustomerById(primaryCustomer.custId);
        dispatch(saveCustomerToStoreAction(updatedPrimaryCustomerData));
        dispatch(getCustomerGroupAction({ custId: primaryCustomer.custId }));
        setLoading(false);
        history.goBack();
        return;
      }
      const stepPoint = activeField === newCustomerRegistrationSteps[indexOfCurrentField + 1] ? 2 : 1;
      setActiveField(newCustomerRegistrationSteps[indexOfCurrentField + stepPoint]);
      setLoading(false);
    } catch (e) {
      console.log({ e });
      setLoading(false);
      setErrorsOfField(e.message);
    }
  };

  const goToPrevStep = () => {
    if (activeField === 'waiverAgreementMinor' || activeField === 'waiverAgreementAdult') {
      setAgreeWithRules(false);
      setActiveField(newCustomerRegistrationSteps[newCustomerRegistrationSteps.length - 1]);
      return;
    }
    const indexOfCurrentField = newCustomerRegistrationSteps.indexOf(activeField);
    if (indexOfCurrentField === 0) {
      return history.goBack();
    }
    setActiveField(newCustomerRegistrationSteps[indexOfCurrentField - 1]);
  };

  const backToInitialBirthDate = () => {
    setCustomerFields({ ...customerFields, birthDate: initialCustomerFields['birthDate'] });
    setWarningBirthDateMessage('');
  };

  const updateProfileFinalUpdate = async () => {
    const signatureOfCustomer = sigPad.current.toDataURL().substr(22);
    if (!agreeWithRules) {
      setAgreeWithRulesError(true);
      return;
    }
    setAgreeWithRulesError(false);
    if (sigPad.current.isEmpty()) {
      return;
    }
    setLoading(true);
    await Api.updateCustomer(custId, customerFields);
    if (!!customerImage) {
      await Api.uploadCustomerPicture(custId, customerImage);
    }
    setLoading(false);
    if (isAdult(customerFields['birthDate'], adultAge)) {
      const adultWaiver = await createAdultWaiver({
        signature: signatureOfCustomer,
        adultCustId: custId,
      });
      if (!adultWaiver) {
        setIsResetSignatureModalShow(true);
        return;
      }
    } else {
      const minorWaiver = await createMinorWaiver({
        guardianSignature: signatureOfCustomer,
        minorCustId: custId,
        guardianCustId: primaryCustomer.custId,
      });
      if (!minorWaiver) {
        setIsResetSignatureModalShow(true);
        return;
      }
    }
    const { data: updatedPrimaryCustomerData } = await Api.fetchCustomerById(primaryCustomer.custId);
    dispatch(saveCustomerToStoreAction(updatedPrimaryCustomerData));
    dispatch(getCustomerGroupAction({ custId: primaryCustomer.custId }));
    history.goBack();
    return;
  };

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };

  const resetSignatureModalHandleClick = () => {
    clearSignature();
    setIsResetSignatureModalShow(false);
  };

  return (
    <Fragment>
      <ContentContainer loading={loading} bgColor="#FFFFFF">
        <HeaderPanel homeButton logOutButton border />
        <WizardFormStep
          type="numeric"
          fieldName="birthDate"
          value={customerFields['birthDate'] || ''}
          label={detectLabelField(adultRegistrationFields, 'birthDate') + ' MM/DD/YYYY'}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'birthDate' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'birthDate')}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
        />
        <WizardFormStep
          type="text"
          fieldName="fName"
          value={customerFields['fName']}
          label={detectLabelField(adultRegistrationFields, 'fName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'fName' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'fName')}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
        />
        <WizardFormStep
          type="text"
          fieldName="lName"
          value={customerFields['lName']}
          label={detectLabelField(adultRegistrationFields, 'lName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'lName' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'lName')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="cell"
          value={customerFields['cell']}
          label={detectLabelField(adultRegistrationFields, 'cell')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'cell' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'cell')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="email"
          fieldName="emailAddress"
          value={customerFields['emailAddress']}
          label={detectLabelField(adultRegistrationFields, 'emailAddress')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'emailAddress' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'emailAddress')}
          doNotMail={!customerFields['doNotMail']}
          setDoNotMail={updateDoNotMail}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="radio"
          fieldName="gender"
          value={customerFields['gender']}
          label={detectLabelField(adultRegistrationFields, 'gender')}
          options={GENDER}
          valueField="value"
          displayField="title"
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'gender' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'gender')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="racerName"
          value={customerFields['racerName']}
          label={detectLabelField(adultRegistrationFields, 'racerName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'racerName' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'racerName')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="address"
          value={customerFields['address']}
          label={detectLabelField(adultRegistrationFields, 'address')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'address' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'address')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="address2"
          value={customerFields['address2']}
          label={detectLabelField(adultRegistrationFields, 'address2')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'address2' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'address2')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="city"
          value={customerFields['city']}
          label={detectLabelField(adultRegistrationFields, 'city')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'city' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'city')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type={customerFields['country'] === 'United States' ? 'typeSelector' : 'text'}
          fieldName="state"
          value={customerFields['state']}
          label={detectLabelField(adultRegistrationFields, 'state')}
          onChange={onChangeField}
          placeholder="Please select a State"
          errorMessage={errorsOfField}
          isActive={'state' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'state')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
          options={STATES}
          displayField={detectLabelField(adultRegistrationFields, 'state')}
        />
        <WizardFormStep
          type="typeSelector"
          fieldName="country"
          value={customerFields['country']}
          options={COUNTRIES}
          displayField={detectLabelField(adultRegistrationFields, 'country')}
          label={detectLabelField(adultRegistrationFields, 'country')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'country' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'country')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="zip"
          value={customerFields['zip']}
          label={detectLabelField(adultRegistrationFields, 'zip')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'zip' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'zip')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="itemSelect"
          fieldName="sourceId"
          value={customerFields['sourceId']}
          label={detectLabelField(adultRegistrationFields, 'sourceId')}
          options={sourceStore.data}
          valueField="sourceId"
          displayField="sourceName"
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'sourceId' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'sourceId')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="picture"
          fieldName="customerImage"
          value={customerImage}
          label="Picture"
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'customerImage' === activeField}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="licenseNumber"
          value={customerFields['licenseNumber']}
          label={detectLabelField(adultRegistrationFields, 'licenseNumber')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'licenseNumber' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'licenseNumber')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom1"
          value={customerFields['custom1']}
          label={detectLabelField(adultRegistrationFields, 'custom1')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom1' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'custom1')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom2"
          value={customerFields['custom2']}
          label={detectLabelField(adultRegistrationFields, 'custom2')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom2' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'custom2')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom3"
          value={customerFields['custom3']}
          label={detectLabelField(adultRegistrationFields, 'custom3')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom3' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'custom3')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        {'waiverAgreementMinor' === activeField && (
          <Fragment>
            <div className={styles.contentWaiver}>
              <div className={styles.waiverAgreementTextContainer}>
                <span className={styles.waiverAgreementTextTitle}>
                  Waiver Agreement - {primaryCustomer.fName} {primaryCustomer.lName} (signing for{' '}
                  {customerFields['fName']} {customerFields['lName']})
                </span>
                <WaiverAgreementText waiverText={waiverStep.minorWaiverText} />
              </div>
              <div className={styles.waiverAgreementTextContainer}>
                <div className={styles.iAgreeTextbox}>
                  <Checkbox
                    state={!!agreeWithRules}
                    label={`I, ${primaryCustomer.fName} ${primaryCustomer.lName}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to both my own waiver, and the waiver for any minors present in my group.`}
                    handleClick={() => setAgreeWithRules(prevState => !prevState)}
                    error={agreeWithRulesError}
                  />
                </div>
              </div>

              {agreeWithRules && (
                <div className={styles.waiverAgreementTextContainer}>
                  <div className={styles.waiverAgreementSignatureCanvas}>
                    <span className={styles.waiverAgreementSignatureTitle}>
                      <h2>
                        {primaryCustomer.fName} {primaryCustomer.lName}, please sign below.
                      </h2>
                    </span>
                  </div>
                  <SignatureCanvas sigPad={sigPad} />
                </div>
              )}
              <div className={styles.navigation}>
                <NavigationPanel
                  onLeftButtonClick={goToPrevStep}
                  leftButtonTitle="Back"
                  onRightButtonClick={updateProfileFinalUpdate}
                  rightButtonTitle="Continue"
                />
                {/*<Button theme="white" handleClick={() => goToPrevStep()} customClass={styles.navigationButton}>*/}
                {/*  Back*/}
                {/*</Button>*/}
                {/*<div className={styles.navigationFilter} />*/}
                {/*<Button*/}
                {/*  theme="red"*/}
                {/*  handleClick={() => updateProfileFinalUpdate()}*/}
                {/*  customClass={styles.navigationButton}*/}
                {/*>*/}
                {/*  Continue*/}
                {/*</Button>*/}
              </div>
            </div>
          </Fragment>
        )}
        {'waiverAgreementAdult' === activeField && (
          <Fragment>
            <div className={styles.contentWaiver}>
              <div className={styles.waiverAgreementTextContainer}>
                <span className={styles.waiverAgreementTextTitle}>
                  Waiver Agreement - {customerFields['fName']} {customerFields['lName']}
                </span>
                <WaiverAgreementText waiverText={waiverStep.adultWaiverText} />
              </div>
              <div className={styles.waiverAgreementTextContainer}>
                <div className={styles.iAgreeTextbox}>
                  <Checkbox
                    state={!!agreeWithRules}
                    label={`I, ${primaryCustomer.fName} ${primaryCustomer.lName}, agree to the terms and conditions of the above agreement.`}
                    handleClick={() => setAgreeWithRules(prevState => !prevState)}
                    error={agreeWithRulesError}
                  />
                </div>
              </div>
              {agreeWithRules && (
                <div className={styles.waiverAgreementTextContainer}>
                  <div className={styles.waiverAgreementSignatureCanvas}>
                    <span className={styles.waiverAgreementSignatureTitle}>
                      <h2>
                        {customerFields['fName']} {customerFields['lName']}, please sign below.
                      </h2>
                    </span>
                  </div>
                  <SignatureCanvas sigPad={sigPad} />
                </div>
              )}

              <div className={styles.navigation}>
                <Button theme="white" handleClick={() => goToPrevStep()} customClass={styles.navigationButton}>
                  Back
                </Button>
                <div className={styles.navigationFilter} />
                <Button
                  theme="red"
                  handleClick={() => updateProfileFinalUpdate()}
                  customClass={styles.navigationButton}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Fragment>
        )}
      </ContentContainer>
      <WarningAlert
        zIndex={9999}
        isOpen={isResetSignatureModalShow}
        title="Whoops!"
        description="An error occurred while creating your signature, please try again."
        handleClick={resetSignatureModalHandleClick}
      />
      <WarningAlert
        isOpen={!!warningBirthDateMessage}
        title={warningBirthDateMessage}
        description=""
        handleClick={backToInitialBirthDate}
      />
      <WarningAlert
        isOpen={!!warningBirthDateMessage}
        title={warningBirthDateMessage}
        description=""
        handleClick={backToInitialBirthDate}
      />
      <IdleMonitor />
    </Fragment>
  );
};

export default EditProductsScreen;
