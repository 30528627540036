import React from 'react';
import styles from './Title.module.scss';

interface TitleProps {
  message?: string;
  children?: React.ReactNode;
}

const Title = ({ message, children }: TitleProps) => {
  return (
    <div className={styles.titleWrap} id="panelTitle">
      <h2 className={styles.title}>{message}</h2>
      <div>{children}</div>
    </div>
  );
};

export default Title;
