import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { retryPaymentAction } from '../../store/Payment/actions';
import HeaderPanel from '../../components/HeaderPanel';
import OhNoWhiteIcon from '../../assets/icons/oh-no-white.svg';
import ContentContainer from '../../components/ContentContainer';
import styles from './styles.module.scss';

const PaymentErrorScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(retryPaymentAction());
  }, []);

  return (
    <Fragment>
      <ContentContainer theme="red">
        <HeaderPanel theme="dark" />
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <img src={OhNoWhiteIcon} className={styles.errorIcon} alt="" />
            <div className={styles.separator} />
            <div className={styles.errorMessageContainer}>
              <p className={styles.errorMessage}>
                We were unable to process your payment.
                <br />
                Please try again or use a different card.
              </p>
            </div>
          </div>
        </div>
      </ContentContainer>
    </Fragment>
  );
};

export default PaymentErrorScreen;
