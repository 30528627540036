import { takeLatest, all } from 'redux-saga/effects';
import { history } from '../../../App';
import { END_CUSTOMER_SESSION } from '../../General/actions';
import { ROUTES } from '../../../constants';

function* logOutWorker() {
  history.replace(ROUTES.ROOT);
}

export default function* generalSaga() {
  yield all([yield takeLatest(END_CUSTOMER_SESSION, logOutWorker)]);
}
