import { takeLatest, put, all, call } from 'redux-saga/effects';
import _ from 'lodash';
import { fetchRegistrationSettings } from '../../../api';

import {
  GET_REGISTRATION_SETTINGS_TYPES,
  getRegistrationSettingsActionSuccess,
  getRegistrationSettingsActionFailure,
  getRegistrationSettingsAction,
} from '../actions';
import * as Api from '../../../api';

export function* getRegistrationSettingsSagaWorker() {
  try {
    const { data } = yield call<any>(fetchRegistrationSettings);
    const adultRegistrationFields = data.adultRegistrationFields.map(value => value.customerFieldName);
    const minorRegistrationFields = data.minorRegistrationFields.map(value => value.customerFieldName);

    let newCustomerRegistrationSteps = [...adultRegistrationFields];
    const newAdultRegistrationSteps = [...adultRegistrationFields];
    const newMinorRegistrationSteps = minorRegistrationFields;

    if (data.captureProfilePicture) {
      newCustomerRegistrationSteps.splice(7, 0, 'customerImage');
      newAdultRegistrationSteps.splice(7, 0, 'customerImage');
      newMinorRegistrationSteps.splice(7, 0, 'customerImage');
    }

    const { data: controlPanelEnableRelationshipScreen } = yield call(
      Api.fetchControlPanelSettings,
      'EnableRelationshipScreen',
      'MainEngine',
    );
    const enableRelationshipScreen = controlPanelEnableRelationshipScreen[0].settingValue.toLowerCase() === 'true';

    newCustomerRegistrationSteps = _.without(newCustomerRegistrationSteps, 'cell');
    yield put<any>(
      getRegistrationSettingsActionSuccess({
        ...data,
        newCustomerRegistrationSteps,
        newAdultRegistrationSteps,
        newMinorRegistrationSteps,
        enableRelationshipScreen,
      }),
    );
  } catch (err) {
    yield put<any>(getRegistrationSettingsActionFailure());
    yield put<any>(getRegistrationSettingsAction());
  }
}

export function* getRegistrationSettingsSagaWatcher() {
  yield takeLatest(GET_REGISTRATION_SETTINGS_TYPES.REQUEST, getRegistrationSettingsSagaWorker);
}

export default function* registrationSettingsSaga() {
  yield all([getRegistrationSettingsSagaWatcher()]);
}
