import React, { useRef, useState, useEffect } from 'react';
import Keyboard from 'react-simple-keyboard';
import { KEYBOARD_LAYOUTS } from '../../constants';
import styles from './styles.module.scss';
import './keyboardStyles.css';

interface KeyboardProps {
  sentenceMode?: boolean;
  baseClass?: string;
  layout?: string;
  fieldName?: string;
  valueOfField: string | number;
  onChange: (input: string, fieldName: string) => void;
  mask?: RegExp;
  hideKeyboard?: () => void;
  handleEnter: any;
  maxLength?: any;
  handleBackspace?: () => void;
}

const KeyboardHOC = ({
  baseClass,
  layout,
  fieldName,
  valueOfField,
  onChange,
  mask,
  hideKeyboard,
  handleEnter,
  sentenceMode,
  maxLength,
  handleBackspace,
}: KeyboardProps) => {
  const [currentKeyboardLayout, setCurrentKeyboardLayout] = useState(layout);
  const keyboard = useRef(null);

  useEffect(() => {
    if (layout !== 'number' && layout !== 'cardNumber') {
      if (!valueOfField) {
        if (currentKeyboardLayout === 'email' || currentKeyboardLayout === 'emailShift') {
          return setCurrentKeyboardLayout(layout);
        }
        return setCurrentKeyboardLayout(`${layout}Shift`);
      }
      if (valueOfField.toString().length < 2) {
        setCurrentKeyboardLayout(layout);
      }
    }
    if (layout !== 'email') {
      const valueInString = `${valueOfField}`;
      if (!sentenceMode) {
        if (valueInString.slice(valueInString.length - 2) === '. ') setCurrentKeyboardLayout(`${layout}Shift`);
        if (valueInString.slice(valueInString.length - 2) !== '. ') setCurrentKeyboardLayout(layout);
      }
    }
  }, [valueOfField]);

  useEffect(() => {
    keyboard.current.setInput(valueOfField);
  }, [valueOfField, fieldName]);

  const display = {
    '{bksp}': 'back',
    '{enter}': 'enter',
    '{shift}': 'shift',
    '{space}': 'space',
    '{abc}': 'abc',
    '{!123}': '!123',
  };

  const onChangeValue = (newFieldValue: string, fieldName: string) => {
    if (newFieldValue.length > 0 && currentKeyboardLayout !== layout) {
      return onChange(newFieldValue, fieldName);
    }
    if (newFieldValue.length > 0) {
      setCurrentKeyboardLayout(layout);
    }
    if (!sentenceMode && layout !== 'cardNumber') {
      if (newFieldValue.slice(newFieldValue.length - 2) === '. ') setCurrentKeyboardLayout(`${layout}Shift`);
    }

    onChange(newFieldValue, fieldName);
  };

  const handleShift = () => {
    if (currentKeyboardLayout === 'email' || currentKeyboardLayout === 'emailShift') {
      return setCurrentKeyboardLayout(prevState => (prevState === 'email' ? 'emailShift' : 'email'));
    }
    return setCurrentKeyboardLayout(prevState => (prevState === layout ? `${layout}Shift` : layout));
  };

  const onKeyPress = (button: string) => {
    if (button === '{shift}') handleShift();
    if (button === '{enter}') handleEnter();
    if (button === '{abc}') {
      return setCurrentKeyboardLayout(layout);
    }
    if (button === '{bksp}' && handleBackspace) {
      handleBackspace();
    }
    if (button === '{!123}') {
      return setCurrentKeyboardLayout('specialSymbols');
    }
  };

  const beforeRender = () => {};

  const beforeFirstRender = () => {};

  const onInit = () => {};

  return (
    <div className={styles.kb}>
      <div className={styles.kbWrapper}>
        <Keyboard
          baseClass={fieldName}
          keyboardRef={r => (keyboard.current = r)}
          beforeRender={beforeRender}
          beforeFirstRender={beforeFirstRender}
          onInit={onInit}
          onChange={newFieldValue => onChangeValue(newFieldValue, fieldName)}
          onKeyPress={onKeyPress}
          preventMouseDownDefault={true}
          syncInstanceInputs={true}
          layoutName={currentKeyboardLayout}
          layout={KEYBOARD_LAYOUTS}
          display={display}
          maxLength={maxLength}
          inputPattern={mask}
        />
      </div>
    </div>
  );
};

KeyboardHOC.defaultProps = {
  baseClass: 'baseClass',
  fieldName: '',
  layout: 'default',
  mask: '',
  hideKeyboard: () => {},
};

export default KeyboardHOC;
