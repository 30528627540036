export const GET_PAYMENT_STATUS_TYPES = {
  GET_PAYMENT_STATUS: 'payment/GET_PAYMENT_STATUS_REQUEST',
  GET_PAYMENT_STATUS_SUCCESS: 'payment/GET_PAYMENT_STATUS_SUCCESS',
  GET_PAYMENT_STATUS_FAILURE: 'payment/GET_PAYMENT_STATUS_FAILURE',
};

export const getPaymentStatusAction = (payload: any) => ({
  type: GET_PAYMENT_STATUS_TYPES.GET_PAYMENT_STATUS,
  payload,
});

export const getPaymentStatusActionSuccess = (payload: any) => ({
  type: GET_PAYMENT_STATUS_TYPES.GET_PAYMENT_STATUS_SUCCESS,
  payload,
});

export const getPaymentStatusActionFailure = () => ({
  type: GET_PAYMENT_STATUS_TYPES.GET_PAYMENT_STATUS_FAILURE,
});

export const PAYMENT_IS_FAILED = 'payment/PAYMENT_IS_FAILED';

export const paymentIsFailedAction = () => ({
  type: PAYMENT_IS_FAILED,
});
