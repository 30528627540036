import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createPaymentAction } from '../../store/Payment/actions';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';

import styles from './styles.module.scss';

const PaymentStripeScreen = () => {
  const dispatch = useDispatch();

  return(
    <Fragment>
      <ContentContainer>
        <div className={styles.container}>

        </div>
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default PaymentStripeScreen;
