import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sha256 } from '../../utils/bcrypt';
import ContentContainer from '../../components/ContentContainer';
import IdleMonitor from '../../services/IdleMonitor';
import { updatePinAction } from '../../store/Customer/actions';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import PhoneKeypad from '../../components/PhoneKeypad';
import { endCustomerSessionAction } from '../../store/General/actions';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import cn from 'classnames';
import HeaderPanel from '../../components/HeaderPanel';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

type formType = {
  [key: string]: string;
};

const NewPinScreen = () => {
  const dispatch = useDispatch();
  const { customerStore, resetPinStore } = useSelector(state => state);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [activeField, setActiveField] = useState('pin');
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({
    pin: '',
    confirmPin: '',
    confirmationMode: 'false',
  });
  const [errorsOfForm, setErrorsOfForm] = useState({
    pin: '',
    confirmPin: '',
  });

  const validatePin = () => {
    if (fieldsOfForm.pin === '') {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Please enter pin' });
      return false;
    }
    return true;
  };
  const validateConfirmPin = () => {
    if (fieldsOfForm.confirmPin === '') {
      return false;
    }
    return fieldsOfForm.pin === fieldsOfForm.confirmPin;
  };

  const phoneKeypadHandler = (newValueOfField: string) => {
    setFieldsOfForm({ ...fieldsOfForm, [activeField]: newValueOfField });
  };
  const phoneKeypadHandler2 = (newValueOfField: string) => {
    setFieldsOfForm({ ...fieldsOfForm, ['confirmPin']: newValueOfField });
  };

  const startOver = async () => {
    dispatch(endCustomerSessionAction());
    return;
  };
  const goToConfirmPin = async () => {
    if (!validatePin()) return;
    setActiveField('confirmPin');
    setFieldsOfForm({ ...fieldsOfForm, ['confirmationMode']: 'true' });
    return;
  };

  const updatePinCode = async () => {
    if (!validateConfirmPin()) {
      setActiveField('pin');
      fieldsOfForm.pin = '';
      fieldsOfForm.confirmPin = '';
      errorsOfForm.pin = 'Pins did not match. Please try again';
      setFieldsOfForm({ ...fieldsOfForm, ['confirmationMode']: 'false' });
      return;
    }
    const pinInHash = await sha256(fieldsOfForm.pin);
    dispatch(updatePinAction({ custId: customerStore.custId, resetCodeId: resetPinStore.resetCodeId, pin: pinInHash }));
  };

  return (
    <Fragment>
      <ContentContainer loading={resetPinStore.loading || customerStore.loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div className={styles.rowMargin}>
              {fieldsOfForm.confirmationMode === 'false' && (
                <TextFieldWithLabel
                  type="password"
                  value={fieldsOfForm.pin}
                  placeholder=""
                  inputMode={nativeKeyboardState}
                  label="Enter New 4 Digit Pin"
                  invalid={errorsOfForm.pin.length > 0}
                  errorMessage={errorsOfForm.pin}
                  onFocus={() => setActiveField('pin')}
                />
              )}
              {fieldsOfForm.confirmationMode === 'true' && (
                <TextFieldWithLabel
                  type="password"
                  value={fieldsOfForm.confirmPin}
                  placeholder=""
                  inputMode={nativeKeyboardState}
                  label="Confirm 4 Digit Pin"
                  invalid={errorsOfForm.confirmPin.length > 0}
                  errorMessage={errorsOfForm.confirmPin}
                  onFocus={() => setActiveField('confirmPin')}
                />
              )}
            </div>
            <div className={styles.rowMargin}>
              {fieldsOfForm.confirmationMode === 'false' && (
                <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler} />
              )}
              {fieldsOfForm.confirmationMode === 'true' && (
                <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler2} />
              )}
            </div>

            <div className={styles.navigation}>
              <Button
                theme={'white'}
                handleClick={startOver}
                customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
              >
                Start Over
              </Button>
              <div className={styles.navigationFilter} />
              {fieldsOfForm.confirmationMode === 'false' && (
                <Button
                  theme="red"
                  handleClick={goToConfirmPin}
                  customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
                >
                  Continue
                </Button>
              )}
              {fieldsOfForm.confirmationMode === 'true' && (
                <Button
                  theme="red"
                  handleClick={updatePinCode}
                  customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
                >
                  Continue
                </Button>
              )}
            </div>
          </div>
        </div>
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default NewPinScreen;
