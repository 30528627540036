export const REMOVE_CART_TYPES = {
  REQUEST: 'cart/REMOVE_CART_REQUEST',
  SUCCESS: 'cart/REMOVE_CART_SUCCESS',
  FAILURE: 'cart/REMOVE_CART_FAILURE',
};

export const REMOVE_MEMBERSHIP_FROM_CART_TYPES = {
  SUCCESS: 'cart/REMOVE_MEMBERSHIP_FROM_CART_SUCCESS',
};

export const showRemoveMembershipAlertAction = (payload: any) => ({
  payload,
  type: REMOVE_MEMBERSHIP_FROM_CART_TYPES.SUCCESS,
});

export const deleteCartAction = (payload: any) => ({
  type: REMOVE_CART_TYPES.REQUEST,
  payload,
});

export const removeCartActionSuccess = () => {
  return {
    type: REMOVE_CART_TYPES.SUCCESS,
  };
};

export const removeCartActionFailure = () => {
  return {
    type: REMOVE_CART_TYPES.FAILURE,
  };
};
