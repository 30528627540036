import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import Dropdown from '../Dropdown/Dropdown';

interface Props {
  value: string | number;
  fieldName: string;
  label?: any;
  placeholder?: string;
  inputType?: string;
  invalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  keyboardLayout?: string;
  onChange: (fieldName: string, newValue: string) => void;
  enterClick?: () => void;
  children?: React.ReactNode;
  items: any;
}

const WizardFormDropdownField = ({
  value,
  fieldName,
  label,
  placeholder,
  errorMessage,
  required,
  onChange,
  children,
  items,
}: Props) => {
  const selectDropdownItem = (value: any) => {
    onChange(fieldName, value);
  };
  return (
    <div className={styles.wizardFormField}>
      <div className={styles.wizardFormFieldInner}>
        <div className={cn(styles.tf, { [styles.tdInvalid]: !!errorMessage })}>
          <Dropdown placeholder={placeholder} value={value} selectDropdownItem={selectDropdownItem} items={items} />
          <div className={styles.tfLine} />
          <h3 className={styles.tfLabel}>
            {label}
            {required && <span className={styles.required}>*</span>}
          </h3>
          {!!errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        </div>
        {children}
      </div>
    </div>
  );
};

WizardFormDropdownField.defaultProps = {
  label: '',
  placeholder: '',
  inputType: 'text',
  invalid: false,
  errorMessage: '',
  required: false,
  keyboardLayout: 'default',
  enterClick: () => {},
};

export default WizardFormDropdownField;
