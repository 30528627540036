import React, { useState } from 'react';
import styles from './styles.module.scss';
import TimeSlotGridRow from '../TimeSlotGridRow';
import HorizontalScroll from '../HorizontalScroll';

interface TimeSlotsListProps {
  timeChunk: any;
  title?: string;
  selectedTimeSlots?: any;
  timeSlotHandler?: any;
  trackNo?: any;
  timeSlotsGridGap?: any;
}

const TimeSlotGrid: React.FC<TimeSlotsListProps> = ({
  trackNo,
  timeChunk,
  selectedTimeSlots,
  timeSlotHandler,
  title,
  timeSlotsGridGap,
}) => {
  const [timeRowActive, setTimeRowActive] = useState(0);

  const handleLeftArrow = () => {
    setTimeRowActive(timeRowActive === 0 ? 0 : timeRowActive - 1);
  };

  const handleRightArrow = () => {
    setTimeRowActive(timeRowActive + 1 === timeChunk.length ? timeChunk.length - 1 : timeRowActive + 1);
  };

  return (
    <div className={styles.timeSlotGridWrapper}>
      <div className={styles.timeSlotGridTitle}>{title}</div>
      <HorizontalScroll
        itemActive={timeRowActive}
        handleLeftArrow={handleLeftArrow}
        handleRightArrow={handleRightArrow}
        itemsLength={timeChunk.length}
        gap={timeSlotsGridGap}
      >
        {timeChunk.map((time, timeRowIndex) => {
          return (
            <TimeSlotGridRow
              selectedTimeSlots={selectedTimeSlots}
              timeSlotHandler={timeSlotHandler}
              items={time}
              key={timeRowIndex}
              trackNo={trackNo}
            />
          );
        })}
      </HorizontalScroll>
    </div>
  );
};

export default TimeSlotGrid;
