import React from 'react';
import cn from 'classnames';
import PhoneKeypadButton from './PhoneKeypadButton';
import deleteIcon from '../../assets/icons/keyboard/delete.svg';
import styles from './styles.module.scss';

interface PhoneKeypadProps {
  valueOfField: string | number;
  onChange: any;
}

const PhoneKeypad: React.SFC<PhoneKeypadProps> = ({ valueOfField, onChange }: PhoneKeypadProps) => {
  const eventHandler = (valueOfKey?: string | number) => {
    if (typeof valueOfKey === 'number') {
      onChange(`${valueOfField}${valueOfKey}`);
    }
    if (typeof valueOfKey === 'string') {
      if (valueOfKey === 'clear') {
        onChange('');
      }
      if (valueOfKey === 'del') {
        onChange(`${valueOfField.toString().slice(0, -1)}`);
      }
    }
  };

  return (
    <div className={styles.container}>
        <PhoneKeypadButton valueOfKey={1} onClick={eventHandler}>
          1
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={2} onClick={eventHandler}>
          2
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={3} onClick={eventHandler}>
          3
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={4} onClick={eventHandler}>
          4
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={5} onClick={eventHandler}>
          5
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={6} onClick={eventHandler}>
          6
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={7} onClick={eventHandler}>
          7
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={8} onClick={eventHandler}>
          8
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={9} onClick={eventHandler}>
          9
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={'clear'} onClick={eventHandler}>
          Clear
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={0} onClick={eventHandler}>
          0
        </PhoneKeypadButton>
        <PhoneKeypadButton valueOfKey={'del'} onClick={eventHandler}>
          <img src={deleteIcon} alt="" />
        </PhoneKeypadButton>
    </div>
  );
};

export default PhoneKeypad;
