import styles from './styles.module.scss';
import cn from 'classnames';
import Arrow from '../../assets/icons/arrow.svg';
import React, { useState } from 'react';

const HorizontalScroll = ({ children, handleRightArrow, handleLeftArrow, itemsLength, itemActive, gap }) => {
  const [dragOffset, setDragOffset] = useState(0);
  const [dragged, setDragged] = useState(false);
  const [xAxis, setXAxis] = useState(null);

  const unify = e => {
    return e.changedTouches ? e.changedTouches[0] : e;
  };

  const pressing = e => {
    setXAxis(unify(e).clientX);
    setDragged(true);
  };

  const dragging = e => {
    if (dragged) {
      setDragOffset(unify(e).clientX - xAxis.toFixed(2));
    }
  };

  const move = () => {
    if (dragged) {
      if (dragOffset < -80) {
        handleRightArrow();
        setDragOffset(0);
      }
      if (dragOffset > 80) {
        handleLeftArrow();
        setDragOffset(0);
      }
      setXAxis(null);
      setDragged(false);
    }
  };

  return (
    <div className={styles.HorizontalScroll}>
      {itemActive !== 0 && (
        <div className={cn(styles.HorizontalScrollArrow, styles.HorizontalScrollArrowLeft)} onClick={handleLeftArrow}>
          <img src={Arrow} alt="" />
        </div>
      )}
      <div
        className={styles.HorizontalScrollContainer}
        style={{
          transform: `translateX(calc(-${itemActive === 0 ? 0 : 100 * itemActive}% - ${gap *
            itemActive}px)) translateX(${itemActive === 0 && dragOffset > 0 ? 0 : dragOffset * 1.2}px)`,
        }}
        onMouseDown={pressing}
        onTouchStart={pressing}
        onMouseMove={dragging}
        onTouchMove={dragging}
        onMouseUp={move}
        onTouchEnd={move}
      >
        {children}
      </div>
      {itemActive + 1 !== itemsLength && (
        <div
          className={cn([styles.HorizontalScrollArrow, styles.HorizontalScrollArrowRight])}
          onClick={handleRightArrow}
        >
          <img src={Arrow} alt="" />
        </div>
      )}
    </div>
  );
};

export default HorizontalScroll;
