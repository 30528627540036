import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  MAKE_PAYMENT_CARD_CONNECT_TYPES,
  makePaymentCardConnectActionSuccess,
  makePaymentCardConnectActionFailure,
} from '../actions';
import { makeCardConnectPayment } from '../../../api';
import { history } from '../../../App';
import { ROUTES } from '../../../constants';
import { isEmpty } from 'lodash';

export function* makePaymentCardConnectWorker({ payload }: any) {
  try {
    const {
      cartStore,
      payByLink: { kdsRequestItems },
    } = yield select(store => store);
    // if (kdsRequestItems && !kdsRequestItems['terminalName']) {
    //   kdsRequestItems['terminalName'] = `SSK${terminalSettings.terminalNumber}`;
    // }
    const { data: cardConnectPaymentResponse } = yield call<any>(makeCardConnectPayment, {
      token: payload.token,
      paymentLinkId: payload.paymentLinkId,
      cardExpirationDate: payload.cardExpirationDate,
      checkId: cartStore.checkId,
      cvv: payload.cvv,
      postalCode: payload.postalCode,
      isText2Pay: false,
      ...(!isEmpty(kdsRequestItems) && { kdsRequestItems }),
    });
    if (cardConnectPaymentResponse.success) {
      yield put<any>(makePaymentCardConnectActionSuccess());
      history.push(ROUTES.PAYMENT_SUCCESS);
    } else {
      yield put<any>(makePaymentCardConnectActionFailure(cardConnectPaymentResponse));
    }
  } catch (e) {}
}

export default function* startPaymentProcessSaga() {
  yield all([
    yield takeLatest(MAKE_PAYMENT_CARD_CONNECT_TYPES.MAKE_PAYMENT_CARD_CONNECT, makePaymentCardConnectWorker),
  ]);
}
