import { GET_REGISTRATION_SETTINGS_TYPES } from '../actions';

export const initialState = {
  adultAge: null,
  adultRegistrationFields: [],
  allowDuplicateEmail: false,
  allowMinorToSign: false,
  captureProfilePicture: false,
  defaultCountry: null,
  emailCheckboxText: null,
  enableAddMinors: false,
  minimumAgeToRegisterOnline: null,
  minorRegistrationFields: [],
  privacyPolicyUrl: null,
  showPrivacyPolicy: false,
  waiverStep: {
    adultWaiverText: null,
    displayName: null,
    minorWaiverText: null,
    required: false,
    settingName: null,
    waiverInstructions: null,
  },
  newCustomerRegistrationSteps: [],
  newAdultRegistrationSteps: [],
  newMinorRegistrationSteps: [],
  enableRelationshipScreen: false,
  loading: false,
};

export type InitialStateType = typeof initialState;

export default function globalSettingsReducer(state = initialState, { type, payload = {} }: any): InitialStateType {
  switch (type) {
    case GET_REGISTRATION_SETTINGS_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REGISTRATION_SETTINGS_TYPES.SUCCESS:
      return {
        ...state,
        adultAge: payload.adultAge,
        adultRegistrationFields: payload.adultRegistrationFields,
        allowDuplicateEmail: payload.allowDuplicateEmail,
        allowMinorToSign: payload.allowMinorToSign,
        captureProfilePicture: payload.captureProfilePicture,
        defaultCountry: payload.defaultCountry,
        emailCheckboxText: payload.emailCheckboxText,
        enableAddMinors: payload.enableAddMinors,
        minimumAgeToRegisterOnline: payload.minimumAgeToRegisterOnline,
        minorRegistrationFields: payload.minorRegistrationFields,
        privacyPolicyUrl: payload.privacyPolicyUrl,
        showPrivacyPolicy: payload.showPrivacyPolicy,
        newCustomerRegistrationSteps: payload.newCustomerRegistrationSteps,
        newAdultRegistrationSteps: payload.newAdultRegistrationSteps,
        newMinorRegistrationSteps: payload.newMinorRegistrationSteps,
        waiverStep: {
          adultWaiverText: payload.waiverStep.adultWaiverText,
          displayName: payload.waiverStep.displayName,
          minorWaiverText: payload.waiverStep.minorWaiverText,
          required: payload.waiverStep.required,
          settingName: payload.waiverStep.settingName,
          waiverInstructions: payload.waiverStep.waiverInstructions,
        },
        enableRelationshipScreen: payload.enableRelationshipScreen,
        loading: false,
      };
    case GET_REGISTRATION_SETTINGS_TYPES.FAILURE:
      return {
        ...initialState,
        loading: false,
      };
    default:
      return state;
  }
}
