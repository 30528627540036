import React from 'react';
import _ from 'lodash';
import TextFieldWithLabel from '../TextFieldWithLabel';
import WheelPicker from '../WheelPicker';
import styles from './styles.module.scss';

interface Props {
  value: any;
  source: any[];
  displayField: string;
  valueField: string;
  fieldName: string;
  label?: string;
  errorMessage?: string;
  placeholder?: string;
  inputType?: string;
  handleChange: (field: any, data: any) => void;
  required?: boolean;
}

const WizardFormDatePicker = ({
  value,
  source,
  fieldName,
  displayField,
  valueField,
  label,
  placeholder,
  inputType,
  handleChange,
  errorMessage,
  required,
}: Props) => {
  const currentValue = _.find(source, obj => obj[valueField] === value);
  const currentTitle = currentValue ? currentValue[displayField] : '';
  return (
    <div className={styles.wizardFormField}>
      <div className={styles.mainContent}>
        <TextFieldWithLabel
          type={inputType}
          value={currentTitle}
          placeholder={placeholder}
          onFocus={() => {}}
          label={label}
          errorMessage={errorMessage}
          required={required}
        />
      </div>
      <div className={styles.select}>
        <div className={styles.selectInner}>
          <WheelPicker
            isShowing={true}
            hide={false}
            debug={false}
            debugListeners={false}
            store={source}
            displayField={displayField}
            valueField={valueField}
            handleSelect={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

WizardFormDatePicker.defaultProps = {
  label: '',
  placeholder: '',
  inputType: 'text',
  errorMessage: '',
  required: false,
};

export default WizardFormDatePicker;
