export const REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES = {
  REQUEST: 'customerGroup/REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES_REQUEST',
  SUCCESS: 'customerGroup/REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES_SUCCESS',
  FAILURE: 'customerGroup/REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES_FAILURE',
};

export const removeMemberFromCustomerGroupAction = (payload: any) => ({
  type: REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES.REQUEST,
  payload,
});

export const removeMemberFromCustomerGroupActionSuccess = (payload: any) => ({
  type: REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES.SUCCESS,
  payload,
});

export const removeMemberFromCustomerGroupActionFailure = () => ({
  type: REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES.FAILURE,
});
