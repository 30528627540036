export const ADD_ITEM_TO_CART_TYPES = {
  REQUEST: 'cart/ADD_ITEM_TO_CART_REQUEST',
  SUCCESS: 'cart/ADD_ITEM_TO_CART_SUCCESS',
  FAILURE: 'cart/ADD_ITEM_TO_CART_FAILURE',
};

export const addItemToCartAction = (payload: any) => ({
  payload,
  type: ADD_ITEM_TO_CART_TYPES.REQUEST,
});

export const addItemToCartActionSuccess = (payload: any) => ({
  payload,
  type: ADD_ITEM_TO_CART_TYPES.SUCCESS,
});

export const addItemToCartActionFailure = (payload?: any) => ({
  payload,
  type: ADD_ITEM_TO_CART_TYPES.FAILURE,
});
