import { MAKE_PAYMENT_CARD_CONNECT_TYPES, RESET_DATA_PAYMENT_CARD_CONNECT } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  errorMessage: '',
  successfulPayment: null,
  loading: false,
  attempts: 1,
};

export type InitialStateType = typeof initialState;

export default function paymentCardConnectReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case MAKE_PAYMENT_CARD_CONNECT_TYPES.MAKE_PAYMENT_CARD_CONNECT:
      return {
        ...state,
        loading: true,
      };
    case MAKE_PAYMENT_CARD_CONNECT_TYPES.MAKE_PAYMENT_CARD_CONNECT_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        successfulPayment: true,
        loading: false,
      };
    case MAKE_PAYMENT_CARD_CONNECT_TYPES.MAKE_PAYMENT_CARD_CONNECT_FAILURE:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        successfulPayment: false,
        loading: false,
      };
    case RESET_DATA_PAYMENT_CARD_CONNECT:
      return {
        ...initialState,
        attempts: state.attempts + 1,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
