import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface TextFieldProps {
  type: string;
  readOnly?: boolean;
  value: string;
  isActive?: boolean;
  placeholder?: string;
  theme: string;
  invalid?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  onChange?: (e: any) => void;
  onFocus: () => void;
  customClass?: string;
  maxLength?: number;
  inputMode?: boolean;
}

const TextField = ({
  type,
  readOnly,
  value = '',
  isActive,
  placeholder,
  theme,
  onChange,
  onFocus,
  invalid,
  disabled,
  errorMessage,
  customClass,
  maxLength,
  inputMode,
}: TextFieldProps) => {
  /*
    We can set
    const nativeKeyboardState = useSelector (nativeKeyboardStateSelector);
    here, in components, and we use that for readOnly to not call us the value every time.
    What do you think?
   */
  return (
    <div className={cn(styles.TextInputWrapper, customClass)}>
      {invalid && (
        <div className={styles.TextInputErrorMessageWrapper}>
          <div className={styles.TextInputErrorMessage}>! {errorMessage}</div>
        </div>
      )}
      <input
        type={type}
        readOnly={readOnly}
        inputMode={inputMode ? 'text' : 'none'}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        className={cn(styles.TextInput, styles[theme], { [styles.TextInputActive]: isActive })}
        onChange={onChange}
        onFocus={onFocus}
        autoComplete="off"
        disabled={disabled}
      />
    </div>
  );
};

TextField.defaultProps = {
  readOnly: false,
  placeholder: '',
  theme: 'themeDefault',
  isActive: false,
  invalid: false,
  errorMessage: '',
  onChange: () => {},
};

export default TextField;
