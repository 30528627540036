import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { startCustomerSessionAction } from '../../store/Terminal/actions';
import { endCustomerSessionAction } from '../../store/General/actions';
import { getRegistrationSettingsAction } from '../../store/Settings/actions';
import { terminalModeStateSelector } from '../../store/Settings/selectors';
import { getSourcesAction } from '../../store/Source/actions';

import ContentContainer from '../../components/ContentContainer';
import Logo from '../../components/Logo';
import NavigationButton from '../../components/NavigationButton';

import { history } from '../../App';
import { ROUTES, TERMINAL_MODES } from '../../constants';

import { isDefaultMode, isFoodMode, isFoodAndActivitiesMode, isRegistrationMode } from '../../utils/detectTerminalMode';

import modeFoodIcon from '../../assets/icons/mode_food.svg';
import modeFoodAndActivitiesIcon from '../../assets/icons/mode_tickets_and_food.svg';

import styles from './styles.module.scss';

const WelcomeScreen = () => {
  const dispatch = useDispatch();
  let timer;
  const terminalMode = useSelector(terminalModeStateSelector);
  const { registrationSettings, source } = useSelector(state => state);
  const goToSettings = () => {
    timer = setTimeout(() => history.push(ROUTES.LOGIN_SETTINGS), 10000);
  };

  const resetTimer = () => {
    clearTimeout(timer);
  };

  React.useEffect(() => {
    localStorage.setItem('login_another_way_birthdate', '');
    localStorage.setItem('login_another_way_fName', '');
    localStorage.setItem('login_another_way_lName', '');

    dispatch(endCustomerSessionAction());
    dispatch(getRegistrationSettingsAction());
    dispatch(getSourcesAction());
  }, []);

  const startCustomerSession = mode => {
    dispatch(startCustomerSessionAction(mode));
  };
  const onTabToBegin = () => {
    if (isDefaultMode(terminalMode) || isFoodMode(terminalMode) || isRegistrationMode(terminalMode)) {
      startCustomerSession(terminalMode);
    }
  };

  return (
    <ContentContainer loading={registrationSettings.loading || source.loading} theme="red">
      <div className={styles.container} onMouseUp={onTabToBegin}>
        <div>
          <div className={styles.logoWrapper}>
            <button
              className={styles.logoButton}
              onMouseDown={goToSettings}
              onMouseUp={resetTimer}
              onTouchStart={goToSettings}
              onTouchEnd={resetTimer}
            >
              <Logo width="700" />
            </button>
          </div>
          <h2 className={cn(styles.title, { [styles.titleLight]: isFoodAndActivitiesMode(terminalMode) })}>
            Self-Serve Kiosk
          </h2>
          {isFoodAndActivitiesMode(terminalMode) && <h3 className={styles.subTitle}>What are you here for?</h3>}
          {!isFoodAndActivitiesMode(terminalMode) && (
            <div className={styles.titleWrapper}>
              <h2>Tap to begin</h2>
            </div>
          )}
          {isFoodAndActivitiesMode(terminalMode) && (
            <div className={styles.navigation}>
              <NavigationButton
                icon={modeFoodIcon}
                label={'I want\nfood'}
                customClass={styles.navigationButton}
                onClick={() => startCustomerSession(TERMINAL_MODES.food.value)}
              />
              <NavigationButton
                icon={modeFoodAndActivitiesIcon}
                label={'I want\nfun'}
                customClass={styles.navigationButton}
                onClick={() => startCustomerSession(TERMINAL_MODES.foodAndActivities.value)}
              />
            </div>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default WelcomeScreen;
