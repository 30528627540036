import request from '../services/Api';
import { AxiosResponse } from 'axios';

export const fetchCategories = (): Promise<AxiosResponse> =>
  request.get('CoreAPI/Categories/GetSelfServeKioskCategories');

export const fetchActivitiesCategories = (): Promise<AxiosResponse> =>
  request.get('CoreAPI/Categories/GetSelfServeKioskCategories');

export const fetchFoodCategories = (): Promise<AxiosResponse> =>
  request.get('CoreAPI/Categories/GetFoodSelfServeKioskCategories');
