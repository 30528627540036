import { takeLatest, put, all, call, delay, select } from 'redux-saga/effects';
import {
  CREATE_PAYMENT_TYPES,
  RETRY_PAYMENT_TYPE,
  FALLING_PAYMENT_TYPE,
  createPaymentActionSuccess,
  createPaymentActionFailure,
} from '../actions';
import { endCustomerSessionAction } from '../../General/actions';
import { PRODUCT_TYPE, ROUTES } from '../../../constants';
import { history } from '../../../App';
import { createPaymentsProcess } from '../../../api';
import { isEmpty } from 'lodash';
import * as Api from '../../../api';

export function* createPaymentProcessWorker() {
  try {
    const {
      terminalSettings: { terminalNumber },
      cartStore: { checkTotal, checkId, custId, checkDetails },
      customerStore,
      payByLink: { kdsRequestItems },
    } = yield select(store => store);

    const customerData = {};
    const foodItems = checkDetails.filter(item => item.productType === PRODUCT_TYPE.FOOD);
    if (foodItems.length) {
      const KDSSettings = yield call(Api.fetchControlPanelSettings, 'KDSEnableIntegration', 'MainEngine');
      if (KDSSettings.data[0].settingValue === 'true') {
        customerData['terminalName'] = `SSK${terminalNumber}`;
        if (customerStore.custId) {
          customerData['foodPhoneNumber'] = customerStore.phoneNumber || customerStore.cell;
          customerData['foodOrderName'] = customerStore.fName;
        } else {
          customerData['foodPhoneNumber'] = kdsRequestItems.foodPhoneNumber;
          customerData['foodOrderName'] = kdsRequestItems.foodOrderName;
        }
      }
    }

    const payloadData = {
      checkId,
      custId,
      amount: checkTotal,
      payType: 2,
      terminalName: `SSK${terminalNumber}`,
      ...(!isEmpty(customerData) && { kdsRequestItems: customerData }),
    };
    const { data } = yield call<any>(createPaymentsProcess, payloadData);
    yield delay(5000);
    yield put<any>(createPaymentActionSuccess(data));
    history.push(ROUTES.PAYMENT_CLOVER_PROCESSING);
  } catch (err) {
    yield put<any>(createPaymentActionFailure());
  }
}

export function* retryPaymentWorker() {
  try {
    yield delay(10000);
    history.push(ROUTES.PRODUCTS);
  } catch (err) {
    console.log(err);
  }
}

export function* fallingPaymentWorker() {
  try {
    const { payment } = yield select(store => store);
    yield delay(10000);
    if (payment.badStatusAttempts > 3) {
      yield put<any>(endCustomerSessionAction());
    } else {
      history.push(ROUTES.PRODUCTS);
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* createPaymentProcessSaga() {
  yield all([
    yield takeLatest(CREATE_PAYMENT_TYPES.REQUEST, createPaymentProcessWorker),
    yield takeLatest(RETRY_PAYMENT_TYPE, retryPaymentWorker),
    yield takeLatest(FALLING_PAYMENT_TYPE, fallingPaymentWorker),
  ]);
}
