import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { ROUTES } from '../../constants';

const ProtectedRoute: React.StatelessComponent<RouteProps> = ({ ...rest }) => {
  const hasSettings =
    !!localStorage.getItem('api_user_name') ||
    !!localStorage.getItem('api_password') ||
    !!localStorage.getItem('api_server');

  return hasSettings ? <Route {...rest} /> : <Redirect to={ROUTES.SETTINGS_API} />;
};

export default ProtectedRoute;
