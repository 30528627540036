export const GET_PAYMENT_TYPES = {
  GET_PAYMENT: 'payment/GET_PAYMENT_REQUEST',
  GET_PAYMENT_SUCCESS: 'payment/GET_PAYMENT_SUCCESS',
  GET_PAYMENT_FAILURE: 'payment/GET_PAYMENT_FAILURE',
};

export const getPaymentAction = (payload: any) => ({
  type: GET_PAYMENT_TYPES.GET_PAYMENT,
  payload,
});

export const getPaymentActionSuccess = (payload: any) => ({
  type: GET_PAYMENT_TYPES.GET_PAYMENT_SUCCESS,
  payload,
});

export const getPaymentActionFailure = () => ({
  type: GET_PAYMENT_TYPES.GET_PAYMENT_FAILURE,
});
