export const GET_PRODUCTS_BY_CATEGORY_TYPES = {
  REQUEST: 'productsByCategory/GET_PRODUCTS_BY_CATEGORY_REQUEST',
  SUCCESS: 'productsByCategory/GET_PRODUCTS_BY_CATEGORY_SUCCESS',
  FAILURE: 'productsByCategory/GET_PRODUCTS_BY_CATEGORY_FAILURE',
};

export const getProductsByCategoryAction = (payload:any) => ({
  type: GET_PRODUCTS_BY_CATEGORY_TYPES.REQUEST,
  payload,
});

export const getProductsByCategoryActionSuccess = (payload: any) => ({
  type: GET_PRODUCTS_BY_CATEGORY_TYPES.SUCCESS,
  payload,
});

export const getProductsByCategoryActionFailure = () => ({
  type: GET_PRODUCTS_BY_CATEGORY_TYPES.FAILURE,
});
