export const MAKE_TEXT_2_PAY_TYPES = {
  MAKE_TEXT_2_PAY: 'payment/MAKE_TEXT_2_PAY_REQUEST',
  MAKE_TEXT_2_PAY_SUCCESS: 'payment/MAKE_TEXT_2_PAY_SUCCESS',
  MAKE_TEXT_2_PAY_FAILURE: 'payment/MAKE_TEXT_2_PAY_FAILURE',
};

export const makeText2PayAction = () => ({
  type: MAKE_TEXT_2_PAY_TYPES.MAKE_TEXT_2_PAY,
});

export const makeText2PayActionSuccess = () => ({
  type: MAKE_TEXT_2_PAY_TYPES.MAKE_TEXT_2_PAY_SUCCESS,
});

export const makeText2PayActionFailure = () => ({
  type: MAKE_TEXT_2_PAY_TYPES.MAKE_TEXT_2_PAY_FAILURE,
});
