import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchCustomerGroup } from '../../../api';
import { GET_CUSTOMER_GROUP_TYPES, getCustomerGroupActionSuccess, getCustomerGroupActionFailure } from '../actions';

export function* getCustomerGroupWorker({ payload }: any) {
  const { custId } = payload;
  try {
    const { data } = yield call<any>(fetchCustomerGroup, custId);
    yield put<any>(getCustomerGroupActionSuccess(data));
  } catch (err) {
    yield put<any>(getCustomerGroupActionFailure());
  }
}

export function* getCustomerGroupWatcher() {
  yield takeLatest(GET_CUSTOMER_GROUP_TYPES.REQUEST, getCustomerGroupWorker);
}

export default function* getCustomerGroupSaga() {
  yield all([getCustomerGroupWatcher()]);
}
