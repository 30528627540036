import { takeLatest, put, all, call } from 'redux-saga/effects';
import { addToCustomerGroup } from '../../../api';
import {
  ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES,
  addMemberToCustomerGroupActionSuccess,
  addMemberToCustomerGroupActionFailure,
} from '../actions';

export function* addMemberToCustomerGroupWorker() {
  try {
    yield call<any>(addToCustomerGroup, {});
    yield put<any>(addMemberToCustomerGroupActionSuccess({}));
  } catch (err) {
    yield put<any>(addMemberToCustomerGroupActionFailure());
  }
}

export function* addMemberToCustomerGroupWatcher() {
  yield takeLatest(ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES.REQUEST, addMemberToCustomerGroupWorker);
}

export default function* addMemberToCustomerGroupSaga() {
  yield all([addMemberToCustomerGroupWatcher()]);
}
