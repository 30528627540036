export const CREATE_PAYMENT_TYPES = {
  REQUEST: 'payment/CREATE_PAYMENT_REQUEST',
  SUCCESS: 'payment/CREATE_PAYMENT_SUCCESS',
  FAILURE: 'payment/CREATE_PAYMENT_FAILURE',
};

export const RETRY_PAYMENT_TYPE = 'payment/RETRY_PAYMENT_TYPE';
export const FALLING_PAYMENT_TYPE = 'payment/FALLING_PAYMENT_TYPE';

export const createPaymentAction = () => ({
  type: CREATE_PAYMENT_TYPES.REQUEST,
});

export const createPaymentActionSuccess = (payload: any) => ({
  type: CREATE_PAYMENT_TYPES.SUCCESS,
  payload,
});

export const createPaymentActionFailure = () => ({
  type: CREATE_PAYMENT_TYPES.FAILURE,
});

export const retryPaymentAction = () => ({
  type: RETRY_PAYMENT_TYPE,
});

export const fallingPaymentAction = () => ({
  type: FALLING_PAYMENT_TYPE,
});
