import React from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import styles from './styles.module.scss';

const SignatureCanvas = ({ sigPad }: any) => {
  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };

  return (
    <div className={styles.waiverAgreementSignatureCanvas}>
      <div className={styles.waiverAgreementSignatureCanvasInner}>
        <ReactSignatureCanvas canvasProps={{ width: 500, height: 200 }} ref={sigPad} />
      </div>
      <button onClick={clearSignature}>Clear</button>
    </div>
  );
};

export default SignatureCanvas;
