import request from '../services/Api';
import { AxiosResponse } from 'axios';

export const createPaymentLink = (data: any): Promise<AxiosResponse> =>
  request({
    method: 'post',
    url: 'CoreAPI/payments/link',
    data,
  });

export const deletePaymentLink = (data: any): Promise<AxiosResponse> =>
  request({
    method: 'delete',
    url: 'CoreAPI/payments/link',
    data,
  });

export const makeText2Pay = (data: any): Promise<AxiosResponse> =>
  request({
    method: 'post',
    url: 'CoreAPI/PaymentLinks/Text2Pay',
    data,
  });

export const fetchPaymentsProcess = (paymentId: string): Promise<AxiosResponse> =>
  request.get(`CoreAPI/Payments/${paymentId}`);

export const makeCardConnectPayment = (data: any): Promise<AxiosResponse> =>
  request.post('CoreAPI/payments/pay/cc', data);

export const createPaymentsProcess = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/Payments', data);

export const fetchPaymentStatus = (paymentStatusId: number): Promise<AxiosResponse> =>
  request.get(`CoreAPI/PaymentStatus/${paymentStatusId}`);
