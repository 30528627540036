import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface TextFieldWithLabelProps {
  type: string;
  value: string | number;
  readOnly?: boolean;
  onFocus?: () => void;
  onChange?: any;
  placeholder?: string;
  label?: string;
  invalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  inputMode?: boolean;
}

const TextFieldWithLabel: React.FC<TextFieldWithLabelProps> = ({
  type,
  value,
  readOnly,
  onFocus,
  placeholder,
  onChange,
  label,
  invalid,
  errorMessage,
  required,
  inputMode,
}: TextFieldWithLabelProps) => {
  return (
    <div className={cn(styles.tf, { [styles.tdInvalid]: !!errorMessage })}>
      <input
        type={type}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        className={styles.tfInput}
        inputMode={inputMode ? 'text' : 'none'}
        autoComplete="off"
      />
      <div className={styles.tfLine} />
      <h3 className={styles.tfLabel}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </h3>
      {!!errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

TextFieldWithLabel.defaultProps = {
  onFocus: () => {},
  onChange: () => {},
  readOnly: false,
  placeholder: '',
  label: '',
  invalid: false,
  errorMessage: '',
};

export default TextFieldWithLabel;
