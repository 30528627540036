import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface CartSummaryProps {
  subtotal?: number;
  tax?: number;
  total?: number;
}

const CartSummary: React.FC<CartSummaryProps> = ({ subtotal, tax, total }: CartSummaryProps) => {
  return (
    <div className={styles.cartSummary}>
      <div className={styles.cartSummaryRow}>
        <div className={styles.cartSummaryTitle}>Subtotal</div>
        <div className={styles.cartSummaryPrice}>${subtotal.toFixed(2)}</div>
      </div>
      <div className={styles.cartSummaryRow}>
        <div className={styles.cartSummaryTitle}>Tax</div>
        <div className={styles.cartSummaryPrice}>${tax.toFixed(2)}</div>
      </div>
      <div className={styles.cartSummaryRow}>
        <div className={cn(styles.cartSummaryTitle, styles.cartSummaryTitleTotal)}>Total</div>
        <div className={cn(styles.cartSummaryPrice, styles.cartSummaryPriceTotal)}>${total.toFixed(2)}</div>
      </div>
    </div>
  );
};

CartSummary.defaultProps = {
  subtotal: 0,
  tax: 0,
  total: 0,
};

export default CartSummary;
