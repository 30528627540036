import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { deleteFromCart } from '../../../api';
import {
  REMOVE_ITEM_FROM_CART_TYPES,
  removeItemFromCartActionSuccess,
  removeItemFromCartActionFailure,
} from '../actions';
import { showRemoveMembershipAlertAction } from '../actions/removeCartActions';

export function* removeItemFromCartWorker({ payload }: any) {
  try {
    const { checkDetailId, custId, isMembership, isCheck } = payload;
    const { cartStore, customerStore } = yield select();
    const { checkId } = cartStore;
    const payloadData = {
      checkId,
      checkDetailIds: [checkDetailId],
    };

    let data = null;

    const getMemberProducts = () => {
      let memberCartProducts;
      if (custId === customerStore.custId) {
        memberCartProducts = cartStore.checkDetails.filter(
          item =>
            (item.g_CustId || item.p_CustId) === custId ||
            (item.m_CustId === null && item.p_CustId === null && item.g_CustId === null),
        );
      } else {
        memberCartProducts = cartStore.checkDetails.filter(item => (item.g_CustId || item.p_CustId) === custId);
      }

      const productId = cartStore.checkDetails.filter(item => checkDetailId === item.checkDetailId)[0].productId;
      return memberCartProducts.filter((cartProduct: any) => cartProduct.m_ProductId === productId);
    };

    if (isMembership && isCheck) {
      const memberProducts = getMemberProducts();
      if (memberProducts.length > 0) {
        yield put<any>(
          showRemoveMembershipAlertAction({
            custId,
            membershipId: checkDetailId,
            showRemoveMembershipAlert: memberProducts.length > 0,
          }),
        );
      } else {
        const deleteFromCartData = yield call<any>(deleteFromCart, payloadData);
        data = deleteFromCartData.data;
      }
    }

    if (isMembership) {
      if (!isCheck) {
        const memberProducts = getMemberProducts();
        for (let i = 0; i < memberProducts.length; i++) {
          payloadData.checkDetailIds.push(memberProducts[i].checkDetailId);
        }
        const deleteFromCartData = yield call<any>(deleteFromCart, payloadData);
        data = deleteFromCartData.data;
      }
    } else {
      const removeItem = yield call<any>(deleteFromCart, payloadData);
      data = removeItem.data;
    }
    yield put<any>(removeItemFromCartActionSuccess(data));
  } catch (err) {
    console.log({ err });
    yield put<any>(removeItemFromCartActionFailure());
  }
}

export default function* removeFromCartSaga() {
  yield all([yield takeLatest(REMOVE_ITEM_FROM_CART_TYPES.REQUEST, removeItemFromCartWorker)]);
}
