export const GET_CUSTOMER_GROUP_TYPES = {
  REQUEST: 'customerGroup/GET_CUSTOMER_GROUP_REQUEST',
  SUCCESS: 'customerGroup/GET_CUSTOMER_GROUP_SUCCESS',
  FAILURE: 'customerGroup/GET_CUSTOMER_GROUP_FAILURE',
};

export const getCustomerGroupAction = (payload: any) => ({
  type: GET_CUSTOMER_GROUP_TYPES.REQUEST,
  payload,
});

export const getCustomerGroupActionSuccess = (payload: any) => ({
  type: GET_CUSTOMER_GROUP_TYPES.SUCCESS,
  payload,
});

export const getCustomerGroupActionFailure = () => ({
  type: GET_CUSTOMER_GROUP_TYPES.FAILURE,
});
