import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const CartItemSchedule = ({ currentCartItem }) => {
  const [scheduledTimes, setScheduledTimes] = useState({});
  useEffect(() => {
    const arr = {};
    currentCartItem.scheduledTimes.forEach((item: any) => {
      if (!arr[item.resourceName]) {
        arr[item.resourceName] = {
          scheduledTimes: [item],
          resourceName: item.resourceName,
        };
      } else {
        arr[item.resourceName].scheduledTimes.push(item);
      }
    });
    setScheduledTimes(arr);
  }, []);

  return (
    <>
      {Object.keys(scheduledTimes).length > 0 && (
        <div>
          {Object.keys(scheduledTimes).map((item: any, idx) => {
            return (
              <div key={idx} className={styles.cartItemSchedule}>
                {scheduledTimes[item].scheduledTimes.map(
                  (itemTime, index) =>
                    `${moment(itemTime.selectedScheduledDate).format('hh:mm A')}${
                      index < scheduledTimes[item].scheduledTimes.length - 1 ? ', ' : ''
                    }`,
                )}
                {` | ${scheduledTimes[item]?.resourceName}`}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CartItemSchedule;
