import { START_RESET_PIN_TYPES, VERIFY_RESET_CODE_TYPES } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  resetCodeId: null,
  errorMessage: '',
  loading: false,
};

export type InitialStateType = typeof initialState;

export default function resetPinReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case START_RESET_PIN_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case START_RESET_PIN_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case START_RESET_PIN_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_RESET_CODE_TYPES.REQUEST:
      return {
        ...state,
        resetCodeId: null,
        loading: true,
      };
    case VERIFY_RESET_CODE_TYPES.SUCCESS:
      return {
        ...state,
        resetCodeId: payload.resetCodeId,
        loading: false,
      };
    case VERIFY_RESET_CODE_TYPES.FAILURE:
      return {
        ...state,
        resetCodeId: null,
        loading: false,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
