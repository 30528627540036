import axios, { AxiosResponse } from 'axios';
import updateTokenInterceptor from './interceptors/updateTokenInterceptor';

const { REACT_APP_KIOSK_URL } = process.env;

const instance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const requestArray = [];

if (REACT_APP_KIOSK_URL) {
  instance.defaults.baseURL = REACT_APP_KIOSK_URL;
}

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (requestArray.length !== 0) {
      requestArray.forEach((x, i) => {
        if (response.config.url === x.url) {
          requestArray.splice(i, 1);
        }
      });
    }

    if (response.data.token) {
      instance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      instance.defaults.headers.common['Content-Type'] = 'application/json';
      instance.defaults.withCredentials = true;
    }

    if (response.data.clubspeedData !== undefined) {
      response.data = response.data.clubspeedData;
    }
    return response;
  },
  (error: any) => {
    const originalRequest = error.config;
    requestArray.push(originalRequest);
    if (error.response.status === 401) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        return updateTokenInterceptor(instance, originalRequest, requestArray);
      }
    } else {
      return error;
    }
    return Promise.reject(error);
  },
);

export default instance;
