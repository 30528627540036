import React from 'react';
import ReactSelect from 'react-select';
import styles from './styles.module.scss';

interface SelectProps {
  options: any;
  value: any;
  onChange: any;
  label?: string;
  isDisabled?: boolean;
}

const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    appearance: 'none',
    lineHeight: '1.75',
    // padding: '0 58px 0 8px',
    position: 'relative',
    zIndex: '0',
    // borderWidth: '2px',
    // borderStyle: 'solid',
    borderColor: 'transparent',
    boxSizing: 'border-box',
    borderRadius: '6px',
    width: '100%',
    // color: isFocused ? theme.colors.white : theme.colors.defaultTextColor,
    transition: 'all 0.3s ease-in-out',
    margin: '0',
    height: '35px',
    boxShadow: 'none',

    ':hover': {
      // borderWidth: '2px',
      // borderStyle: 'solid',
      // borderColor: theme.colors.defaultTextColor,
    },
  }),
  valueContainer: () => ({
    height: '35px',
  }),
  menu: styles => ({
    ...styles,
    zIndex: '10',
    backgroundColor: 'white',
  }),
  menuList: styles => ({
    ...styles,
    backgroundColor: 'white',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    color: '#DF151C',
    backgroundColor: 'transparent',
    ':active': {
      ...styles[':active'],
      color: '#DF151C',
    },
    ':hover': {
      ...styles[':hover'],
    },
  }),
  singleValue: styles => ({
    ...styles,
    color: 'white',
    fontSize: '18px',
    fontWeight: '500',
    textAlign: 'center',
    width: '100%',
  }),
  placeholder: styles => ({
    ...styles,
    color: 'white',
    fontSize: '18px',
    fontWeight: '500',
    textAlign: 'center',
    width: '100%',
  }),
};

const Select: React.FC<SelectProps> = ({ options, value, onChange, label = 'label', isDisabled = false }: SelectProps) => (
  <div className={styles.select}>
    <ReactSelect
      options={options}
      value={value}
      getOptionLabel={option => `${option[label]}`}
      styles={customStyles}
      onChange={onChange}
      isSearchable={false}
      isDisabled={isDisabled}
    />
  </div>
);

Select.defaultProps = {
  options: [],
};

export default Select;
