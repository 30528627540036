import { takeLatest, all, put } from 'redux-saga/effects';
import { UPDATE_KDS_DATA_TYPES, updateKdsDataActionSuccess, updateKdsDataActionFailure } from '../actions';

export function* updateKdsDataWorker(payload: any) {
  try {
    yield put<any>(updateKdsDataActionSuccess(payload));
  } catch (err) {
    yield put<any>(updateKdsDataActionFailure());
  }
}

export default function* updateKdsDataSaga() {
  yield all([yield takeLatest(UPDATE_KDS_DATA_TYPES.REQUEST, updateKdsDataWorker)]);
}
