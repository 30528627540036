import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { endCustomerSessionAction } from '../../store/General/actions';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import BigTicketIcon from '../../assets/icons/big-ticket.svg';
import styles from './styles.module.scss';

const PaymentSuccessScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(endCustomerSession, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const endCustomerSession = () => {
    dispatch(endCustomerSessionAction());
  };

  return (
    <ContentContainer theme="red">
      <HeaderPanel theme="dark" />
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <h2 className={styles.title}>Thank You!</h2>
          <h3 className={styles.subTitle}>
            We’ve texted you your receipt.
            <br />
            Remember to see a cashier if you have any
            <br />
            items to pick up.
          </h3>
          <div className={styles.ticketIcon}>
            <img src={BigTicketIcon} alt="" />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default PaymentSuccessScreen;
