import React, { useEffect } from 'react';
import cn from 'classnames';

const PickerContents = ({ store, valueField, displayField, scrollRef, scrollStyle, activeItem }) => {
  useEffect(() => {}, [scrollStyle]);
  return (
    <div className="picker default">
      <div className="wheel-picker-records-body">
        <div className="wheel-picker-records-inner">
          <div className="wheel-picker-viewport" id="modal-viewport">
            <div className="wheel-picker-wheel">
              <ul ref={scrollRef} className="wheel-picker-scroll" style={scrollStyle} id="modal-scroll">
                {store.length === 0 ? (
                  <div key="0">
                    <div className="wheel-picker-empty-records">There are no records to show</div>
                  </div>
                ) : (
                  store.map((s, key) => {
                    return (
                      <li key={key} className={cn({ ['test']: s[displayField] === activeItem[displayField] })}>
                        {s[displayField]}
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickerContents;
