import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  GET_PRODUCTS_BY_CATEGORY_TYPES,
  getProductsByCategoryActionSuccess,
  getProductsByCategoryActionFailure,
} from '../actions';
import * as Api from '../../../api';

export function* getProductsByCategoryWorker({ payload }: any) {
  try {
    const { categoryId, categoryType } = payload;
    const { productsByCategoryStore } = yield select();
    const productsByCategory = [...productsByCategoryStore.productsByCategory];
    if (categoryType === 'food') {
      const { data: foodProductsByCategoryResponse } = yield call<any>(Api.fetchFoodProductsByCategory, categoryId);
      productsByCategory.push(
        ...foodProductsByCategoryResponse.map((item: any) => ({ ...item, categoryId: Number(categoryId) })),
      );
    } else {
      let products = [];
      const { data: productsByCategoryResponse } = yield call<any>(Api.fetchProductsByCategory, categoryId);
      products = [...products, ...productsByCategoryResponse];
      if (!productsByCategoryResponse.length) {
        const { data: foodProductsByCategoryResponse } = yield call<any>(Api.fetchFoodProductsByCategory, categoryId);
        products = [...products, ...foodProductsByCategoryResponse];
      }

      productsByCategory.push(...products.map((item: any) => ({ ...item, categoryId: Number(categoryId) })));
    }
    const enabledProductsByCategory = productsByCategory.filter((category: any) => category?.enabled);
    yield put<any>(getProductsByCategoryActionSuccess(enabledProductsByCategory));
  } catch (e) {
    yield put<any>(getProductsByCategoryActionFailure());
  }
}

export default function* getProductsByCategorySaga() {
  yield all([yield takeLatest(GET_PRODUCTS_BY_CATEGORY_TYPES.REQUEST, getProductsByCategoryWorker)]);
}
