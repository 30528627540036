import { useState, useEffect } from 'react';

const useGridHeightHook = () => {
  const [greedHeight, setGreedHeight] = useState(0);
  useEffect(() => {
    function handleResize() {

      const windowHeight = window.innerHeight;
      const headerPanelHeight = document.getElementById('headerPanel').offsetHeight;
      const titlePanelHeight = document.getElementById('panelTitle').offsetHeight;
      // const productDetailHeight = document.getElementById('productDetail').offsetHeight;
      setGreedHeight(windowHeight - (headerPanelHeight + titlePanelHeight));
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  },        []);
  return greedHeight;
};

export default useGridHeightHook;
