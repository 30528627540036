import { useState, useEffect } from 'react';

const useTimeSlotsGridHook = () => {
  const [timeSlotsGridLength, setTimeSlotsGridLength] = useState(0);
  const [timeSlotsGridHeight, setTimeSlotsGridHeight] = useState(0);
  const [timeSlotsGridGap, setTimeSlotsGridGap] = useState(35);
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const headerPanelHeight = document.getElementById('headerPanel').offsetHeight;
      const productTitleHeight = document.getElementById('productTitle').offsetHeight;
      const productDetailHeight = document.getElementById('productDetail').offsetHeight;
      const footerPanelHeight = document.getElementById('footerPanel').offsetHeight;
      if (windowWidth > 1900) {
        setTimeSlotsGridLength(14);
      } else if (windowWidth > 1700) {
        setTimeSlotsGridLength(12);
      } else if (windowWidth > 1500) {
        setTimeSlotsGridLength(10);
      } else if (windowWidth > 1024) {
        setTimeSlotsGridLength(8);
      } else if (windowWidth > 875) {
        setTimeSlotsGridLength(8);
      } else {
        setTimeSlotsGridLength(6);
      }
      setTimeSlotsGridHeight(
        windowHeight - (headerPanelHeight + productTitleHeight + productDetailHeight + footerPanelHeight),
      );
      setTimeSlotsGridGap(30);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return { timeSlotsGridLength, timeSlotsGridHeight, timeSlotsGridGap };
};

export default useTimeSlotsGridHook;
