import { START_CUSTOMER_SESSION } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  customerSessionMode: null
};

export type InitialStateType = typeof initialState;

export default function sourceReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case START_CUSTOMER_SESSION:
      return {
        ...state,
        customerSessionMode: payload
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
