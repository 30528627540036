const TERMINAL_MODES = {
  default: {
    label: 'Default',
    value: 'default',
  },
  food: {
    label: 'Only Food',
    value: 'food',
  },
  foodAndActivities: {
    label: 'Food & Activities',
    value: 'foodAndActivities',
  },
  registration: {
    label: 'Registration Mode',
    value: 'registration',
  },
};



export default TERMINAL_MODES;
