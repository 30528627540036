import styles from './styles.module.scss';
import Button from '../Button';
import React from 'react';

const AddGuestButton = ({ handleClick }) => {
  return (
    <div className={styles.addGuestButtonWrapper}>
      <Button handleClick={handleClick} customClass={styles.addGuestButton}>
        <span className={styles.addGuestButtonIcon}>+</span>
        <span className={styles.addGuestButtonText}>Add more guests</span>
      </Button>
    </div>
  );
};

export default AddGuestButton;
