import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Api from '../../api';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import { saveCustomerToStoreAction, updateSignatureOfNewCustomerAction } from '../../store/Customer/actions';
import isAdult from '../../utils/isAdult';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import Checkbox from '../../components/Checkbox';
import Modal from '../../components/Modal';
import SignatureCanvas from '../../components/SignatureCanvas';
import WarningAlert from '../../components/WarningAlert';
import { createAdultWaiver, createMinorWaiver } from '../../utils/waivers';
import WaiverAgreementText from '../../components/WaiverAgreementText';

const WaiverAgreementMinorScreen = () => {
  const dispatch = useDispatch();
  const sigPad = React.useRef(null);

  const {
    newCustomerStore,
    newGuestStore,
    registrationSettings: { adultAge, waiverStep, allowDuplicateEmail },
  } = useSelector(state => state);
  const newAllMinorGuests = [...newGuestStore.guests].filter(
    (item: any) => !isAdult(item.commonData.birthDate, adultAge),
  );
  const newMinorGuests = [...newGuestStore.guests].filter(
    (item: any) => !isAdult(item.commonData.birthDate, adultAge) && !item.custId,
  );
  const newMinorGuestsWithId = [...newGuestStore.guests].filter(
    (item: any) => !isAdult(item.commonData.birthDate, adultAge) && item.custId,
  );
  const newAdultGuests = [...newGuestStore.guests].filter(
    (item: any) => isAdult(item.commonData.birthDate, adultAge) && !item.custId,
  );
  const newAdultGuestsWithId = [...newGuestStore.guests].filter(
    (item: any) => isAdult(item.commonData.birthDate, adultAge) && item.custId,
  );
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [agreeWithRulesError, setAgreeWithRulesError] = useState(false);
  const [minorsListModal, setMinorsListModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResetSignatureModalShow, setIsResetSignatureModalShow] = useState(false);
  const [createdCustomer, setCreatedCustomer] = useState(null);
  const [createdMinorCustomers, setCreatedMinorCustomers] = useState([]);

  const startRegistrationFlow = async (listOfNewGuests, signatureOfPrimaryCustomer) => {
    setLoading(true);
    let primaryCust;
    if (!createdCustomer) {
      const { data: primary } = await Api.createCustomer(newCustomerStore.commonData);
      primaryCust = primary;
      setCreatedCustomer(primary);
    } else {
      primaryCust = createdCustomer;
    }

    const adultWaiver = await createAdultWaiver({
      signature: signatureOfPrimaryCustomer,
      adultCustId: primaryCust.custId,
    });
    if (!adultWaiver) {
      setIsResetSignatureModalShow(true);
      return;
    }

    if (!primaryCust.doNotMail && primaryCust.emailAddress) {
      await Api.sendWelcomeEmail(primaryCust.custId);
    }

    if (!isResetSignatureModalShow) {
      for (const item of newMinorGuests) {
        const dataOfNewMinor = {
          ...item.commonData,
          address: primaryCust.address,
          address2: primaryCust.address2,
          city: primaryCust.city,
          state: primaryCust.state,
          zip: primaryCust.zip,
          country: primaryCust.country,
          sourceId: primaryCust.sourceId,
          phoneNumber: primaryCust.cell,
        };
        if (allowDuplicateEmail) {
          dataOfNewMinor['emailAddress'] = primaryCust.emailAddress;
        }

        const findCustomer = createdMinorCustomers.find(
          customer =>
            customer.fName === item.commonData.fName &&
            customer.lName === item.commonData.lName &&
            customer.birthDate === item.commonData.birthDate,
        );
        let cust;
        if (!findCustomer) {
          const {
            data: { custId: curentCustId },
          } = await Api.createCustomer(dataOfNewMinor);
          cust = { ...dataOfNewMinor, curentCustId };
          setCreatedMinorCustomers([...createdMinorCustomers, cust]);
        } else {
          cust = findCustomer;
        }
        const minorWaiver = await createMinorWaiver({
          guardianSignature: signatureOfPrimaryCustomer,
          minorCustId: cust.curentCustId,
          guardianCustId: primaryCust.custId,
        });
        if (!minorWaiver) {
          setIsResetSignatureModalShow(true);
          return;
        }
        await Api.addToCustomerGroup({
          custIds: [cust.curentCustId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: cust.curentCustId,
            },
          ],
          custId: primaryCust.custId,
        });
      }

      for (const item of newMinorGuestsWithId) {
        const minorWaiver = await createMinorWaiver({
          guardianSignature: signatureOfPrimaryCustomer,
          minorCustId: item.custId,
          guardianCustId: primaryCust.custId,
        });
        if (!minorWaiver) {
          setIsResetSignatureModalShow(true);
          return;
        }
        await Api.addToCustomerGroup({
          custIds: [item.custId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: item.custId,
            },
          ],
          custId: primaryCust.custId,
        });
      }

      for (const item of newAdultGuestsWithId) {
        await Api.addToCustomerGroup({
          custIds: [item.custId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              custId: item.custId,
              relationship: item.relationships,
            },
          ],
          custId: primaryCust.custId,
        });
      }

      for (const item of listOfNewGuests) {
        if (!item.edited) continue;
        await Api.updateCustomer(item.custId, item.commonData);
        if (item.customerImage) {
          await Api.uploadCustomerPicture(item.custId, item.customerImage);
        }
      }

      const { data: fetchPrimaryCust } = await Api.fetchCustomerById(primaryCust.custId);
      setLoading(false);
      dispatch(saveCustomerToStoreAction(fetchPrimaryCust));
      history.push(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isResetSignatureModalShow) {
      setLoading(false);
    }
  }, [isResetSignatureModalShow]);

  const onBackClick = () => {
    history.goBack();
  };

  const onContinueClick = async () => {
    try {
      const listOfNewGuests = newGuestStore.guests;
      const signatureOfPrimaryCustomer = sigPad.current.toDataURL().substr(22);
      if (!agreeWithRules) {
        setAgreeWithRulesError(true);
        return;
      }
      setAgreeWithRulesError(false);
      if (sigPad.current.isEmpty()) {
        return;
      }
      dispatch(updateSignatureOfNewCustomerAction(signatureOfPrimaryCustomer));
      if (newAdultGuests.length > 0) {
        history.push(`${ROUTES.WAIVER_AGREEMENT_ADULT}/${newAdultGuests[0].id}`);
        return;
      }
      await startRegistrationFlow(listOfNewGuests, signatureOfPrimaryCustomer);
    } catch (e) {}
  };

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };

  const resetSignatureModalHandleClick = () => {
    clearSignature();
    setIsResetSignatureModalShow(false);
  };

  return (
    <Fragment>
      <ContentContainer loading={loading}>
        <HeaderPanel startOverButton border paginationHistory="" paginationStep={''} />
        <div className={styles.content}>
          <div className={styles.waiverAgreementTextContainer}>
            <span className={styles.waiverAgreementTextTitle}>
              Waiver Agreement - {newCustomerStore.commonData.fName} {newCustomerStore.commonData.lName}
              {!!newAllMinorGuests.length && (
                <span>
                  {' '}
                  (signing for{' '}
                  <span className={styles.redText} onClick={() => setMinorsListModal(prevState => !prevState)}>
                    minors in group
                  </span>
                  )
                </span>
              )}
            </span>
            <div className={styles.waiverAgreementTextText}>
              <div>Adult Waiver Text:</div>
              <WaiverAgreementText isDoubleText={!!newMinorGuests.length} waiverText={waiverStep.adultWaiverText} />
              {!!newMinorGuests.length && (
                <Fragment>
                  <div>Minor Waiver Text:</div>
                  <WaiverAgreementText isDoubleText={true} waiverText={waiverStep.minorWaiverText} />
                </Fragment>
              )}
            </div>
          </div>

          <div className={styles.waiverAgreementTextContainer}>
            <div className={styles.iAgreeTextbox}>
              {!!newAllMinorGuests.length && (
                <Checkbox
                  state={!!agreeWithRules}
                  label={`I, ${newCustomerStore.commonData.fName} ${newCustomerStore.commonData.lName}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to both my own waiver, and the waiver for any minors present in my group.`}
                  handleClick={() => setAgreeWithRules(prevState => !prevState)}
                  error={agreeWithRulesError}
                />
              )}
              {!newAllMinorGuests.length && (
                <Checkbox
                  state={!!agreeWithRules}
                  label={`I, ${newCustomerStore.commonData.fName} ${newCustomerStore.commonData.lName}, agree to the terms and conditions of the above agreement.`}
                  handleClick={() => setAgreeWithRules(prevState => !prevState)}
                  error={agreeWithRulesError}
                />
              )}
            </div>
          </div>

          {agreeWithRules && (
            <div className={styles.waiverAgreementTextContainer}>
              <div className={styles.waiverAgreementSignatureCanvas}>
                <span className={styles.waiverAgreementSignatureTitle}>
                  <h2>
                    {newCustomerStore.commonData.fName} {newCustomerStore.commonData.lName}, please sign below.
                  </h2>
                </span>
              </div>
              <SignatureCanvas sigPad={sigPad} />
            </div>
          )}
        </div>
        <div className={styles.navigation}>
          <Button theme="white" size="base" handleClick={onBackClick} customClass={styles.whiteButton}>
            Back
          </Button>
          <div className={styles.navigationFilter} />
          <Button theme="red" size="base" handleClick={onContinueClick} customClass={styles.redButton}>
            Continue
          </Button>
        </div>
      </ContentContainer>
      <WarningAlert
        zIndex={9999}
        isOpen={isResetSignatureModalShow}
        title="Whoops!"
        description="An error occurred while creating your signature, please try again."
        handleClick={resetSignatureModalHandleClick}
      />
      <Modal isOpen={!!minorsListModal}>
        <div className={styles.minorsListModal}>
          <div className={styles.minorsListModalContainer}>
            <p>
              {newCustomerStore.commonData.fName} {newCustomerStore.commonData.lName} consent to this waiver
              <br />
              agreement will apply to the
              <br />
              following minor(s) in the group:
            </p>
            <ul>
              {newAllMinorGuests.map((item, index) => {
                return (
                  <li key={index}>
                    {item.commonData.fName} {item.commonData.lName}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.cloverPassButtonWrap}>
            <Button
              handleClick={() => setMinorsListModal(prevState => !prevState)}
              theme="red"
              customClass={styles.minorsListModalButton}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>

      <IdleMonitor />
    </Fragment>
  );
};

export default WaiverAgreementMinorScreen;
