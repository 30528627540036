import React from 'react';
import Schedule from './Schedule';
import ResourceGroup from './ResourceGroup';
import { useTimeSlotsGridHook } from '../../hooks';

const ProductSchedule = ({ resourceGroup = false, timeSlots, timeSlotHandler, selectedTimeSlots }) => {
  const { timeSlotsGridLength, timeSlotsGridHeight, timeSlotsGridGap } = useTimeSlotsGridHook();
  if (resourceGroup) {
    return (
      <ResourceGroup
        selectedTimeSlots={selectedTimeSlots}
        timeSlots={timeSlots}
        timeSlotHandler={timeSlotHandler}
        timeSlotsGridHeight={timeSlotsGridHeight}
        timeSlotsGridLength={timeSlotsGridLength}
        timeSlotsGridGap={timeSlotsGridGap}
      />
    );
  }
  return <Schedule timeSlots={timeSlots} timeSlotHandler={timeSlotHandler} selectedTimeSlots={selectedTimeSlots} />;
};

export default ProductSchedule;
