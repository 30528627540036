export const REMOVE_ITEM_FROM_CART_TYPES = {
  REQUEST: 'cart/REMOVE_ITEM_CART_REQUEST',
  SUCCESS: 'cart/REMOVE_ITEM_CART_SUCCESS',
  FAILURE: 'cart/REMOVE_ITEM_CART_FAILURE',
};

export const removeItemFromCartAction = (payload: any) => ({
  type: REMOVE_ITEM_FROM_CART_TYPES.REQUEST,
  payload,
});

export const removeItemFromCartActionSuccess = (payload: any) => ({
  type: REMOVE_ITEM_FROM_CART_TYPES.SUCCESS,
  payload,
});

export const removeItemFromCartActionFailure = () => ({
  type: REMOVE_ITEM_FROM_CART_TYPES.FAILURE,
});
