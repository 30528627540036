export const NEW_GUEST_TYPES = {
  ADD_TO_GUESTS_LIST: 'guest/ADD_TO_GUESTS_LIST',
  UPDATE_NEW_GUEST: 'guest/UPDATE_NEW_GUEST',
  ADD_SIGNATURE_OF_NEW_GUEST: 'guest/ADD_SIGNATURE_OF_NEWGUEST',
};

export const addToGuestsList = (payload: any) => ({
  type: NEW_GUEST_TYPES.ADD_TO_GUESTS_LIST,
  payload,
});

export const updateNewGuestAction = (payload: any) => ({
  type: NEW_GUEST_TYPES.UPDATE_NEW_GUEST,
  payload,
});

export const addSignatureOfNewGuestAction = (payload: any) => ({
  type: NEW_GUEST_TYPES.ADD_SIGNATURE_OF_NEW_GUEST,
  payload,
});
