import React from 'react';
import styles from './PhoneKeypadButton.module.scss';

interface PhoneKeypadButtonProps {
  valueOfKey: string | number;
  onClick: (valueOfKey: string | number) => void;
  children: React.ReactNode;
}

const PhoneKeypadButton = ({ valueOfKey, onClick, children }: PhoneKeypadButtonProps) => {
  return (
    <button className={styles.button} onClick={() => onClick(valueOfKey)}>
      {children}
    </button>
  );
};

export default PhoneKeypadButton;
