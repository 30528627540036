import { TERMINAL_DEFAULT_SETTINGS } from '../../../constants';
import { UPDATE_TERMINAL_SETTINGS_TYPES, UPDATE_NATIVE_KEYBOARD_TYPE, SELECT_MODE_TYPE } from '../actions';

export const initialState = {
  userNameApi: null,
  passwordApi: null,
  apiURL: null,

  password: null,

  mode: TERMINAL_DEFAULT_SETTINGS.MODE,

  ip: null,
  terminalNumber: null,
  paymentProcessor: TERMINAL_DEFAULT_SETTINGS.PAYMENT_PROCESSOR,

  cardConnectURL: null,
  text2pay: TERMINAL_DEFAULT_SETTINGS.TEXT_2_PAY_ENABLED,
  sessionTimeOut: TERMINAL_DEFAULT_SETTINGS.SESSION_TIME_OUT,
  nativeKeyboard: TERMINAL_DEFAULT_SETTINGS.NATIVE_KEYBOARD,
  loading: false,
};

export type InitialStateType = typeof initialState;

export default function globalSettingsReducer(state = initialState, { type, payload = {} }: any): InitialStateType {
  switch (type) {
    case UPDATE_TERMINAL_SETTINGS_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_TERMINAL_SETTINGS_TYPES.SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case UPDATE_TERMINAL_SETTINGS_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NATIVE_KEYBOARD_TYPE:
      return {
        ...state,
        nativeKeyboard: payload,
      };
    case SELECT_MODE_TYPE:
      return {
        ...state,
        mode: payload,
      };
    default:
      return state;
  }
}
