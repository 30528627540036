import React from 'react';
import TextFieldWithLabel from '../TextFieldWithLabel';
import styles from './styles.module.scss';
import DateKeypad from '../../components/DateKeypad';
import { useSelector } from 'react-redux';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';
interface Props {
  value: string | number;
  fieldName: string;
  label?: any;
  placeholder?: string;
  inputType?: string;
  invalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  keyboardLayout?: string;
  onChange: (fieldName: string, newValue: string) => void;
  enterClick?: () => void;
  children?: React.ReactNode;
}

const WizardFormNumeric = ({
  value,
  fieldName,
  label,
  placeholder,
  inputType,
  invalid,
  errorMessage,
  required,
  keyboardLayout,
  onChange,
  children,
  enterClick,
}: Props) => {
  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const onKeyboardPress = (newFieldValue: string) => {
    if (newFieldValue.length == 2 || newFieldValue.length == 5) {
      newFieldValue = newFieldValue + '/';
    }
    if (newFieldValue.length > 10) {
      newFieldValue = newFieldValue.slice(0, 10);
    }
    onChange(fieldName, newFieldValue);
  };

  const onChangeValue = (e: any) => {
    onChange(fieldName, e.target.value);
  };

  return (
    <div className={styles.wizardFormField}>
      <div className={styles.wizardFormFieldInner}>
        <TextFieldWithLabel
          type={inputType}
          value={value}
          inputMode={nativeKeyboardState}
          placeholder={placeholder}
          onChange={onChangeValue}
          onFocus={() => {}}
          label={label}
          invalid={invalid}
          errorMessage={errorMessage}
          required={required}
        />
        {children}
        <div className={styles.rowMargin} />
        {
          <div className={styles.rowMargin}>
            <DateKeypad valueOfField={value} onChange={onKeyboardPress} />
          </div>
        }
      </div>
    </div>
  );
};

WizardFormNumeric.defaultProps = {
  label: '',
  placeholder: '',
  inputType: 'text',
  invalid: false,
  errorMessage: '',
  required: false,
  keyboardLayout: 'default',
  enterClick: () => {},
};

export default WizardFormNumeric;
