import { REMOVE_CART_TYPES } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';
import { REMOVE_MEMBERSHIP_FROM_CART_TYPES } from '../actions/removeCartActions';

export const initialState = {
  list: {},
  showRemoveMembershipAlert: false,
  membershipId: null,
  custId: null,
};

export type InitialStateType = typeof initialState;

export default function cartItemDetailReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    // case TIME_SLOT_TYPES.ADD:
    //   const newList = state.list;
    //   newList[payload.key] = payload.value;
    //   return {
    //     ...state,
    //     list: { ...newList },
    //   };
    // case TIME_SLOT_TYPES.REMOVE:
    //   return {
    //     ...state,
    //   };
    case REMOVE_MEMBERSHIP_FROM_CART_TYPES.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case REMOVE_CART_TYPES.SUCCESS:
      return {
        ...initialState,
      };
    case REMOVE_CART_TYPES.FAILURE:
      return {
        ...initialState,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
