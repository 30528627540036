import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import * as Api from '../../api';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import { saveCustomerToStoreAction } from '../../store/Customer/actions';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import styles from './styles.module.scss';
import isAdult from '../../utils/isAdult';
import Button from '../../components/Button';
import { addSignatureOfNewGuestAction } from '../../store/Customer/actions';
import Checkbox from '../../components/Checkbox';
import SignatureCanvas from '../../components/SignatureCanvas';
import { createAdultWaiver, createMinorWaiver } from '../../utils/waivers';
import WarningAlert from '../../components/WarningAlert';
import WaiverAgreementText from '../../components/WaiverAgreementText';

const EnforceWaiverAgreementAdultScreen = () => {
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const sigPad = React.useRef(null);

  const {
    newCustomerStore,
    newGuestStore,
    registrationSettings: { adultAge, waiverStep },
  } = useSelector(state => state);
  const [currentAdultNewGuest, setCurrentAdultNewGuest] = useState(null);
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [agreeWithRulesError, setAgreeWithRulesError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResetSignatureModalShow, setIsResetSignatureModalShow] = useState(false);
  const [createdCustomer, setCreatedCustomer] = useState(null);
  const [createdMinorCustomers, setCreatedMinorCustomers] = useState([]);
  const [createdAdultCustomers, setCreatedAdultCustomers] = useState([]);

  useEffect(() => {
    setCurrentAdultNewGuest(_.find(newGuestStore.guests, obj => obj.id === id));
  }, [id]);

  const startRegistrationFlow = async listOfNewGuests => {
    setLoading(true);
    const listOfNewMinorGuests = listOfNewGuests.filter(item => !isAdult(item.commonData.birthDate, adultAge));
    const listOfNewAdultGuests = listOfNewGuests.filter(
      item => isAdult(item.commonData.birthDate, adultAge) && item.signature,
    );

    let tempCustomer;
    if (!createdCustomer) {
      const { data: primaryCust } = await Api.createCustomer(newCustomerStore.commonData);
      tempCustomer = primaryCust;
      setCreatedCustomer(primaryCust);
    } else {
      tempCustomer = createdCustomer;
    }
    const primaryCust = tempCustomer;

    const adultWaiver = await createAdultWaiver({
      signature: newCustomerStore.signature,
      adultCustId: primaryCust.custId,
    });

    if (!adultWaiver) {
      setIsResetSignatureModalShow(true);
      return;
    }

    if (!isResetSignatureModalShow) {
      for (const item of listOfNewMinorGuests) {
        const findCustomer = createdMinorCustomers.find(
          customer =>
            customer.fName === item.commonData.fName &&
            customer.lName === item.commonData.lName &&
            customer.birthDate === item.commonData.birthDate,
        );
        let cust;
        if (!findCustomer) {
          const {
            data: { custId: curentCustId },
          } = await Api.createCustomer(item.commonData);
          cust = { ...item.commonData, curentCustId };
          setCreatedMinorCustomers([...createdMinorCustomers, cust]);
        } else {
          cust = findCustomer;
        }

        const minorWaiver = await createMinorWaiver({
          guardianSignature: newCustomerStore.signature,
          minorCustId: cust.curentCustId,
          guardianCustId: primaryCust.custId,
        });
        if (!minorWaiver) {
          setIsResetSignatureModalShow(true);
          return;
        }
        await Api.addToCustomerGroup({
          custIds: [cust.curentCustId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: cust.curentCustId,
            },
          ],
          custId: primaryCust.custId,
        });
      }
      for (const item of listOfNewAdultGuests) {
        const findCustomer = createdAdultCustomers.find(
          customer =>
            customer.fName === item.commonData.fName &&
            customer.lName === item.commonData.lName &&
            customer.birthDate === item.commonData.birthDate,
        );
        let cust;
        if (!findCustomer) {
          const {
            data: { custId: curentCustId },
          } = await Api.createCustomer(item.commonData);
          cust = { ...item.commonData, curentCustId };
          setCreatedAdultCustomers([...createdAdultCustomers, cust]);
        } else {
          cust = findCustomer;
        }

        const adultWaiver = await createAdultWaiver({
          signature: item.signature,
          adultCustId: cust.curentCustId,
        });
        if (!adultWaiver) {
          setIsResetSignatureModalShow(true);
          return;
        }
        await Api.addToCustomerGroup({
          custIds: [cust.curentCustId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: cust.curentCustId,
            },
          ],
          custId: primaryCust.custId,
        });
      }
      const { data: fetchPrimaryCust } = await Api.fetchCustomerById(primaryCust.custId);
      dispatch(saveCustomerToStoreAction(fetchPrimaryCust));
      history.push(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
    }
    setLoading(false);
  };

  const onBackClick = () => {
    history.goBack();
  };

  const onContinueClick = async () => {
    try {
      const listOfNewGuests = newGuestStore.guests;
      const indexOfCurrentNewGuest = listOfNewGuests.findIndex(item => item.id === id);

      const signatureOfCustomer = sigPad.current.toDataURL().substr(22);
      if (!agreeWithRules) {
        setAgreeWithRulesError(true);
        return;
      }
      setAgreeWithRulesError(false);
      if (sigPad.current.isEmpty()) {
        return;
      }
      listOfNewGuests[indexOfCurrentNewGuest].signature = signatureOfCustomer;

      const listOfNewAdultGuestsWithoutSignature = listOfNewGuests.filter(
        item => isAdult(item.commonData.birthDate, adultAge) && !item.signature,
      );

      if (listOfNewAdultGuestsWithoutSignature.length > 0) {
        dispatch(addSignatureOfNewGuestAction(listOfNewGuests));
        sigPad.current.clear();
        history.push(`${ROUTES.WAIVER_AGREEMENT_ADULT}/${listOfNewAdultGuestsWithoutSignature[0].id}`);
        return;
      }
      await startRegistrationFlow(listOfNewGuests);
    } catch (e) {}
  };

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };

  useEffect(() => {
    if (isResetSignatureModalShow) {
      setLoading(false);
    }
  }, [isResetSignatureModalShow]);

  const resetSignatureModalHandleClick = () => {
    clearSignature();
    setIsResetSignatureModalShow(false);
  };

  return (
    <Fragment>
      <ContentContainer loading={loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />
        <div className={styles.content}>
          <div className={styles.waiverAgreementTextContainer}>
            <span className={styles.waiverAgreementTextTitle}>
              Waiver Agreement - {currentAdultNewGuest ? currentAdultNewGuest.commonData.fName : ''}{' '}
              {currentAdultNewGuest ? currentAdultNewGuest.commonData.lName : ''}
            </span>
            <WaiverAgreementText waiverText={waiverStep.adultWaiverText} />
          </div>
          <div className={styles.waiverAgreement}>
            <div>
              <Checkbox
                state={!!agreeWithRules}
                label="I agree to the terms and conditions of the above agreement."
                handleClick={() => setAgreeWithRules(prevState => !prevState)}
                error={agreeWithRulesError}
              />
            </div>
          </div>
          <div className={styles.waiverAgreementSignatureContainer}>
            <span className={styles.waiverAgreementSignatureTitle}>
              Waiver Signature - {currentAdultNewGuest ? currentAdultNewGuest.commonData.fName : ''}{' '}
              {currentAdultNewGuest ? currentAdultNewGuest.commonData.lName : ''}
            </span>
            <SignatureCanvas sigPad={sigPad} />
          </div>
        </div>
        <div className={styles.navigation}>
          <Button theme="white" size="base" handleClick={onBackClick} customClass={styles.whiteButton}>
            Back
          </Button>
          <div className={styles.navigationFilter} />
          <Button theme="red" size="base" handleClick={onContinueClick} customClass={styles.redButton}>
            Continue
          </Button>
        </div>
      </ContentContainer>
      <IdleMonitor />
      <WarningAlert
        zIndex={9999}
        isOpen={isResetSignatureModalShow}
        title="Whoops!"
        description="An error occurred while creating your signature, please try again."
        handleClick={resetSignatureModalHandleClick}
      />
    </Fragment>
  );
};

export default EnforceWaiverAgreementAdultScreen;
