import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface CheckboxProps {
  state: boolean;
  disabled?: boolean;
  label: string;
  handleClick: any;
  error?: boolean;
  theme?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  state,
  disabled,
  label,
  handleClick,
  error = false,
  theme = 'red',
}: CheckboxProps) => {
  return (
    <div className={cn(styles.checkboxContainer, styles[`${theme}`])}>
      <button
        type="button"
        disabled={disabled}
        onClick={handleClick}
        className={cn(styles.checkmark, { [styles.checked]: state })}
      />
      <div onClick={handleClick} className={cn(styles.label, { [styles.error]: error })}>
        {label}
      </div>
    </div>
  );
};

export default Checkbox;
