import { takeLatest, put, all, call, delay, select } from 'redux-saga/effects';
import { ROUTES } from '../../../constants';
import { PAYMENT_STATUSES } from '../../../constants';
import { history } from '../../../App';
import {
  GET_PAYMENT_STATUS_TYPES,
  getPaymentStatusAction,
  getPaymentStatusActionSuccess,
  getPaymentActionFailure,
  PAYMENT_IS_FAILED,
  paymentIsFailedAction, createPaymentAction,
} from '../actions';
import { fetchPaymentStatus } from '../../../api';

function* getPaymentStatusWorker({ payload }: any) {

  const { payment : { paymentStatusId, attemptNumber, badStatusAttempts } } = yield select((store) => store);

  try {
    const { data: response } = yield call<any>(fetchPaymentStatus, paymentStatusId);
    switch (response.status) {
      case PAYMENT_STATUSES.PROCESSING:
        yield delay(3000);
        yield put<any>(getPaymentStatusAction(response));
        break;
      case PAYMENT_STATUSES.SUCCESS:
        yield put<any>(getPaymentStatusActionSuccess(paymentStatusId));
        history.push(ROUTES.PAYMENT_SUCCESS);
        break;
      case PAYMENT_STATUSES.ERROR:
        yield put<any>(paymentIsFailedAction());
        break;
      default:
    }
  } catch (err) {
    yield put<any>(getPaymentActionFailure());
    if (attemptNumber <= 3) {
      history.push(ROUTES.PAYMENT_ERROR);
      return;
    }
    history.push(ROUTES.PAYMENT_FAILURE);
  }
}

function* paymentFailureWorker() {
  try {
    const { payment : { badStatusAttempts, attemptNumber } } = yield select((store) => store);
    if (badStatusAttempts <= 1) {
      return yield put<any>(createPaymentAction());
    }

    if (attemptNumber <= 3) {
      history.push(ROUTES.PAYMENT_ERROR);
    } else {
      history.push(ROUTES.PAYMENT_FAILURE);
    }

  } catch (err) {
    console.log(err);
  }
}

export default function* getPaymentStatusSaga() {
  yield all([
    yield takeLatest(GET_PAYMENT_STATUS_TYPES.GET_PAYMENT_STATUS, getPaymentStatusWorker),
    yield takeLatest(PAYMENT_IS_FAILED, paymentFailureWorker),
  ]);
}
