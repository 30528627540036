const PAYMENT_PROCESSORS = {
  clover: {
    label: 'Clover',
    value: 'clover',
  },
  cardConnect: {
    label: 'CardConnect',
    value: 'cardConnect',
  },
};

export default PAYMENT_PROCESSORS;
