import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchCustomerRelationships } from '../../../api';
import {
  ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES,
  addMemberToCustomerRelationshipsActionSuccess,
  addMemberToCustomerRelationshipsActionFailure,
} from '../actions';

export function* addMemberToCustomerRelationshipsWorker() {
  try {
    yield call<any>(fetchCustomerRelationships, {});
    yield put<any>(addMemberToCustomerRelationshipsActionSuccess({}));
  } catch (err) {
    yield put<any>(addMemberToCustomerRelationshipsActionFailure());
  }
}

export function* addMemberToCustomerRelationshipsWatcher() {
  yield takeLatest(ADD_MEMBER_TO_CUSTOMER_RELATIONSHIPS_TYPES.REQUEST, addMemberToCustomerRelationshipsWorker);
}

export default function* addMemberToCustomerRelationshipsSaga() {
  yield all([addMemberToCustomerRelationshipsWatcher()]);
}
