import * as Api from '../api';

export const createMinorWaiver = async data => {
  const minorWaiver = await Api.createMinorWaiver(data);
  return minorWaiver.status === 200;
};

export const createAdultWaiver = async data => {
  const adultWaiver = await Api.createAdultWaiver(data);
  return adultWaiver.status === 200;
};
