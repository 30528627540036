import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { createPaymentLink } from '../../../api';
import { CREATE_PAY_BY_LINK_TYPES, createPayByLinkSuccess, createPayByLinkFailure } from '../actions';
import { PRODUCT_TYPE } from '../../../constants';
import * as Api from '../../../api';
import { isEmpty } from 'lodash';

export function* createPayByLinkWorker() {
  try {
    const {
      terminalSettings: { terminalNumber },
      cartStore: { checkId, checkDetails },
      customerStore,
      payByLink: { kdsRequestItems },
    } = yield select(store => store);

    const customerData = {};
    let phoneNumber = null;

    const foodItems = checkDetails.filter(item => item.productType === PRODUCT_TYPE.FOOD);
    if (foodItems.length) {
      const KDSSettings = yield call(Api.fetchControlPanelSettings, 'KDSEnableIntegration', 'MainEngine');
      if (KDSSettings.data[0].settingValue === 'true') {
        customerData['terminalName'] = `SSK${terminalNumber}`;
        if (customerStore.custId) {
          customerData['foodPhoneNumber'] = customerStore.phoneNumber || customerStore.cell;
          customerData['foodOrderName'] = customerStore.fName;
        } else {
          customerData['foodPhoneNumber'] = kdsRequestItems.foodPhoneNumber;
          customerData['foodOrderName'] = kdsRequestItems.foodOrderName;
        }
      } else {
        phoneNumber = kdsRequestItems?.foodPhoneNumber;
      }
    }

    const { data: paymentLinkURL } = yield call(Api.fetchControlPanelSettings, 'PaymentLinkURL', 'MainEngine');
    const { data } = yield call(createPaymentLink, {
      checkId,
      linkSourceId: 1,
      ...(!isEmpty(customerData) && { kdsRequestItems: customerData }),
    });
    yield put<any>(
      createPayByLinkSuccess({
        ...data,
        ...(!isEmpty(customerData) && { kdsRequestItems: customerData }),
        ...(!isEmpty(phoneNumber) && { phoneNumber }),
        ...(!isEmpty(paymentLinkURL) && { paymentLinkURL: paymentLinkURL[0]?.settingValue }),
      }),
    );
  } catch (err) {
    yield put<any>(createPayByLinkFailure());
  }
}

export function* createCartSagaWatcher() {
  yield takeLatest(CREATE_PAY_BY_LINK_TYPES.REQUEST, createPayByLinkWorker);
}

export default function* createCartSaga() {
  yield all([createCartSagaWatcher()]);
}
