import { put, call, takeLatest } from 'redux-saga/effects';
import { SELECTION_OF_PARTICIPANT_SHOW } from '../actions';
import { customersSchedulesAvailableInKiosk } from '../../../api';
import { showSelectionOfParticipantSuccess } from '../actions/selectionOfParticipantActions';

export function* selectionOfParticipantShowWorker({ payload }: any) {
  try {
    let { scheduledTime } = payload;
    const { productId, checkId } = payload;
    if (typeof scheduledTime === 'object') {
      scheduledTime = scheduledTime.join(',');
    }
    const { data } = yield call<any>(
      customersSchedulesAvailableInKiosk,
      productId,
      scheduledTime,
      checkId ? checkId : 0,
    );
    yield put<any>(showSelectionOfParticipantSuccess({ ...data, loading: false }));
  } catch (e) {
    yield put<any>(showSelectionOfParticipantSuccess({ loading: false }));
    console.log(e);
  }
}

export default function* purchaseFlowSaga() {
  yield takeLatest(SELECTION_OF_PARTICIPANT_SHOW, selectionOfParticipantShowWorker);
}
