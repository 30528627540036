export const CREATE_CART_TYPES = {
  REQUEST: 'cart/CREATE_CART_REQUEST',
  SUCCESS: 'cart/CREATE_CART_SUCCESS',
  FAILURE: 'cart/CREATE_CART_FAILURE',
  RESET_LOADING: 'cart/CREATE_CART_RESET_LOADING',
};

export const createCartAction = () => ({
  type: CREATE_CART_TYPES.REQUEST,
});

export const createCartActionSuccess = (payload: any) => ({
  type: CREATE_CART_TYPES.SUCCESS,
  payload,
});

export const createCartActionFailure = () => ({
  type: CREATE_CART_TYPES.FAILURE,
});

export const createCartResetLoadingAction = () => ({
  type: CREATE_CART_TYPES.RESET_LOADING,
});
