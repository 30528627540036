import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Api from '../../api';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import { saveCustomerToStoreAction, updateCommonDataOfNewCustomerAction } from '../../store/Customer/actions';
import ContentContainer from '../../components/ContentContainer';
import IdleMonitor from '../../services/IdleMonitor';
import PhoneKeypad from '../../components/PhoneKeypad';
import WarningAlert from '../../components/WarningAlert';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import isAdult from '../../utils/isAdult';
import HeaderPanel from '../../components/HeaderPanel';
import cn from 'classnames';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

type formType = {
  cell: string;
};

const LogInFirstStepScreen = () => {
  const {
    customerStore,
    resetPinStore,
    registrationSettings: { adultAge },
  } = useSelector(state => state);
  const dispatch = useDispatch();

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [customerIsMinor, setCustomerIsMinor] = useState(false);
  const [moreThanOneCustomer, setMoreThanOneCustomer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timerStatus, setTimerStatus] = useState(false);

  useEffect(() => {
    let timer;
    if (timerStatus) {
      timer = setTimeout(() => goToLogInAnotherWay(), 10000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timerStatus]);

  const [fieldsOfForm, setFieldsOfForm] = useState({
    cell: '',
  });

  const [errorsOfForm, setErrorsOfForm] = useState({
    cell: '',
  });

  const validateFieldsOfForm = () => {
    if (fieldsOfForm.cell === '') {
      setErrorsOfForm({ ...errorsOfForm, ['cell']: 'Please enter phone number' });
      return false;
    }
    setErrorsOfForm({ ...errorsOfForm, ['cell']: '' });

    return true;
  };

  const phoneKeypadHandler = (newValueOfField: string) => {
    setFieldsOfForm({ ...fieldsOfForm, ['cell']: newValueOfField });
  };

  const goToUserSearch = () => {
    history.push(ROUTES.LOG_IN_ANOTHER_WAY);
  };

  const findCustomer = async () => {
    try {
      if (!validateFieldsOfForm()) return;
      if (fieldsOfForm.cell.length >= 3) {
        setLoading(true);
        const { data } = await Api.findCustomersByPhone(fieldsOfForm.cell);
        setLoading(false);
        if (data.length >= 2) {
          setMoreThanOneCustomer(true);
          setTimerStatus(true);
          return;
        }
        if (data.length === 1) {
          if (!isAdult(data[0].birthDate, adultAge)) {
            setCustomerIsMinor(true);
            return;
          }
          dispatch(saveCustomerToStoreAction(data[0]));
          history.push(ROUTES.LOG_IN_SECOND_STEP);
          return;
        }
        dispatch(updateCommonDataOfNewCustomerAction({ cell: fieldsOfForm.cell }));
        history.push(ROUTES.SIGN_UP_FIRST_STEP);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const goToLogInAnotherWay = () => {
    setMoreThanOneCustomer(false);
    history.push(ROUTES.LOG_IN_ANOTHER_WAY);
  };

  const endSession = () => {
    history.replace(ROUTES.ROOT);
  };

  return (
    <Fragment>
      <ContentContainer loading={loading || resetPinStore.loading || customerStore.loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div className={styles.rowMargin}>
              <TextFieldWithLabel
                type="text"
                inputMode={nativeKeyboardState}
                required={true}
                value={fieldsOfForm['cell']}
                placeholder=""
                label="Mobile Number"
                onFocus={() => {}}
                invalid={Boolean(errorsOfForm.cell.length)}
                errorMessage={errorsOfForm.cell}
              />
            </div>
            <div className={styles.rowMargin}>
              <PhoneKeypad valueOfField={fieldsOfForm['cell']} onChange={phoneKeypadHandler} />
            </div>

            <div className={styles.navigation}>
              <Button
                theme={'white'}
                handleClick={goToUserSearch}
                customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
              >
                Log in Another Way
              </Button>
              <div className={styles.navigationFilter} />
              <Button
                theme="red"
                handleClick={findCustomer}
                customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </ContentContainer>
      <WarningAlert
        isOpen={moreThanOneCustomer}
        title="Sorry!"
        description="We've found more than one profile using the phone number you've provided. We'll take you through a couple more steps to find your profile."
        handleClick={goToLogInAnotherWay}
      />
      <WarningAlert
        isOpen={customerIsMinor}
        title="Sorry!"
        description="Please have an adult sign in first to complete your purchase, or see a cashier for help"
        handleClick={endSession}
      />
      <IdleMonitor />
    </Fragment>
  );
};

export default LogInFirstStepScreen;
