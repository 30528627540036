import React from 'react';
import DateKeypadButton from './DateKeypadButton';
import deleteIcon from '../../assets/icons/keyboard/delete.svg';
import styles from './styles.module.scss';

interface DateKeypadProps {
  valueOfField: string | number;
  onChange: any;
}

const DateKeypad: React.SFC<DateKeypadProps> = ({ valueOfField, onChange }: DateKeypadProps) => {
  const eventHandler = (valueOfKey?: string | number) => {
    if (typeof valueOfKey === 'number') {
      onChange(`${valueOfField}${valueOfKey}`);
    }
    if (typeof valueOfKey === 'string') {
      if (valueOfKey === 'clear') {
        onChange('');
      }
      if (valueOfKey === 'del') {
        if (valueOfField.toString().length === 3 || valueOfField.toString().length === 6) {
          onChange(`${valueOfField.toString().slice(0, -2)}`);
        } else {
          onChange(`${valueOfField.toString().slice(0, -1)}`);
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <DateKeypadButton valueOfKey={1} onClick={eventHandler}>
        1
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={2} onClick={eventHandler}>
        2
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={3} onClick={eventHandler}>
        3
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={4} onClick={eventHandler}>
        4
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={5} onClick={eventHandler}>
        5
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={6} onClick={eventHandler}>
        6
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={7} onClick={eventHandler}>
        7
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={8} onClick={eventHandler}>
        8
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={9} onClick={eventHandler}>
        9
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={'clear'} onClick={eventHandler}>
        Clear
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={0} onClick={eventHandler}>
        0
      </DateKeypadButton>
      <DateKeypadButton valueOfKey={'del'} onClick={eventHandler}>
        <img src={deleteIcon} alt="" />
      </DateKeypadButton>
    </div>
  );
};

export default DateKeypad;
