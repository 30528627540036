import React from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import TimeSlotGridItem from '../TimeSlotGridItem';

const Schedule = ({ timeSlots, timeSlotHandler, selectedTimeSlots }) => {
  return (
    <div className={styles.timeSlotGrid}>
      {timeSlots[0] &&
        timeSlots[0].timeslots.map((item, index) => {
          if (moment().isAfter(item.scheduledTime)) return;
          return (
            <TimeSlotGridItem
              key={index}
              data={item}
              handleClick={timeSlotHandler}
              isActive={item.isActive}
              isSelected={!!selectedTimeSlots.find(i => i.scheduledTime === item.scheduledTime)}
            />
          );
        })}
    </div>
  );
};
export default Schedule;
