import React from 'react';
import styles from './styles.module.scss';

interface CartItemsListProps {
  children: React.ReactNode[];
}

const CartItemsList = ({ children }: CartItemsListProps) => {

  if (!children.length) {
    return (
      <div className={styles.emptyCart}>
        <span>
          No items in cart. Select products<br />
          from the left to place into the cart
        </span>
      </div>
    );
  }

  return (
    <div className={styles.cartItemsList}>
      {React.Children.map(children, (child, i) => child)}
    </div>
  );
};

export default CartItemsList;
