import React from 'react';
import styles from './styles.module.scss';

interface Props {
  value: number;
  handleClick: (type: string) => void;
}

const InputNumber = ({ value, handleClick }: Props) => {
  return (
    <div className={styles.inputNumber}>
      <button onClick={() => handleClick('minus')} className={styles.minus}>
        -
      </button>
      <input type="text" readOnly value={value} />
      <button onClick={() => handleClick('plus')} className={styles.plus}>
        +
      </button>
    </div>
  );
};

export default InputNumber;
