import React from 'react';
import styles from './styles.module.scss';
import update from 'immutability-helper';
import Checkbox from '../Checkbox';
import Confirm from '../Prompt';

interface Props {
  handleContinueClick?: any;
  prompts?: any;
  setPromptTempMembers?: any;
  setPrompts?: any;
  setQty?: any;
}

const Prompts = ({ handleContinueClick, prompts, setPrompts, setPromptTempMembers, setQty }: Props) => {
  const resetPromptsHandle = () => {
    setPromptTempMembers(null);
    setPrompts(null);
    setQty(0);
  };

  return (
    <>
      {prompts.map((prompt, idx) => (
        <Confirm
          key={idx}
          isOpen={prompt.isOpen}
          title={prompt.header}
          titleButtonCancel="Cancel"
          titleButtonOk="Continue"
          handleReturnClick={resetPromptsHandle}
          handleContinueClick={() => handleContinueClick(idx)}
          disabledContinue={prompt.disabledContinue}
        >
          <div className={styles.prompts}>
            <p className={styles.promptsSubheader}>{prompt.subHeader}</p>
            {prompt.promptLabels && (
              <div className={styles.promptsLabels}>
                {prompt.promptLabels.map((label, labelIdx) => (
                  <div className={styles.promptsLabel} key={label.promptLabelId}>
                    <Checkbox
                      state={label.checked}
                      label={label.promptLabel}
                      handleClick={() => {
                        let newPromptsData = update(prompts, {
                          [idx]: { promptLabels: { [labelIdx]: { checked: { $set: !label.checked } } } },
                        });
                        const uncheckedLabels = newPromptsData[idx].promptLabels.filter(check => !check.checked);
                        newPromptsData = update(newPromptsData, {
                          [idx]: { disabledContinue: { $set: !!uncheckedLabels.length } },
                        });
                        setPrompts(newPromptsData);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Confirm>
      ))}
    </>
  );
};

export default Prompts;
