import request from '../services/Api';
import { AxiosResponse } from 'axios';

export const fetchCustomerRelationships = (custId: string): Promise<AxiosResponse> =>
  request.get(`CoreAPI/CustomerRelationships/${custId}`);

export const addToCustomerRelationships = (data: any): Promise<AxiosResponse> =>
  request.post('CoreAPI/CustomerRelationships', data);

export const removeFromCustomerRelationships = (data: any): Promise<AxiosResponse> =>
  request.post('CoreAPI/CustomerRelationships/Remove', data);
