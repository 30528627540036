import { SELECTION_OF_PARTICIPANT_SHOW, SELECTION_OF_PARTICIPANT_HIDE, NOT_ENOUGH_TIME_SLOTS } from '../actions';

import { ADD_ITEM_TO_CART_TYPES } from '../../Cart/actions';

import { END_CUSTOMER_SESSION } from '../../General/actions';
import { SELECTION_OF_PARTICIPANT_SHOW_SUCCESS } from '../actions/selectionOfParticipantActions';

export const initialState = {
  notEnoughTimeSlots: false,
  selectionOfParticipant: false,
  loading: false,
  unavailableCustomers: null,
};

export type InitialStateType = typeof initialState;

export default function purchaseFlowReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case SELECTION_OF_PARTICIPANT_SHOW:
      return {
        ...state,
        loading: true,
      };
    case SELECTION_OF_PARTICIPANT_SHOW_SUCCESS:
      return {
        ...state,
        ...payload,
        selectionOfParticipant: true,
      };
    case SELECTION_OF_PARTICIPANT_HIDE:
      return {
        ...state,
        unavailableCustomers: null,
        selectionOfParticipant: false,
      };

    case NOT_ENOUGH_TIME_SLOTS:
      return {
        ...state,
        notEnoughTimeSlots: payload.notEnoughTimeSlots,
      };

    case ADD_ITEM_TO_CART_TYPES.SUCCESS:
      return {
        ...initialState,
      };

    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
