import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderPanel from '../../components/HeaderPanel';
import ContentContainer from '../../components/ContentContainer';

import { getPaymentStatusAction } from '../../store/Payment/actions';

import styles from './styles.module.scss';

const PaymentProcessingScreen = () => {
  const dispatch = useDispatch();
  const { payment: paymentStore } = useSelector(state => state);
  React.useEffect(() => {
    dispatch(
      getPaymentStatusAction({ paymentStatusId: paymentStore.paymentStatusId, attemptNumber: paymentStore.attempts }),
    );
  }, [paymentStore.paymentStatusId]);

  return (
    <>
      <ContentContainer theme="red">
        <HeaderPanel theme="dark" />
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <h2 className={styles.title}>Processing</h2>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default PaymentProcessingScreen;
