import { takeLatest, all, select, put, call } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { START_PAYMENT_TYPE } from '../actions';
import { updateKdsDataStartAction } from '../../PayByLink/actions';
import { updateKdsDataWorker } from '../../PayByLink/sagas/updateKdsDataSaga';
import { createPayByLinkAction } from '../../PayByLink/actions';
import { ROUTES, PAYMENT_PROCESSORS, PRODUCT_TYPE } from '../../../constants';
import { history } from '../../../App';
import * as Api from '../../../api';

export function* startPaymentProcessWorker({ payload }: any) {
  try {
    const {
      terminalSettings,
      cartStore,
      customerStore,
      terminal: { customerSessionMode },
    } = yield select(store => store);

    const foodItems = cartStore.checkDetails.filter(item => item.productType === PRODUCT_TYPE.FOOD);
    let kdsEnabled = false;

    if (foodItems.length) {
      const KDSSettings = yield call(Api.fetchControlPanelSettings, 'KDSEnableIntegration', 'MainEngine');
      if (KDSSettings.data[0].settingValue === 'true' && !customerStore.custId && isEmpty(payload)) {
        kdsEnabled = true;
        return yield put(updateKdsDataStartAction({ kdsEnabled }));
      }
    }

    if (!isEmpty(payload) || customerSessionMode !== 'food') {
      yield call(updateKdsDataWorker, payload.kdsData);
      if (terminalSettings.paymentProcessor === PAYMENT_PROCESSORS.clover.value) {
        history.push(ROUTES.PAYMENT_CLOVER);
      } else if (terminalSettings.paymentProcessor === PAYMENT_PROCESSORS.cardConnect.value) {
        yield put(createPayByLinkAction());
        if (terminalSettings.text2pay) {
          return history.push(ROUTES.PAYMENT_ONLINE);
        } else {
          return history.push(ROUTES.PAYMENT_CARD_CONNECT);
        }
      }
    }
  } catch (err) {}
}

export default function* startPaymentProcessSaga() {
  yield all([yield takeLatest(START_PAYMENT_TYPE, startPaymentProcessWorker)]);
}
