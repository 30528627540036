const RELATIONSHIP_TYPES = [
  {
    value: 'brother',
    label: 'Brother',
  },
  {
    value: 'son',
    label: 'Son',
  },
  {
    value: 'daughter',
    label: 'Daughter',
  },
  {
    value: 'father',
    label: 'Father',
  },
  {
    value: 'mother',
    label: 'Mother',
  },
  {
    value: 'sister',
    label: 'Sister',
  },
  {
    value: 'granddaughter',
    label: 'Granddaughter',
  },
  {
    value: 'grandson',
    label: 'Grandson',
  },
  {
    value: 'guardian',
    label: 'Guardian',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export default RELATIONSHIP_TYPES;
