import { NEW_GUEST_TYPES, CUSTOMER_TYPES } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  guests: [],
};

export type InitialStateType = typeof initialState;

export default function newGuestReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case NEW_GUEST_TYPES.ADD_TO_GUESTS_LIST:
      return {
        ...state,
        guests: [...state.guests, payload],
      };
    case NEW_GUEST_TYPES.ADD_SIGNATURE_OF_NEW_GUEST:
      return {
        ...state,
        guests: payload,
      };
    case NEW_GUEST_TYPES.UPDATE_NEW_GUEST:
      return {
        ...state,
        guests: payload,
      };
    case CUSTOMER_TYPES.SAVE_CUSTOMER_TO_STORE:
      return {
        ...initialState,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
