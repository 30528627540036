export const ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES = {
  REQUEST: 'customerGroup/ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES_REQUEST',
  SUCCESS: 'customerGroup/ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES_SUCCESS',
  FAILURE: 'customerGroup/ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES_FAILURE',
};

export const addMemberToCustomerGroupAction = (payload: any) => ({
  type: ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES.REQUEST,
  payload,
});

export const addMemberToCustomerGroupActionSuccess = (payload: any) => ({
  type: ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES.SUCCESS,
  payload,
});

export const addMemberToCustomerGroupActionFailure = () => ({
  type: ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES.FAILURE,
});
