import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import cn from "classnames";

interface SubItemsListProps {
  numberOfSliders: number;
  children: React.ReactNode;
}

const ProductOptionsSlider = ({ numberOfSliders, children }:SubItemsListProps) => {

  const { terminalSettings, payByLink } = useSelector(state => state);

  return (
    <div className={styles.productOptionsSlider}>
      <div className={cn(styles.slider, { [styles.sliderSmall]: terminalSettings.nativeKeyboard })}>
        {children}
      </div>
    </div>
  );
};

export default ProductOptionsSlider;
