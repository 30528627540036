export const SELECTION_OF_PARTICIPANT_SHOW = 'cart/SELECTION_OF_PARTICIPANT_SHOW';
export const SELECTION_OF_PARTICIPANT_SHOW_SUCCESS = 'cart/SELECTION_OF_PARTICIPANT_SHOW_SUCCESS';
export const SELECTION_OF_PARTICIPANT_HIDE = 'cart/SELECTION_OF_PARTICIPANT_HIDE';

export const showSelectionOfParticipant = (payload: any) => ({
  payload,
  type: SELECTION_OF_PARTICIPANT_SHOW,
});

export const showSelectionOfParticipantSuccess = (payload: any) => ({
  payload,
  type: SELECTION_OF_PARTICIPANT_SHOW_SUCCESS,
});

export const hideSelectionOfParticipant = () => ({
  type: SELECTION_OF_PARTICIPANT_HIDE,
});
