import { takeLatest, put, all, call } from 'redux-saga/effects';
import { createCustomer, createAdultWaiver, sendWelcomeEmail } from '../../../api';
import { ROUTES } from '../../../constants';
import { history } from '../../../App';

import { NEW_CUSTOMER_TYPES, createNewCustomerActionSuccess, createNewCustomerActionFailure } from '../actions';

export function* createNewCustomerSagaWorker({ payload: { customerData, signatureOfCustomer } }: any) {
  try {
    const { data: newCustomerData } = yield call(createCustomer, { ...customerData, doNotMail: false });
    yield call(sendWelcomeEmail, newCustomerData.custId);
    yield call(createAdultWaiver, { signature: signatureOfCustomer, adultCustId: newCustomerData.custId });
    yield put(createNewCustomerActionSuccess(newCustomerData));
    history.replace(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
  } catch (err) {
    yield put(createNewCustomerActionFailure());
    history.replace(ROUTES.ROOT);
  }
}

export default function* newCustomerSaga() {
  yield all([yield takeLatest(NEW_CUSTOMER_TYPES.CREATE_NEW_CUSTOMER_REQUEST, createNewCustomerSagaWorker)]);
}
