export const GET_REGISTRATION_SETTINGS_TYPES = {
  REQUEST: 'settings/GET_REGISTRATION_SETTINGS_REQUEST',
  SUCCESS: 'settings/GET_REGISTRATION_SETTINGS_SUCCESS',
  FAILURE: 'settings/GET_REGISTRATION_SETTINGS_FAILURE',
};

export const getRegistrationSettingsAction = () => ({
  type: GET_REGISTRATION_SETTINGS_TYPES.REQUEST,
});

export const getRegistrationSettingsActionSuccess = (payload: any) => ({
  type: GET_REGISTRATION_SETTINGS_TYPES.SUCCESS,
  payload,
});

export const getRegistrationSettingsActionFailure = () => ({
  type: GET_REGISTRATION_SETTINGS_TYPES.FAILURE,
});
