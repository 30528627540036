import React from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import ContentContainer from '../../components/ContentContainer';
import TextField from '../../components/TextField';
import Logo from '../../components/Logo';
import Keyboard from '../../components/Keyboard';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import { nativeKeyboardStateSelector, terminalSettingsSelector } from '../../store/Settings/selectors';
import cn from 'classnames';

const LoginSettingsScreen = () => {
  const terminalSettings = useSelector(terminalSettingsSelector);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [activeField, setActiveField] = React.useState('password');
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [fieldsOfForm, setFieldsOfForm] = React.useState<any>({
    password: '',
  });

  const onKeyboardPress = (newFieldValue: string, fieldName) => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
  };

  const goBack = () => {
    history.replace(ROUTES.ROOT);
  };

  const loginToSettings = () => {
    if (fieldsOfForm.password !== terminalSettings.password) {
      setErrorMessage('wrong password');
      return;
    }
    history.push(ROUTES.SETTINGS_API);
  };

  const onFocus = () => {
    setErrorMessage(null);
    setActiveField('password');
  };

  return (
    <>
      <ContentContainer>
        <div className={styles.container}>
          <div className={styles.contentInner}>
            <Logo width="270" />
            <div className={styles.separator} />
            <h1 className={styles.title}>Self-Serve Kiosk</h1>
            <h3 className={styles.mainMessage}>
              Enter your password to access Self
              <br />
              Serve Kiosk settings
            </h3>
            <div className={styles.mb15}>
              <TextField
                inputMode={nativeKeyboardState}
                type="password"
                value={fieldsOfForm.password}
                placeholder=""
                isActive={activeField === 'password'}
                invalid={false}
                errorMessage={''}
                theme="themeTransparent"
                onFocus={onFocus}
              />
            </div>
            {errorMessage && <div className={cn(styles.mb30, styles.errorMessage)}>{errorMessage}</div>}
          </div>
          <div className={styles.keyboard}>
            <Keyboard
              fieldName={activeField}
              valueOfField={fieldsOfForm[activeField]}
              onChange={onKeyboardPress}
              handleEnter={loginToSettings}
              hideKeyboard={() => {}}
            />
          </div>
          <div className={styles.navigation}>
            <Button theme="white" handleClick={goBack} customClass={styles.cancelButton}>
              Cancel
            </Button>
            <Button theme="red" handleClick={loginToSettings} customClass={styles.saveButton}>
              Next
            </Button>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default LoginSettingsScreen;
