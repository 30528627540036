import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

interface CountdownTimerPhotoProps {
  time: number;
  onComplete: () => void;
}

const CountdownTimerPhoto: React.FC<CountdownTimerPhotoProps> = ({ time, onComplete }: CountdownTimerPhotoProps) => {
  const [lastSeconds, setLastSeconds] = useState(time);
  const [seconds, setSeconds] = useState(time);

  useEffect(() => {
    if (seconds < 1) {
      onComplete();
      return;
    }
    const intervalId = setInterval(() => {
      countDown(seconds);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [seconds]);

  const countDown = seconds => {
    const newSeconds = seconds - 1;
    setSeconds(newSeconds);
    setLastSeconds(newSeconds);
  };

  return (
    <div className={styles.countdownTimerPhoto}>
      <div className={styles.countdownTimerPhotoInner}>{lastSeconds === time ? 'Get Ready!' : lastSeconds}</div>
    </div>
  );
};

CountdownTimerPhoto.defaultProps = {
  onComplete: () => {},
};

export default CountdownTimerPhoto;
