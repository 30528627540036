import moment from 'moment';

export const isUndefined = (val: any) => typeof val === 'undefined';

export const isFunction = (val: any) => Object.prototype.toString.apply(val) === '[object Function]';

export const convertDateToAge = date => {
  return moment(date)
    .subtract(18, 'years')
    .format();
};

export const isMinor = (minimumAdultAge, customerAge) => {
  return moment().diff(moment(customerAge), 'years') < minimumAdultAge;
};

export const camelCase = (str: any) => {
  return str.replace(/-a([a-z])/g, ($0, $1) => $1.toUpperCase()).replace('-', '');
};

export const millisecondsToMinutes = (milliseconds: number) => {
  return moment.duration(milliseconds, 'milliseconds').asMinutes();
};

export const minutesToMilliseconds = (minutes: number) => {
  return moment.duration(minutes, 'minutes').asMilliseconds();
};

export const getPrice = (price: any, priceLevel: number) => {
  let priceItem: string;
  switch (priceLevel) {
    case 1:
      priceItem = price?.price1?.toFixed(2);
      break;
    case 2:
      priceItem = price?.price2?.toFixed(2);
      break;
    case 3:
      priceItem = price?.price3?.toFixed(2);
      break;
    case 4:
      priceItem = price?.price4?.toFixed(2);
      break;
    case 5:
      priceItem = price?.price5?.toFixed(2);
      break;
    default:
      priceItem = price?.price1?.toFixed(2);
      break;
  }

  if (priceItem === '0.00') {
    return price?.price1?.toFixed(2);
  }

  return priceItem;
};

export const sortByCreatedDate = (a, b) => {
  const dateA = new Date(a.sortByCreatedDate).getTime();
  const dateB = new Date(b.sortByCreatedDate).getTime();
  return dateA > dateB ? 1 : -1;
};

export const arrayChunk = (array, size) => {
  const newArray = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    newArray.push(chunk);
  }
  return newArray;
};

export const isIpAddress = (address: string) =>
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    address,
  );

export const detectScrollDisable = () => document.body.scrollHeight === document.body.offsetHeight;

export const findLastVisitedGuest = (guests: any[]) => {
  let lastVisitedArr = { date: moment(guests[0].lastVisited, 'YYYY-MM-DD HH:mm:ss'), index: 0 };
  guests.forEach((item, idx) => {
    const momentLastVisited = moment(item.lastVisited, 'YYYY-MM-DD  HH:mm:ss');
    if (lastVisitedArr.date < momentLastVisited) {
      lastVisitedArr = { date: momentLastVisited, index: idx };
    }
  });
  return guests[lastVisitedArr.index];
};
