import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import MemberCard from '../MemberCard';
import Button from '../Button';
import Title from '../Title';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import AddGuestButton from '../AddGuestButton';

interface ChooseParticipantProps {
  isOpen: boolean;
  members: any[];
  cancelClickHandler: Function;
  doneClickHandler: Function;
  addGuestHandler: () => void;
  loading?: boolean;
}

const ChooseParticipant = ({
  isOpen,
  members,
  cancelClickHandler,
  addGuestHandler,
  doneClickHandler,
  loading = false,
}: ChooseParticipantProps) => {
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const {
    purchaseFlowStore: { unavailableCustomers },
  } = useSelector(state => state);

  useEffect(() => {
    let index = 0;
    if (unavailableCustomers) {
      index = members.findIndex(item => unavailableCustomers.indexOf(item.custId) === -1);
    }
    if (index !== -1) {
      setSelectedParticipants([...selectedParticipants, members[index].custId]);
    }
    handleResizeDetailsInner();
    window.addEventListener('resize', handleResizeDetailsInner);
    return () => {
      window.removeEventListener('resize', handleResizeDetailsInner);
    };
  }, []);

  const handleResizeDetailsInner = () => {
    // const heightChooseMembers = document.getElementById('chooseMembers').offsetHeight;
    // const heightChooseMembersHeader = document.getElementById('chooseMembersHeader').offsetHeight;
    // const heightChooseMembersFooter = document.getElementById('chooseMembersFooter').offsetHeight;
    // setHeightContent(heightChooseMembers - (heightChooseMembersHeader + heightChooseMembersFooter));
  };

  const chooseParticipant = item => {
    if (!unavailableCustomers || unavailableCustomers.indexOf(item.custId) === -1) {
      if (selectedParticipants.includes(item.custId)) {
        const selectedParticipantsCopy = selectedParticipants;
        const index = selectedParticipantsCopy.indexOf(item.custId);
        selectedParticipantsCopy.splice(index, 1);
        return setSelectedParticipants([...selectedParticipantsCopy]);
      }
      setSelectedParticipants([...selectedParticipants, item.custId]);
    }
  };

  const doneClickHandlerWrap = () => {
    const selectedMembers = [];
    members.map(item => {
      if (selectedParticipants.includes(item.custId)) {
        selectedMembers.push(item);
      }
    });
    return doneClickHandler(selectedMembers);
  };

  return (
    <Modal isOpen={isOpen} closeButtonHandler={() => cancelClickHandler()}>
      <div id="chooseMembers" className={styles.chooseParticipant}>
        {/*<div id="chooseMembersHeader" className={styles.chooseParticipantHeader}>*/}
        {/*  <h2>Who are you purchasing for?</h2>*/}
        {/*</div>*/}

        <Title message="Who are you purchasing for?" />

        <div className={styles.chooseParticipantContent}>
          <AddGuestButton handleClick={addGuestHandler} />
          <div className={styles.membersListContainer}>
            {/*<div className={styles.membersListContainerInner}>*/}
            {members.map((item, index) => (
              <MemberCard
                key={index}
                handleClick={() => chooseParticipant(item)}
                isActive={selectedParticipants.includes(item.custId)}
                custId={item.custId}
                unavailable={unavailableCustomers && unavailableCustomers.indexOf(item.custId) !== -1}
                firstName={item.fName}
                lastName={item.lName}
                birthday={item.birthDate}
                gender={item.gender}
              />
            ))}
            {/*</div>*/}
          </div>
        </div>
        <div id="chooseMembersFooter" className={styles.navigation}>
          <div className={styles.columnLeft}>
            <Button handleClick={() => cancelClickHandler()} customClass={styles.whiteButton} theme="white">
              Cancel
            </Button>
          </div>
          <div className={styles.columnRight}>
            <Button
              handleClick={doneClickHandlerWrap}
              disabled={loading || !selectedParticipants.length}
              customClass={styles.redButton}
              theme="red"
            >
              Continue
            </Button>
          </div>
          <div className={styles.navigationFilter} />
        </div>
      </div>
    </Modal>
  );
};

ChooseParticipant.defaultProps = {
  isOpen: false,
  members: [],
};

export default ChooseParticipant;
