import React from 'react';
import styles from './ProductGrid.module.scss';

interface ProductGridProps {
  children: React.ReactNode;
}

const ProductGrid = ({ children }: ProductGridProps) => {
  return (
    <div className={styles.productGrid}>
      {children}
    </div>
  );
};

export default ProductGrid;
