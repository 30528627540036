import { select, takeLatest, put, all, call } from 'redux-saga/effects';
import { deleteCart } from '../../../api';
import { END_CUSTOMER_SESSION } from '../../General/actions';
import { removeCartActionSuccess, removeCartActionFailure } from '../actions';

export function* removeCartWorker() {
  try {
    const {
      cartStore: { checkId, removeLineItems },
    } = yield select(store => store);

    if (!checkId) {
      return;
    }
    if (removeLineItems) {
      yield call(deleteCart, { checkId });
    }
    yield put(removeCartActionSuccess());
  } catch (err) {
    yield put(removeCartActionFailure());
  }
}

export default function* removeCartSaga() {
  yield all([yield takeLatest(END_CUSTOMER_SESSION, removeCartWorker)]);
}
