import styles from './styles.module.scss';
import cn from 'classnames';
import React, { useState } from 'react';

interface Props {
  value: string | number;
  placeholder?: string;
  selectDropdownItem: (value: any) => void;
  items: any;
}

const Dropdown = ({ value, placeholder, items, selectDropdownItem }: Props) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const toggleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const selectItem = (value: any) => {
    setIsOpenDropdown(false);
    selectDropdownItem(value);
  };

  return (
    <div className={styles.dropdown} tabIndex={0} onBlur={() => setIsOpenDropdown(false)}>
      <div
        className={cn(styles.dropdownPlaceholder, { [styles.dropdownPlaceholderIsOpen]: isOpenDropdown })}
        onClick={toggleDropdown}
      >
        {value ? value : placeholder}
      </div>
      <div className={cn(styles.dropdownList, { [styles.dropdownListIsOpen]: isOpenDropdown })}>
        {items.map((item, idx) => (
          <li
            key={idx}
            className={cn(styles.dropdownListItem, { [styles.dropdownListItemSelected]: item.value === value })}
            onClick={() => selectItem(item.value)}
          >
            {item.title}
          </li>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
