export const MEMBERSHIP_TYPES = {
  REQUEST: 'membership/REQUEST',
  SUCCESS: 'membership/SUCCESS',
  FAILED: 'membership/FAILED',
  RESET: 'membership/RESET',
};

export const membershipRequestAction = (payload: any) => ({
  payload,
  type: MEMBERSHIP_TYPES.REQUEST,
});

export const membershipSuccessAction = (payload: any) => ({
  payload,
  type: MEMBERSHIP_TYPES.SUCCESS,
});

export const membershipFailedAction = (payload: any) => ({
  payload,
  type: MEMBERSHIP_TYPES.FAILED,
});

export const membershipResetAction = () => ({
  type: MEMBERSHIP_TYPES.RESET,
});
