import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createPaymentAction } from '../../store/Payment/actions';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import PaymentIcon from '../../assets/icons/payment-Icon.svg';
import styles from './styles.module.scss';

const PaymentCloverScreen = () => {
  const dispatch = useDispatch();
  const { cartStore } = useSelector(state => state);
  useEffect(() => {
    dispatch(createPaymentAction());
  },        []);

  return (
    <Fragment>
      <ContentContainer theme="red">
        <HeaderPanel />
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <div>
              <div className={styles.subtotal}>
                <span>
                  Subtotal: <span className={styles.value}>${cartStore.checkSubtotal.toFixed(2)}</span>
                </span>
              </div>
              <div className={styles.tax}>
                <span>
                  Tax: <span className={styles.value}>${cartStore.checkTax.toFixed(2)}</span>
                </span>
              </div>
              <div className={styles.balance}>
                <span>
                  Balance: <span className={styles.value}>${cartStore.checkTotal.toFixed(2)}</span>
                </span>
              </div>
              <h3 className={styles.description}>
                Use the card reader to insert chip or swipe
                <br /> card to complete purchase
              </h3>
              <div className={styles.icons}>
                <img src={PaymentIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default PaymentCloverScreen;
