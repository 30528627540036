import { MEMBERSHIP_TYPES } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  memberships: null,
  customers: null,
  product: null,
  isLoading: false,
};

export type InitialStateType = typeof initialState;

export default function membershipReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case MEMBERSHIP_TYPES.REQUEST:
      return payload;
    case MEMBERSHIP_TYPES.SUCCESS:
      return payload;
    case MEMBERSHIP_TYPES.FAILED:
      return {
        ...state,
      };
    case MEMBERSHIP_TYPES.RESET:
      return initialState;
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
