import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Api from '../../api';
import { history } from '../../App';
import { sha256 } from '../../utils/bcrypt';
import { startResetPinAction } from '../../store/Customer/actions';
import IdleMonitor from '../../services/IdleMonitor';
import Button from '../../components/Button';
import PhoneKeypad from '../../components/PhoneKeypad';
import styles from './styles.module.scss';
import ContentContainer from '../../components/ContentContainer';
import { ROUTES } from '../../constants';
import WarningAlert from '../../components/WarningAlert';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import cn from 'classnames';
import HeaderPanel from '../../components/HeaderPanel';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

type formType = {
  [key: string]: string;
};

const LogInSecondStepScreen = () => {
  const dispatch = useDispatch();
  const { customerStore, resetPinStore } = useSelector(state => state);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [activeField, setActiveField] = useState('pin');
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({
    pin: '',
  });
  const [pinErrorMessage, setPinErrorMessage] = useState('');
  const [pinExists, setPinExists] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!customerStore.pinExists) {
      setPinExists(false);
    }
  }, []);

  const phoneKeypadHandler = (newValueOfField: string) => {
    if (newValueOfField.length <= 4) {
      setPinErrorMessage('');
      setFieldsOfForm({ ...fieldsOfForm, [activeField]: newValueOfField });
    }
  };

  const logIn = async () => {
    if (!validateFieldsOfForm()) return;
    try {
      setLoading(true);
      const hashPin = await sha256(fieldsOfForm.pin);
      const { data } = await Api.verifyPinCustomer(customerStore.custId, hashPin);
      if (data.success) {
        setLoading(false);
        history.replace(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
        return;
      }
      fieldsOfForm.pin = '';
      setLoading(false);
      setPinErrorMessage(data.message);
      return;
    } catch (e) {
      console.log(e);
    }
  };

  const resetPin = () => {
    dispatch(startResetPinAction({ customerData: customerStore }));
  };

  const validateFieldsOfForm = () => {
    if (fieldsOfForm.pin === '') {
      setPinErrorMessage('Enter a 4 digit pin');
      return false;
    }
    setPinErrorMessage('');

    return true;
  };

  const goToCreateNewPin = () => {
    setPinExists(true);
    history.push(ROUTES.LOG_IN_ANOTHER_WAY_ENTER_PIN, { prevRoute: 'LogInSecondStepScreen' });
  };

  return (
    <Fragment>
      <ContentContainer loading={loading || resetPinStore.loading || customerStore.loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div className={styles.rowMargin}>
              <TextFieldWithLabel
                type="password"
                value={fieldsOfForm.pin}
                placeholder=""
                inputMode={nativeKeyboardState}
                label="Enter 4 Digit Pin"
                invalid={pinErrorMessage.length > 0}
                errorMessage={pinErrorMessage}
                onFocus={() => setActiveField('pin')}
              />
            </div>
            <div className={styles.rowMargin}>
              <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler} />
            </div>

            <div className={styles.navigation}>
              <Button
                theme={'white'}
                handleClick={resetPin}
                customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
              >
                Forgot Pin
              </Button>
              <div className={styles.navigationFilter} />
              <Button
                theme="red"
                handleClick={logIn}
                customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </ContentContainer>
      <WarningAlert
        isOpen={!pinExists}
        title="Almost There!"
        description="We’ve found your account, please create a PIN before proceeding"
        handleClick={goToCreateNewPin}
      />
      <IdleMonitor />
    </Fragment>
  );
};

export default LogInSecondStepScreen;
