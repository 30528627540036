import React from 'react';
import QRCode from 'qrcode';
import styles from './styles.module.scss';

interface Props {
  text?: string;
}

const Confirm = ({ text }: Props) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    QRCode.toCanvas(canvasRef.current, text);
  },              [text]);

  return (
    <div className={styles.qr}>
      <canvas ref={canvasRef} className={styles.canvas}/>
    </div>
  );
};

export default Confirm;
