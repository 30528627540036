const GENDER = [
  {
    title: 'Male',
    value: '1',
  },
  {
    title: 'Female',
    value: '2',
  },
  {
    title: 'Other',
    value: '0',
  },
];

export default GENDER;
