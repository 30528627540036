import { useState, useEffect } from 'react';

interface CartItemsListHeightProps {
  isSticky: boolean;
}

const useCartItemsListHeight = ({ isSticky = false }: CartItemsListHeightProps) => {
  const [cartItemsListHeight, setCartItemsListHeight] = useState(0);
  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const headerPanelHeight = isSticky ? 0 : document.getElementById('headerPanel').offsetHeight;
      const cartTitleHeight = document.getElementById('cartTitle').offsetHeight;
      const cartTotalAmountHeight = document.getElementById('cartTotalAmount').offsetHeight;
      setCartItemsListHeight(windowHeight - (headerPanelHeight + cartTitleHeight + cartTotalAmountHeight));
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [isSticky]);
  return cartItemsListHeight;
};

export default useCartItemsListHeight;
