export const SOURCES_TYPES = {
  REQUEST: 'source/GET_SOURCES_REQUEST',
  SUCCESS: 'source/GET_SOURCES_SUCCESS',
  FAILURE: 'source/GET_SOURCES_FAILURE',
};

export const getSourcesAction = () => ({
  type: SOURCES_TYPES.REQUEST,
});

export const getSourcesActionSuccess = (payload: any) => ({
  type: SOURCES_TYPES.SUCCESS,
  payload,
});

export const getSourcesActionFailure = () => ({
  type: SOURCES_TYPES.FAILURE,
});
