import { AxiosInstance } from 'axios';

const updateTokenInterceptor = (instance: AxiosInstance, originalRequest: any, requestArray: any) => {
  return instance
    .post('auth', {
      url: `https://${localStorage.getItem('api_server')}.com/CoreAPI/`,
      username: `${localStorage.getItem('api_user_name')}`,
      password: `${localStorage.getItem('api_password')}`,
    })
    .then(res => {
      const response = res.data;
      localStorage.setItem('token', response.token);
      if (requestArray.length !== 0) {
        requestArray.forEach((failedResponse: { headers: { [x: string]: string; Authorization: string } }) => {
          try {
            failedResponse.headers.Authorization = `Bearer ${response.token}`;
            failedResponse.headers['Content-Type'] = 'application/json';
            instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            instance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
          } catch (e) {
            console.log(e);
          }
        });
      }
      return instance(originalRequest);
    })
    .catch((err): any => {
      console.log(err);
    });
};

export default updateTokenInterceptor;
