import React from 'react';
import LogoLight from '../../assets/images/logo.svg';
import LogoDark from '../../assets/images/logo-dark.svg';
import styles from './styles.module.scss';

interface LogoProps {
  width?: string;
  theme?: string;
}

const Logo = ({ theme= 'light', width= 'auto' }: LogoProps) => (
  <img src={theme === 'light' ? LogoLight : LogoDark} className={styles.logo} width={width} alt="" />
);

export default Logo;
