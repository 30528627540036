export const CREATE_PAY_BY_LINK_TYPES = {
  REQUEST: 'payByLink/CREATE_PAY_BY_LINK_REQUEST',
  SUCCESS: 'payByLink/CREATE_PAY_BY_LINK_SUCCESS',
  FAILURE: 'payByLink/CREATE_PAY_BY_LINK_FAILURE',
};

export const createPayByLinkAction = () => ({
  type: CREATE_PAY_BY_LINK_TYPES.REQUEST,
});

export const createPayByLinkSuccess = (payload: any) => ({
  type: CREATE_PAY_BY_LINK_TYPES.SUCCESS,
  payload,
});

export const createPayByLinkFailure = () => ({
  type: CREATE_PAY_BY_LINK_TYPES.FAILURE,
});
