import { takeLatest, all, call, put } from 'redux-saga/effects';
import { START_CUSTOMER_SESSION } from '../actions';
import { ROUTES, TERMINAL_MODES } from '../../../constants';
import { history } from '../../../App';

export function* startCustomerSessionWorker({ payload }: any) {
  const sessionMode = payload;

  switch (sessionMode) {
    case TERMINAL_MODES.default.value:
      history.replace(ROUTES.LOG_IN_FIRST_STEP);
      break;
    case TERMINAL_MODES.food.value:
      history.replace(ROUTES.PRODUCTS);
      break;
    case TERMINAL_MODES.foodAndActivities.value:
      history.replace(ROUTES.LOG_IN_FIRST_STEP);
      break;
    case TERMINAL_MODES.registration.value:
      history.replace(ROUTES.LOG_IN_FIRST_STEP);
      break;
    default:
  }
}

export default function* startPaymentProcessSaga() {
  yield all([
    yield takeLatest(START_CUSTOMER_SESSION, startCustomerSessionWorker),
  ]);
}
