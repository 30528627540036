import React from 'react';
import styles from './styles.module.scss';

interface RadioProps {
  state: boolean;
  label: string;
  handleClick: any;
}

const Radio: React.FC<RadioProps> = ({ state, label, handleClick }: RadioProps) => {
  return (
    <div className={styles.checkboxWrap}>
      <label className={styles.checkboxLabel}>
        <input type="radio" readOnly onClick={handleClick} checked={state} className={styles.checkboxInput} />
        <span className={styles.checkmark} />
        {label}
      </label>
    </div>
  );
};

Radio.defaultProps = {
  state: false,
};

export default Radio;
