import React from 'react';
import styles from './styles.module.scss';
import Modal from '../Modal';
import ProductOptionsSlider from '../ProductOptionsSlider';
import Button from '../Button';
import cn from 'classnames';
import Keyboard from '../Keyboard/KeyboardHOC';
import { useSelector } from 'react-redux';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

interface ProductOptionsProps {
  productTitle?: any;
  onCloseHandler: () => void;
  isOpen?: boolean;
  options: any;
  selectedOptions: any;
  onClickOption: (option: string) => void;
  valueCustomOption: string;
  onChangeCustomOption: any;
  doneProductOptions: any;
}

const ProductOptions = ({
  productTitle,
  onCloseHandler,
  isOpen,
  options,
  selectedOptions,
  onClickOption,
  valueCustomOption,
  onChangeCustomOption,
  doneProductOptions,
}: ProductOptionsProps) => {
  const { terminalSettings } = useSelector(state => state);
  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);

  return (
    <Modal isOpen={isOpen} closeButtonHandler={onCloseHandler}>
      <div
        className={cn(styles.productOptionsContainer, {
          [styles.productOptionsContainerSmall]: terminalSettings.nativeKeyboard,
        })}
      >

        <div className={styles.productOptionsContent}>
          <div className={styles.productOptionsHeader}>
            <h3 className={styles.title}>Customize Your</h3>
            <h2 className={styles.productName}>{productTitle}</h2>
          </div>
          <div className={styles.optionsList}>
            {options && !!options.length && (
              <ProductOptionsSlider numberOfSliders={options.length}>
                {options.length &&
                  options.map((item: string, index) => {
                    const isActive = selectedOptions.includes(item);
                    return (
                      <div key={`${item}_${index}`} className={styles.optionsItem}>
                        <button
                          className={cn(styles.optionsItemBtn, { [styles.optionsItemBtnActive]: isActive })}
                          onClick={() => onClickOption(item)}
                        />
                        <span className={styles.optionsItemTitle}>{item}</span>
                      </div>
                    );
                  })}
              </ProductOptionsSlider>
            )}
          </div>
          <div className={styles.customOptionWrapper}>
            <textarea
              inputMode={nativeKeyboardState ? 'text' : 'none'}
              // readOnly={!nativeKeyboardState}
              maxLength={50}
              value={valueCustomOption}
              onChange={event => onChangeCustomOption(event.target.value)}
            />
          </div>
        </div>
        {terminalSettings.nativeKeyboard && (
          <div className={styles.productOptionButtons}>
            <Button blockLevel size="medium" handleClick={onCloseHandler}>
              Cancel
            </Button>
            <Button theme="red" size="medium" blockLevel handleClick={doneProductOptions}>
              Done
            </Button>
          </div>
        )}
        {!terminalSettings.nativeKeyboard && (
          <div className={styles.keyboard}>
            <Keyboard
              valueOfField={valueCustomOption}
              onChange={onChangeCustomOption}
              hideKeyboard={() => {}}
              handleEnter={() => {}}
            />
            <div className={styles.navigation}>
              <Button blockLevel customClass={styles.navigationButtonCancel} handleClick={onCloseHandler}>
                Cancel
              </Button>
              <Button handleClick={doneProductOptions} customClass={styles.navigationButton} theme="red">
                Continue
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ProductOptions;
