import React from 'react';
import styles from './styles.module.scss';
import TimeSlotGridItem from '../TimeSlotGridItem';
import cn from 'classnames';
import moment from 'moment';

interface TimeSlotsGridRowProps {
  items?: any;
  timeSlotHandler?: any;
  selectedTimeSlots?: any;
  trackNo?: any;
}

const TimeSlotGridRow: React.FC<TimeSlotsGridRowProps> = ({
  items,
  timeSlotHandler,
  selectedTimeSlots,
  trackNo,
}: TimeSlotsGridRowProps) => {
  return (
    <div className={cn(styles.timeSlotsGridRow)}>
      {items.map((timeItem, timeItemIndex) => {
        if (moment().isAfter(timeItem.scheduledTime)) return;
        return (
          <TimeSlotGridItem
            key={timeItemIndex}
            data={timeItem}
            trackNo={trackNo}
            handleClick={timeSlotHandler}
            isActive={timeItem.isActive}
            isSelected={
              !!selectedTimeSlots.find(i => i.scheduledTime === timeItem.scheduledTime && i.trackNo === trackNo)
            }
          />
        );
      })}
    </div>
  );
};

export default TimeSlotGridRow;
