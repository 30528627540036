import { takeLatest, put, all, call } from 'redux-saga/effects';
import { sendResetPinCode, verifyResetCode, updatePinCode } from '../../../api';
import { ROUTES } from '../../../constants';
import { history } from '../../../App';
import {
  START_RESET_PIN_TYPES,
  VERIFY_RESET_CODE_TYPES,
  UPDATE_PIN_TYPES,
  startResetPinActionSuccess,
  startResetPinActionFailure,
  verifyResetCodeActionSuccess,
  verifyResetCodeActionFailure,
  updatePinActionSuccess,
  updatePinActionFailure,
} from '../actions';

function* startResetPinWorker({ payload }: any) {
  try {
    const customerData = payload.customerData;
    yield call(sendResetPinCode, customerData.custId);
    yield put(startResetPinActionSuccess());
    history.push(ROUTES.RESET_PIN);
  } catch (err) {
    yield put(startResetPinActionFailure());
  }
}

function* verifyResetCodeWorker({ payload }: any) {
  try {
    const { data } = yield call(verifyResetCode, payload.custId, payload.resetCode);
    yield put(verifyResetCodeActionSuccess(data));
    history.push(ROUTES.NEW_PIN);
  } catch (err) {
    yield put(verifyResetCodeActionFailure());
  }
}

function* updatePinWorker({ payload }: any) {
  try {
    yield call(updatePinCode, payload.custId, payload.resetCodeId, payload.pin);
    yield put(updatePinActionSuccess());
    history.push(ROUTES.LOG_IN_SECOND_STEP);
  } catch (err) {
    yield put(updatePinActionFailure());
  }
}

export default function* resetPinSaga() {
  yield all([
    yield takeLatest(START_RESET_PIN_TYPES.REQUEST, startResetPinWorker),
    yield takeLatest(VERIFY_RESET_CODE_TYPES.REQUEST, verifyResetCodeWorker),
    yield takeLatest(UPDATE_PIN_TYPES.REQUEST, updatePinWorker),
  ]);
}
