export const START_RESET_PIN_TYPES = {
  REQUEST: 'auth/START_RESET_PIN_REQUEST',
  SUCCESS: 'auth/START_RESET_PIN_SUCCESS',
  FAILURE: 'auth/START_RESET_PIN_FAILURE',
};

export const VERIFY_RESET_CODE_TYPES = {
  REQUEST: 'auth/VERIFY_RESET_CODE_REQUEST',
  SUCCESS: 'auth/VERIFY_RESET_CODE_SUCCESS',
  FAILURE: 'auth/VERIFY_RESET_CODE_FAILURE',
};

export const UPDATE_PIN_TYPES = {
  REQUEST: 'auth/UPDATE_PIN_TYPES_REQUEST',
  SUCCESS: 'auth/UPDATE_PIN_TYPES_SUCCESS',
  FAILURE: 'auth/UPDATE_PIN_TYPES_FAILURE',
};

export const startResetPinAction = (payload: any) => ({
  type: START_RESET_PIN_TYPES.REQUEST,
  payload,
});

export const startResetPinActionSuccess = () => {
  return {
    type: START_RESET_PIN_TYPES.SUCCESS,
  };
};

export const startResetPinActionFailure = () => {
  return {
    type: START_RESET_PIN_TYPES.FAILURE,
  };
};

export const verifyResetCodeAction = (payload: any) => ({
  type: VERIFY_RESET_CODE_TYPES.REQUEST,
  payload,
});

export const verifyResetCodeActionSuccess = (payload: any) => {
  return {
    type: VERIFY_RESET_CODE_TYPES.SUCCESS,
    payload,
  };
};

export const verifyResetCodeActionFailure = () => {
  return {
    type: VERIFY_RESET_CODE_TYPES.FAILURE,
  };
};

export const updatePinAction = (payload: any) => {
  return {
    type: UPDATE_PIN_TYPES.REQUEST,
    payload,
  };
};

export const updatePinActionSuccess = () => {
  return {
    type: UPDATE_PIN_TYPES.SUCCESS,
  };
};

export const updatePinActionFailure = () => {
  return {
    type: UPDATE_PIN_TYPES.FAILURE,
  };
};
