import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateTerminalSettingsAction } from '../../store/Settings/actions';
import { terminalSettingsSelector, nativeKeyboardStateSelector } from '../../store/Settings/selectors';

import { TERMINAL_MODES, PAYMENT_PROCESSORS, ROUTES } from '../../constants';
import * as Api from '../../api';

import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import TextField from '../../components/TextField';
import Select from '../../components/Select';
import Keyboard from '../../components/Keyboard';
import NativeKeyboardToggle from '../../components/NativeKeyboardToggle';
import Clover from '../../components/Clover';
import Button from '../../components/Button';
import fieldValidator from '../../utils/validator';
import Checkbox from '../../components/Checkbox';

import { isRegistrationMode } from '../../utils/detectTerminalMode';
import { isCardConnect, isClover } from '../../utils/detectPeymentProcessor';

import styles from './styles.module.scss';
import { history } from '../../App';
import { isIpAddress } from '../../utils/core';

const TerminalSettingsScreen = () => {
  const dispatch = useDispatch();

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const terminalSettings = useSelector(terminalSettingsSelector);

  const [activeField, setActiveField] = React.useState(null);
  const [kioskLicenses, setKioskLicenses] = React.useState([]);
  const [isActiveKioskLicenses, setIsActiveKioskLicenses] = React.useState(null);
  const [terminalNumber, setTerminalNumber] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [fieldsOfForm, setFieldsOfForm] = React.useState<any>({
    mode: TERMINAL_MODES[terminalSettings.mode],
    paymentProcessor: PAYMENT_PROCESSORS[terminalSettings.paymentProcessor],
    terminalNumber: terminalSettings.terminalNumber,
    sessionTimeout: terminalSettings.sessionTimeout,
    text2pay: terminalSettings.text2pay,
    text2payExpirationTime: 1,
    password: terminalSettings.password,
    ip: terminalSettings.ip,
  });

  React.useEffect(() => {
    (async () => {
      await fetchKioskLicense();
    })();
  }, []);

  const fetchKioskLicense = async () => {
    try {
      const {
        data: { selectedLicense },
      } = await Api.fetchKioskLicense();
      const licensesList = () => {
        const arr = [];
        for (let i = 1; i <= selectedLicense.id; i++) {
          arr.push({ id: i, label: `${i}` });
        }
        return arr;
      };
      setKioskLicenses(licensesList());
      if (fieldsOfForm.terminalNumber) {
        const isAcive = licensesList().filter(item => item.id === fieldsOfForm.terminalNumber);
        setIsActiveKioskLicenses(isAcive[0]);
        await setTerminalPayProcessor(isAcive[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setTerminalPayProcessor = async item => {
    try {
      // const settingsName = 'CardConnectURL';
      // let controlPanelSettings = [];
      // if(fieldsOfForm.kioskPaymentProcessor.value !== 'clover') {
      //   const { data: controlPanelSettingsOnline } = await Api.fetchControlPanelSettings(settingsName, 'MainEngine');
      //   controlPanelSettings = [...controlPanelSettingsOnline];
      // } else {
      //   const { data: controlPanelSettingsClover } = await Api.fetchControlPanelSettings(settingsName, `SSK${item.id}`);
      //   controlPanelSettings = [...controlPanelSettingsClover];
      // }
      // if(controlPanelSettings[0]) {
      //   const cardPresentProcessor = controlPanelSettings[0].settingValue;
      //   setFieldsOfForm({
      //     ...fieldsOfForm,
      //     ['terminalPayProcessor']: cardPresentProcessor.charAt(0).toUpperCase() + cardPresentProcessor.slice(1),
      //   });
      // }
    } catch (e) {
      throw new Error(e);
    }
  };

  const onKeyboardPress = (newFieldValue: string, fieldName) => {
    setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
  };

  const onChangeInputValue = (e: any) => {
    if (activeField === 'sessionTimeout') {
      const value = Number(e.target.value);
      if (value <= 999 || e.target.value === '') {
        setFieldsOfForm({ ...fieldsOfForm, [activeField]: e.target.value });
      }
    } else {
      setFieldsOfForm({ ...fieldsOfForm, [activeField]: e.target.value });
    }
  };

  const text2payAction = () => {
    setFieldsOfForm({ ...fieldsOfForm, ['text2pay']: !fieldsOfForm.text2pay });
  };

  const kioskNumberHandler = (item: any) => {
    setTerminalNumber(item);
    setFieldsOfForm({ ...fieldsOfForm, ['terminalNumber']: item.id });
  };

  const onChangeMode = (item: any) => {
    setFieldsOfForm({
      ...fieldsOfForm,
      mode: item,
      paymentProcessor: !isRegistrationMode(item.value) ? fieldsOfForm.paymentProcessor : null,
      // isKioskPaymentProcessorEnabled: item.value !== 'registration',

      text2pay: !isRegistrationMode(item.value) ? fieldsOfForm.text2pay : false,
      // isText2PayEnabled: item.value !== 'registration',
      // isTerminalNumberEnabled: item.value !== 'registration',
    });
    // if (item.value === 'registration') {
    //   setIsActiveKioskLicenses(null);
    // }
  };

  const onChangePaymentProcessor = (item: any) => {
    setFieldsOfForm({
      ...fieldsOfForm,
      paymentProcessor: item,
      text2pay: isCardConnect(item.value) ? fieldsOfForm.text2pay : false,
    });
  };

  const updateSettings = async () => {
    try {
      await fieldValidator('terminalNumber', fieldsOfForm.terminalNumber, true, false);
      await fieldValidator('sessionTimeout', fieldsOfForm.sessionTimeout, true, false);
      await fieldValidator('password', fieldsOfForm.password, true, false);
      if (isClover(fieldsOfForm.paymentProcessor?.value)) {
        await fieldValidator('ip', fieldsOfForm.ip, true, false);
      }
      dispatch(updateTerminalSettingsAction(fieldsOfForm));
    } catch (e) {
      setError(e.message);
    }
  };

  React.useEffect(() => {
    if (isActiveKioskLicenses) {
      const kioskLicensesFilter = kioskLicenses.filter((item: any) => item.id === isActiveKioskLicenses.id)[0];
      setTerminalNumber(kioskLicensesFilter);
    }
  }, [isActiveKioskLicenses]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <ContentContainer loading={terminalSettings.loading} theme="red">
        <HeaderPanel theme="dark" shadow />
        <div className={styles.keyboardCheckbox}>
          <NativeKeyboardToggle />
        </div>
        <div className={styles.contentInner}>
          <div className={styles.contentColumn}>
            <h1 className={styles.title}>Kiosk Settings</h1>
            <div className={styles.formContainer}>
              <div>
                <div className={styles.spaceBetweenRow}>
                  <div className={styles.fieldLabel}>Kiosk Mode</div>
                  <Select options={Object.values(TERMINAL_MODES)} onChange={onChangeMode} value={fieldsOfForm.mode} />
                </div>
                <div className={styles.spaceBetweenRow}>
                  <div className={styles.fieldLabel}>Payment Processor</div>
                  <Select
                    options={Object.values(PAYMENT_PROCESSORS)}
                    onChange={onChangePaymentProcessor}
                    value={fieldsOfForm.paymentProcessor}
                    isDisabled={isRegistrationMode(fieldsOfForm.mode.value)}
                  />
                </div>
                <div className={styles.spaceBetweenRow}>
                  <div className={styles.fieldLabel}>Clover IP Address</div>
                  <div className={styles.clover}>
                    <div className={styles.cloverInput}>
                      <TextField
                        type="text"
                        inputMode={nativeKeyboardState}
                        value={fieldsOfForm.ip}
                        placeholder="IP Address"
                        isActive={activeField === 'ip'}
                        invalid={false}
                        errorMessage={''}
                        disabled={!isClover(fieldsOfForm.paymentProcessor?.value)}
                        theme="themeTransparent"
                        onChange={onChangeInputValue}
                        onFocus={() => setActiveField('ip')}
                        customClass={styles.smallField}
                      />
                    </div>
                    <Clover
                      disabled={!(isClover(fieldsOfForm.paymentProcessor?.value) && isIpAddress(fieldsOfForm.ip))}
                      terminalNumber={fieldsOfForm.terminalNumber}
                    />
                  </div>
                </div>
                <div className={styles.spaceBetweenRow}>
                  <div className={styles.fieldLabel}>Text2Pay</div>
                  <Checkbox
                    state={fieldsOfForm.text2pay}
                    label="Enable Text2Pay?"
                    disabled={!isCardConnect(fieldsOfForm.paymentProcessor?.value)}
                    handleClick={text2payAction}
                    theme="white"
                  />
                </div>
              </div>
              <div>
                <div className={styles.spaceBetweenRow}>
                  <div className={styles.fieldLabel}>Kiosk Password</div>
                  <TextField
                    type="password"
                    inputMode={nativeKeyboardState}
                    value={fieldsOfForm.password}
                    placeholder="Password"
                    isActive={activeField === 'password'}
                    invalid={false}
                    errorMessage={''}
                    theme="themeTransparent"
                    onChange={onChangeInputValue}
                    onFocus={() => setActiveField('password')}
                    customClass={styles.smallField}
                  />
                </div>
                <div className={styles.spaceBetweenRow}>
                  <div className={styles.fieldLabel}>Terminal Number</div>
                  <Select options={kioskLicenses} onChange={kioskNumberHandler} value={terminalNumber} />
                </div>
                <div className={styles.spaceBetweenRow}>
                  <div className={styles.fieldLabel}>Session Timeout (Minutes)</div>
                  <TextField
                    type="text"
                    inputMode={nativeKeyboardState}
                    value={fieldsOfForm.sessionTimeout}
                    placeholder="Minutes to session timeout"
                    isActive={activeField === 'sessionTimeout'}
                    invalid={false}
                    errorMessage={''}
                    maxLength={3}
                    theme="themeTransparent"
                    onChange={onChangeInputValue}
                    onFocus={() => setActiveField('sessionTimeout')}
                    customClass={styles.smallField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!nativeKeyboardState && (
          <div className={styles.keyboard}>
            <Keyboard
              fieldName={activeField}
              valueOfField={fieldsOfForm[activeField]}
              onChange={onKeyboardPress}
              hideKeyboard={() => {}}
              handleEnter={() => {}}
            />
          </div>
        )}

        <div className={styles.navigation}>
          <Button
            theme="white"
            handleClick={() => history.replace(ROUTES.SETTINGS_API)}
            customClass={styles.cancelButton}
          >
            Back
          </Button>
          {error && <div className={styles.error}>{error}</div>}
          <Button theme="red" handleClick={updateSettings} customClass={styles.saveButton}>
            Save
          </Button>
        </div>
      </ContentContainer>
    </>
  );
};

export default TerminalSettingsScreen;
