import React from 'react';
import ReactModal from 'react-modal';
import styles from './styles.module.scss';

interface ModalProps {
  isOpen?: boolean;
  closeButtonHandler?: () => void;
  children: React.ReactNode;
  zIndex?: number;
}

ReactModal.setAppElement('#root');

const Modal = ({ isOpen, closeButtonHandler = () => {}, children, zIndex }: ModalProps) => {
  return (
    <>
      {isOpen && (
        <ReactModal
          isOpen={isOpen}
          className={styles.Modal}
          overlayClassName={styles.Overlay}
          onRequestClose={closeButtonHandler}
          style={{
            overlay: {
              zIndex,
            },
          }}
        >
          {children}
        </ReactModal>
      )}
    </>
  );
};

Modal.defaultProps = {
  showCloseButton: false,
  closeButtonHandler: () => {},
  zIndex: 100,
};

export default Modal;
