const ROUTES = {
  ROOT: '/self-serve-kiosk',
  LOG_IN_FIRST_STEP: '/log-in-first-step',
  LOG_IN_SECOND_STEP: '/log-in-second-step',
  SIGN_IN: '/sign-in',
  NEW_PIN: '/new-pin',
  SIGN_UP_FIRST_STEP: '/sign-up-first-step',
  SIGN_UP: '/sign-up',
  NEW_GUEST: '/new-guest',
  WAIVER_AGREEMENT_ADULT: '/waiver-agreement-adult',
  WAIVER_AGREEMENT_MINOR: '/waiver-agreement-minor',
  ENFORCE_WAIVER_AGREEMENT_ADULT: '/enforce-waiver-agreement-adult',
  ENFORCE_WAIVER_AGREEMENT_MINOR: '/enforce-waiver-agreement-minor',
  REVIEW_OF_NEW_CUSTOMERS: '/review-of-new-customers',
  NEW_SUB_CUSTOMER: '/new-sub-customer',
  RESET_PIN: '/reset-pin',
  UPDATE_PIN: '/update-pin',
  WAIVER: '/waiver',
  LOG_IN_ANOTHER_WAY: '/log-in-another-way',
  LOG_IN_ANOTHER_WAY_ENTER_PHONE: '/log-in-another-way-enter-phone',
  LOG_IN_ANOTHER_WAY_ENTER_PIN: '/log-in-another-way-enter-pin',
  LOGIN_SETTINGS: '/login-settings',
  SETTINGS: '/settings',
  SETTINGS_API: '/settings-api',
  SETTINGS_KIOSK: '/settings-kiosk',
  MODE_SELECTION: '/mode-selection',
  MAIN_FOOD_MODE: '/main-food-mode',
  MAIN_FOOD_AND_ACTIVITIES_MODE: '/main-food-and-activities-mode',
  MAIN_REGISTRATION_MODE: '/main-registration-mode',
  NAVIGATION_FOOD_AND_ACTIVITIES: '/navigation-food-and-activities',
  PROFILE: '/profile',
  EDIT_PROFILE: '/edit-profile',
  PRODUCTS: '/products',
  PAYMENT_CLOVER: '/payment-clover',
  PAYMENT_CLOVER_PROCESSING: '/payment-clover-processing',
  PAYMENT_STRIPE: '/payment-stripe',
  PAYMENT_CARD_CONNECT: '/payment-card-connect',
  TEXT_2_PAY: '/text-2-pay',
  PAYMENT_ONLINE: '/payment-online',
  PAYMENT_SUCCESS: '/payment-success',
  PAYMENT_ERROR: '/payment-error',
  PAYMENT_FAILURE: '/payment-failure',
  EDIT_NEW_GUEST: '/edit-new-guest',
};

export default ROUTES;
