import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface ContentContainerProps {
  children: React.ReactNode;
  loading?: boolean;
  theme?: string;
  bgColor?: string;
}

const ContentContainer: React.FC<ContentContainerProps> = ({ children, loading = false, theme = 'default', bgColor = '#F2F2F2' }: ContentContainerProps) => {
  return (
    <div
      className={cn(styles.container, styles[`${theme}Theme`])}
      style={{ backgroundColor: bgColor }}>
      {children}
      {loading && (
        <div className={styles.loaderWrapper}>
          <div className={styles.loader} />
        </div>
      )}
    </div>
  );
};

export default ContentContainer;
