const COUNTRIES = [
  {
    value: 'Afghanistan',
    name: 'Afghanestan',
  },
  {
    value: 'Albania',
    name: 'Shqiperia',
  },
  {
    value: 'Algeria',
    name: "Al Jaza'ir",
  },
  {
    value: 'Andorra',
    name: 'Andorra',
  },
  {
    value: 'Angola',
    name: 'Angola',
  },
  {
    value: 'Antigua and Barbuda',
    name: 'Antigua and Barbuda',
  },
  {
    value: 'Argentina',
    name: 'Argentina',
  },
  {
    value: 'Armenia',
    name: 'Hayastan',
  },
  {
    value: 'Australia',
    name: 'Australia',
  },
  {
    value: 'Austria',
    name: 'Oesterreich',
  },
  {
    value: 'Azerbaijan',
    name: 'Azarbaycan Respublikasi',
  },
  {
    value: 'The Bahamas',
    name: 'The Bahamas',
  },
  {
    value: 'Bahrain',
    name: 'Al Bahrayn',
  },
  {
    value: 'Bangladesh',
    name: 'Bangladesh',
  },
  {
    value: 'Barbados',
    name: 'Barbados',
  },
  {
    value: 'Belarus',
    name: 'Byelarus',
  },
  {
    value: 'Belgium',
    name: 'Belgie',
  },
  {
    value: 'Belize',
    name: 'Belice',
  },
  {
    value: 'Benin',
    name: 'Benin',
  },
  {
    value: 'Bhutan',
    name: 'Drukyul',
  },
  {
    value: 'Bolivia',
    name: 'Bolivia',
  },
  {
    value: 'Bosnia and Herzegovina',
    name: 'Bosna i Hercegovina',
  },
  {
    value: 'Botswana',
    name: 'Botswana',
  },
  {
    value: 'Brazil',
    name: 'Brasil',
  },
  {
    value: 'Brunei',
    name: 'Brunei',
  },
  {
    value: 'Bulgaria',
    name: 'Republika Bulgariya',
  },
  {
    value: 'Burkina Faso',
    name: 'Burkina Faso',
  },
  {
    value: 'Burundi',
    name: 'Burundi',
  },
  {
    value: 'Cambodia',
    name: 'Kampuchea',
  },
  {
    value: 'Cameroon',
    name: 'Cameroon',
  },
  {
    value: 'Canada',
    name: 'Canada',
  },
  {
    value: 'Cape Verde',
    name: 'Cabo Verde',
  },
  {
    value: 'Central African Republic',
    name: 'Republique Centrafricaine',
  },
  {
    value: 'Chad',
    name: 'Tchad',
  },
  {
    value: 'Chile',
    name: 'Chile',
  },
  {
    value: 'China',
    name: 'China',
  },
  {
    value: 'Colombia',
    name: 'Colombia',
  },
  {
    value: 'Comoros',
    name: 'Comores',
  },
  {
    value: 'Congo',
    name: 'Congo',
  },
  {
    value: 'Costa Rica',
    name: 'Costa Rica',
  },
  {
    value: "Cote d'Ivoire",
    name: "Cote d'Ivoire",
  },
  {
    value: 'Croatia',
    name: 'Hrvatska',
  },
  {
    value: 'Cuba',
    name: 'Cuba',
  },
  {
    value: 'Cyprus',
    name: 'Kypros',
  },
  {
    value: 'Czech Republic',
    name: 'Ceska Republika',
  },
  {
    value: 'Denmark',
    name: 'Danmark',
  },
  {
    value: 'Djibouti',
    name: 'Djibouti',
  },
  {
    value: 'Dominica',
    name: 'Dominica',
  },
  {
    value: 'Dominican Republic',
    name: 'Republica Dominicana',
  },
  {
    value: 'Ecuador',
    name: 'Ecuador',
  },
  {
    value: 'Egypt',
    name: 'Misr',
  },
  {
    value: 'El Salvador',
    name: 'El Salvador',
  },
  {
    value: 'Equatorial Guinea',
    name: 'Guinea Ecuatorial',
  },
  {
    value: 'Eritrea',
    name: 'Ertra',
  },
  {
    value: 'Estonia',
    name: 'Eesti',
  },
  {
    value: 'Ethiopia',
    name: "YeItyop'iya",
  },
  {
    value: 'Fiji',
    name: 'Fiji',
  },
  {
    value: 'Finland',
    name: 'Suomi',
  },
  {
    value: 'France',
    name: 'France or Republique Francaise',
  },
  {
    value: 'Gabon',
    name: 'Gabon',
  },
  {
    value: 'The Gambia',
    name: 'The Gambia',
  },
  {
    value: 'Georgia',
    name: "Sak'art'velo",
  },
  {
    value: 'Germany',
    name: 'Deutschland',
  },
  {
    value: 'Ghana',
    name: 'Ghana',
  },
  {
    value: 'Greece',
    name: 'Ellas',
  },
  {
    value: 'Grenada',
    name: 'Grenada',
  },
  {
    value: 'Guatemala',
    name: 'Guatemala',
  },
  {
    value: 'Guinea',
    name: 'Guinee',
  },
  {
    value: 'Guinea-Bissau',
    name: 'Guine-Bissau',
  },
  {
    value: 'Guyana',
    name: 'Guyana',
  },
  {
    value: 'Haiti',
    name: 'Haiti',
  },
  {
    value: 'Honduras',
    name: 'Honduras',
  },
  {
    value: 'Hungary',
    name: 'Magyarorszag',
  },
  {
    value: 'Iceland',
    name: 'Island',
  },
  {
    value: 'India',
    name: 'India, Bharat',
  },
  {
    value: 'Indonesia',
    name: 'Indonesia',
  },
  {
    value: 'Iran',
    name: 'Iran, Persia',
  },
  {
    value: 'Iraq',
    name: 'Al Iraq',
  },
  {
    value: 'Ireland',
    name: 'Ireland or Eire',
  },
  {
    value: 'Israel',
    name: "Yisra'el",
  },
  {
    value: 'Italy',
    name: 'Italia',
  },
  {
    value: 'Jamaica',
    name: 'Jamaica',
  },
  {
    value: 'Japan',
    name: 'Japan',
  },
  {
    value: 'Jordan',
    name: 'Al Urdun',
  },
  {
    value: 'Kazakhstan',
    name: 'Qazaqstan',
  },
  {
    value: 'Kenya',
    name: 'Kenya',
  },
  {
    value: 'Kiribati',
    name: 'Kiribati',
  },
  {
    value: 'Korea, North',
    name: 'Choson',
  },
  {
    value: 'Korea, South',
    name: "Taehan-min'guk",
  },
  {
    value: 'Kuwait',
    name: 'Al Kuwayt',
  },
  {
    value: 'Kyrgyzstan',
    name: 'Kyrgyz Respublikasy',
  },
  {
    value: 'Laos',
    name: 'Laos',
  },
  {
    value: 'Latvia',
    name: 'Latvija',
  },
  {
    value: 'Lebanon',
    name: 'Lubnan',
  },
  {
    value: 'Lesotho',
    name: 'Lesotho',
  },
  {
    value: 'Liberia',
    name: 'Liberia',
  },
  {
    value: 'Libya',
    name: 'Libya',
  },
  {
    value: 'Liechtenstein',
    name: 'Liechtenstein',
  },
  {
    value: 'Lithuania',
    name: 'Lietuva',
  },
  {
    value: 'Luxembourg',
    name: 'Luxembourg',
  },
  {
    value: 'Macedonia',
    name: 'Makedonija',
  },
  {
    value: 'Madagascar',
    name: 'Madagascar',
  },
  {
    value: 'Malawi',
    name: 'Malawi',
  },
  {
    value: 'Malaysia',
    name: 'Malaysia',
  },
  {
    value: 'Maldives',
    name: 'Dhivehi Raajje',
  },
  {
    value: 'Mali',
    name: 'Mali',
  },
  {
    value: 'Malta',
    name: 'Malta',
  },
  {
    value: 'Marshall Islands',
    name: 'Marshall Islands',
  },
  {
    value: 'Mauritania',
    name: 'Muritaniyah',
  },
  {
    value: 'Mauritius',
    name: 'Mauritius',
  },
  {
    value: 'Mexico',
    name: 'Mexico',
  },
  {
    value: 'Micronesia',
    name: 'Micronesia',
  },
  {
    value: 'Moldova',
    name: 'Moldova',
  },
  {
    value: 'Monaco',
    name: 'Monaco',
  },
  {
    value: 'Mongolia',
    name: 'Mongol Uls',
  },
  {
    value: 'Morocco',
    name: 'Al Maghrib',
  },
  {
    value: 'Mozambique',
    name: 'Mocambique',
  },
  {
    value: 'Myanmar (Burma)',
    name: 'Myanma Naingngandaw',
  },
  {
    value: 'Namibia',
    name: 'Namibia',
  },
  {
    value: 'Nauru',
    name: 'Nauru',
  },
  {
    value: 'Nepal',
    name: 'Nepal',
  },
  {
    value: 'Netherlands',
    name: 'Nederland',
  },
  {
    value: 'New Zealand',
    name: 'New Zealand',
  },
  {
    value: 'Nicaragua',
    name: 'Nicaragua',
  },
  {
    value: 'Niger',
    name: 'Niger',
  },
  {
    value: 'Nigeria',
    name: 'Nigeria',
  },
  {
    value: 'Norway',
    name: 'Norge',
  },
  {
    value: 'Oman',
    name: 'Uman',
  },
  {
    value: 'Pakistan',
    name: 'Pakistan',
  },
  {
    value: 'Palau',
    name: 'Belau',
  },
  {
    value: 'Panama',
    name: 'Panama',
  },
  {
    value: 'Papua New Guinea',
    name: 'Papua New Guinea',
  },
  {
    value: 'Paraguay',
    name: 'Paraguay',
  },
  {
    value: 'Peru',
    name: 'Peru',
  },
  {
    value: 'Philippines',
    name: 'Pilipinas',
  },
  {
    value: 'Poland',
    name: 'Polska',
  },
  {
    value: 'Portugal',
    name: 'Portugal',
  },
  {
    value: 'Qatar',
    name: 'Qatar',
  },
  {
    value: 'Romania',
    name: 'Romania',
  },
  {
    value: 'Russia',
    name: 'Rossiya',
  },
  {
    value: 'Rwanda',
    name: 'Rwanda',
  },
  {
    value: 'Saint Kitts and Nevis',
    name: 'Saint Kitts and Nevis',
  },
  {
    value: 'Saint Lucia',
    name: 'Saint Lucia',
  },
  {
    value: 'Samoa',
    name: 'Samoa',
  },
  {
    value: 'San Marino',
    name: 'San Marino',
  },
  {
    value: 'Sao Tome and Principe',
    name: 'Sao Tome e Principe',
  },
  {
    value: 'Saudi Arabia',
    name: 'Al Arabiyah as Suudiyah',
  },
  {
    value: 'Senegal',
    name: 'Senegal',
  },
  {
    value: 'Serbia and Montenegro',
    name: 'Srbija-Crna Gora',
  },
  {
    value: 'Seychelles',
    name: 'Seychelles',
  },
  {
    value: 'Sierra Leone',
    name: 'Sierra Leone',
  },
  {
    value: 'Singapore',
    name: 'Singapore',
  },
  {
    value: 'Slovakia',
    name: 'Slovensko',
  },
  {
    value: 'Slovenia',
    name: 'Slovenija',
  },
  {
    value: 'Solomon Islands',
    name: 'Solomon Islands',
  },
  {
    value: 'Somalia',
    name: 'Somalia',
  },
  {
    value: 'South Africa',
    name: 'South Africa',
  },
  {
    value: 'Spain',
    name: 'Espana',
  },
  {
    value: 'Sri Lanka',
    name: 'Sri Lanka',
  },
  {
    value: 'Sudan',
    name: 'As-Sudan',
  },
  {
    value: 'Suriname',
    name: 'Suriname',
  },
  {
    value: 'Swaziland',
    name: 'Swaziland',
  },
  {
    value: 'Sweden',
    name: 'Sverige',
  },
  {
    value: 'Switzerland',
    name: 'Suisse (French)',
  },
  {
    value: 'Syria',
    name: 'Suriyah',
  },
  {
    value: 'Taiwan',
    name: 'Taiwan',
  },
  {
    value: 'Tajikistan',
    name: 'Jumhurii Tojikistan',
  },
  {
    value: 'Tanzania',
    name: 'Tanzania',
  },
  {
    value: 'Thailand',
    name: 'Muang Thai',
  },
  {
    value: 'Tolo',
    name: 'Togo',
  },
  {
    value: 'Tonga',
    name: 'Tonga',
  },
  {
    value: 'Trinidad and Tobago',
    name: 'Trinidad and Tobago',
  },
  {
    value: 'Tunisia',
    name: 'Tunis',
  },
  {
    value: 'Turkey',
    name: 'Turkiye',
  },
  {
    value: 'Turkmenistan',
    name: 'Turkmenistan',
  },
  {
    value: 'Tuvalu',
    name: 'Tuvalu',
  },
  {
    value: 'Uganda',
    name: 'Uganda',
  },
  {
    value: 'Ukraine',
    name: 'Ukrayina',
  },
  {
    value: 'United Arab Emirates',
    name: 'U.A.E',
  },
  {
    value: 'United Kingdom',
    name: 'United Kingdom',
  },
  {
    value: 'United States',
    name: 'United States',
  },
  {
    value: 'Uruguay',
    name: 'Uruguay',
  },
  {
    value: 'Uzbekistan',
    name: 'Uzbekiston Respublikasi',
  },
  {
    value: 'Vanuatu',
    name: 'Vanuatu',
  },
  {
    value: 'Vatican City (Holy See)',
    name: 'Santa Sede',
  },
  {
    value: 'Venezuela',
    name: 'Venezuela',
  },
  {
    value: 'Vietnam',
    name: 'Viet Nam',
  },
  {
    value: 'Yemen',
    name: 'Al Yaman',
  },
  {
    value: 'Zambia',
    name: 'Zambia',
  },
  {
    value: 'Zimbabwe',
    name: 'Zimbabwe',
  },
];

export default COUNTRIES;
