import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props {
  theme?: string;
  size?: string;
  customStyles?: {};
  customClass?: string;
  handleClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  blockLevel?: boolean;
  children: React.ReactNode;
}

const Button = ({ theme, size, customStyles, customClass, handleClick, disabled, blockLevel, children }: Props) => {
  return (
    <button
      onClick={handleClick}
      className={cn(styles.button, styles[`${size}Size`], styles[`${theme}Theme`], {[styles.block]: blockLevel}, customClass)}
      style={customStyles}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  theme: 'default',
  size: 'base',
  customStyles: {},
  handleClick: () => {},
  disabled: false,
};

export default Button;
