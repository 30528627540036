import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import ContentContainer from '../../components/ContentContainer';

import styles from './styles.module.scss';
import HeaderPanel from '../../components/HeaderPanel';
import { endCustomerSessionAction } from '../../store/General/actions';
import { makeText2PayAction } from '../../store/Payment/actions';
import QrCode from '../../components/QrCode/QrCode';
import IdleMonitor from '../../services/IdleMonitor';

const API_SERVER = localStorage.getItem('api_server');
// const END_SESSION_TIMEOUT = 180000; // ms

const PaymentText2PayScreen = () => {
  const dispatch = useDispatch();

  const {
    payByLink: { paymentLinkId, loading, paymentLinkURL },
  } = useSelector(state => state);

  const payByLinkData = {
    paymentLinkId,
    coreApiServer: API_SERVER.split('.')[0].toLowerCase(),
  };

  const payByLinkUrl = `${paymentLinkURL}/${btoa(JSON.stringify(payByLinkData))}`;

  React.useEffect(() => {
    dispatch(makeText2PayAction());
  }, []);

  const endCustomerSession = () => {
    dispatch(endCustomerSessionAction());
  };

  return (
    <>
      <ContentContainer theme="red" loading={loading}>
        <HeaderPanel theme="dark" />
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>Thank you!</h1>
          <h3 className={styles.description}>
            We’ve sent you a text message with a link to complete your purchase! If you did not receive a text message,
            <br />
            then scan the QR code below to complete your payment!
          </h3>
          <div className={styles.paymentQrCode}>
            {paymentLinkId && (
              <div className={styles.qr}>
                <QrCode text={payByLinkUrl} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.footerContainer}>
          {/*<span className={styles.message}>You’re all set you’ve completed your payment!</span>*/}
          <Button handleClick={endCustomerSession} theme="white" customClass={styles.okBtn}>
            Finish
          </Button>
        </div>
      </ContentContainer>
      <IdleMonitor removeLineItems={false} />
    </>
  );
};

export default PaymentText2PayScreen;
