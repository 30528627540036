import React from 'react';
import styles from './styles.module.scss';
import warningIcon from '../../assets/icons/warningIcon.svg';

interface WarningProps {
  title?: string;
  text: string;
  buttonText?: string;
  handleClick: any;
}

const Warning = ({ title = 'Oh no!', text, buttonText = 'OK', handleClick }: WarningProps) => {
  return (
    <div className={styles.Warning}>
      <img className={styles.Warning__icon} src={warningIcon} alt="Warning icon" />
      <div className={styles.Warning__content}>
        <div className={styles.Warning__title}>{title}</div>
        <div className={styles.Warning__text}>{text}</div>
      </div>
      <div onClick={handleClick} className={styles.Warning__button}>
        {buttonText}
      </div>
    </div>
  );
};

export default Warning;
