import React from 'react';
import cn from "classnames";
import HeaderPanel from '../../components/HeaderPanel';

import styles from './mainRegistrationModeScreen.module.scss';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import NavigationButton from "../../components/NavigationButton";

import DocumentIcon from '../../assets/icons/document-icon.svg';
import GearsIcon from '../../assets/icons/gears-icon.svg';
import ModeFoodIcon from "../../assets/icons/mode_food.svg"

const MainRegistrationModeScreen = () => {

  return (
    <>
      <ContentContainer loading={false} theme="red">
        <HeaderPanel logOutButton />

        <div className={styles.container}>
          <NavigationButton
            icon={ModeFoodIcon}
            label="Add guest"
            customClass={cn(styles.button, styles.buttonAddGuest)} />
          <NavigationButton
            icon={DocumentIcon}
            label="Finish check-in"
            customClass={cn(styles.button, styles.buttonFinishCheckIn)} />
          <NavigationButton
            icon={GearsIcon}
            label="Manage accounts"
            customClass={cn(styles.button, styles.buttonManageAccounts)} />
        </div>

      </ContentContainer>
      <IdleMonitor />
    </>
  );
};

export default MainRegistrationModeScreen;
