import {CREATE_PAYMENT_TYPES, RETRY_PAYMENT_TYPE, GET_PAYMENT_STATUS_TYPES, PAYMENT_IS_FAILED, RESET_PAYMENT_PROCESS_TYPE} from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  paymentStatusId: null,
  status: null,
  statusDescription: '',
  payment: null,
  attempts: 1,
  badStatusAttempts: 0,
  loading: false,
};

export type InitialStateType = typeof initialState;

export default function paymentReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case CREATE_PAYMENT_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PAYMENT_TYPES.SUCCESS:
      return {
        ...state,
        paymentStatusId: payload.paymentStatusId,
        loading: false,
      };
    case CREATE_PAYMENT_TYPES.FAILURE:
      return {
        ...state,
        paymentStatusId: null,
        status: null,
        loading: false,
      };
    case GET_PAYMENT_STATUS_TYPES.GET_PAYMENT_STATUS:
      return {
        ...state,
        attempts: state.attempts + 1,
        loading: false,
      };
    case PAYMENT_IS_FAILED:
      return {
        ...state,
        badStatusAttempts: state.badStatusAttempts + 1,
        loading: false,
      };
    case GET_PAYMENT_STATUS_TYPES.GET_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RESET_PAYMENT_PROCESS_TYPE.RESET_PAYMENT_PROCESS_SUCCESS:
      return {
        ...initialState,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
