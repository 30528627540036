import styles from './styles.module.scss';
import Button from '../../Button';
import Modal from '../../Modal';
import React from 'react';

const CartItemRemoveAlert = ({ isOpen, continueClickHandle, cancelClickHandle }: any) => {
  return (
    <Modal isOpen={isOpen}>
      <div className={styles.cartItemRemoveAlert}>
        <div className={styles.cartItemRemoveAlert__content}>
          <h2>Are you sure?</h2>
          <p>There are products in your cart that require this membership.</p>
          <p>
            If you continue those products will be <strong>removed from the cart</strong> too.
          </p>
        </div>
        <div className={styles.cartItemRemoveAlert__navigation}>
          <Button handleClick={cancelClickHandle} customClass={styles.whiteButton} theme="white">
            Cancel
          </Button>
          <Button handleClick={continueClickHandle} customClass={styles.redButton} theme="red">
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CartItemRemoveAlert;
