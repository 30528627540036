import { detectScrollDisable } from '../utils/core';
import { useEffect, useState } from 'react';

const useSticky = (ref, offset = 0) => {
  const [offsetTop, setOffsetTop] = useState(offset);
  const [isSticky, setIsSticky] = useState(false);
  const sticky = () => {
    if (!detectScrollDisable()) {
      const pageYOffset = window.pageYOffset;
      const getBoundingClientRectTop = ref.current.getBoundingClientRect().top;
      if (
        pageYOffset >= 0 &&
        getBoundingClientRectTop >= 0 &&
        getBoundingClientRectTop <= offset &&
        pageYOffset <= offset
      ) {
        requestAnimationFrame(() => {
          setIsSticky(false);
          setOffsetTop(getBoundingClientRectTop);
        });
      } else if (
        (pageYOffset < 0 && getBoundingClientRectTop <= offset && getBoundingClientRectTop >= 0) ||
        (pageYOffset < offset && getBoundingClientRectTop <= offset && getBoundingClientRectTop >= 0) ||
        (pageYOffset > 0 && getBoundingClientRectTop < 0 && pageYOffset < offset) ||
        (pageYOffset > 0 && getBoundingClientRectTop < offset && pageYOffset < offset)
      ) {
        requestAnimationFrame(() => {
          setIsSticky(false);
          setOffsetTop(offset);
        });
        setOffsetTop(offset);
      } else if (pageYOffset > 0 && getBoundingClientRectTop === 0) {
        requestAnimationFrame(() => {
          setIsSticky(true);
          setOffsetTop(0);
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', sticky);
    return () => {
      window.removeEventListener('scroll', sticky);
    };
  }, []);

  return { offsetTop, isSticky };
};

export default useSticky;
