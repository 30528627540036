import { CUSTOMER_TYPES, NEW_CUSTOMER_TYPES } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  commonData: {
    birthDate: '',
    fName: '',
    lName: '',
    cell: '',
    doNotMail: true,
    customerImage: '',
    pin: '',
  },
  signature: '',
};

export type InitialStateType = typeof initialState;

export default function newCustomerReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case NEW_CUSTOMER_TYPES.UPDATE_COMMON_DATA:
      return {
        ...state,
        commonData: {
          ...state.commonData,
          ...payload,
        },
      };
    case NEW_CUSTOMER_TYPES.UPDATE_SIGNATURE:
      return {
        ...state,
        signature: payload,
      };
    case CUSTOMER_TYPES.SAVE_CUSTOMER_TO_STORE:
      return {
        ...initialState,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
