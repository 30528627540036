export const MAKE_PAYMENT_CARD_CONNECT_TYPES = {
  MAKE_PAYMENT_CARD_CONNECT: 'payment/MAKE_PAYMENT_CARD_CONNECT_REQUEST',
  MAKE_PAYMENT_CARD_CONNECT_SUCCESS: 'payment/MAKE_PAYMENT_CARD_CONNECT_SUCCESS',
  MAKE_PAYMENT_CARD_CONNECT_FAILURE: 'payment/MAKE_PAYMENT_CARD_CONNECT_FAILURE',
};

export const RESET_DATA_PAYMENT_CARD_CONNECT = 'payment/RESET_DATA_PAYMENT_CARD_CONNECT';

export const makePaymentCardConnectActionAction = (payload: any) => ({
  type: MAKE_PAYMENT_CARD_CONNECT_TYPES.MAKE_PAYMENT_CARD_CONNECT,
  payload,
});

export const makePaymentCardConnectActionSuccess = () => ({
  type: MAKE_PAYMENT_CARD_CONNECT_TYPES.MAKE_PAYMENT_CARD_CONNECT_SUCCESS,
});

export const makePaymentCardConnectActionFailure = (payload: any) => ({
  type: MAKE_PAYMENT_CARD_CONNECT_TYPES.MAKE_PAYMENT_CARD_CONNECT_FAILURE,
  payload,
});

export const resetCardConnectAction = () => ({
  type: RESET_DATA_PAYMENT_CARD_CONNECT,
});
