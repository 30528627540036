import React from 'react';
import styles from './styles.module.scss';
import { arrayChunk } from '../../utils/core';
import moment from 'moment';
import TimeSlotGrid from '../TimeSlotGrid';

const ResourceGroup = ({
  timeSlots,
  timeSlotHandler,
  selectedTimeSlots,
  timeSlotsGridHeight,
  timeSlotsGridLength,
  timeSlotsGridGap,
}) => {
  return (
    <div className={styles.productDetails} style={{ height: `${timeSlotsGridHeight}px` }}>
      <div className={styles.productDetailsInner}>
        {timeSlots &&
          timeSlots.map((item, index) => {
            const itemTimeSlots = item.timeslots.filter(timeslot => !moment().isAfter(timeslot.scheduledTime));
            const timeChunk = arrayChunk(itemTimeSlots, timeSlotsGridLength);
            if (timeChunk.length > 0) {
              return (
                <TimeSlotGrid
                  selectedTimeSlots={selectedTimeSlots}
                  timeSlotHandler={timeSlotHandler}
                  title={item.resourceName}
                  key={index}
                  timeChunk={timeChunk}
                  trackNo={item.trackNo}
                  timeSlotsGridGap={timeSlotsGridGap}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default ResourceGroup;
